import { TextField, Typography } from "@mui/material";
import {
  DateTimePicker,
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import React, { useCallback, useEffect, useState } from "react";
import useFlashMsg from "../../../../hooks/useFlashMsg";
import { createTestLinkForCandidateAPI, getAllTestdomeTestsAPI } from "../../../../services";
import ClientValidatorErrorDisplayer from "../../../ClientValidatorErrorDisplayer";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import classes from "./TestdomeModal.module.css";
import moment from "moment";

const initError = {
  test: [],
  deadlineDate: [],
};

const TestdomeModal = ({ fullName, testId, email, handleCloseModal, updateTestdome }) => {
  const [testdome, setTestdome] = useState({
    test: {
      id: null,
      name: "",
      description: "",
    },
    deadlineDate: "",
  });
  const [testdomeTests, setTestdomeTests] = useState([]);
  const [errorHandling, setErrorHandling] = useState(initError);
  const { handleSuccessFlash, handleErrorFlash } = useFlashMsg();

  const getAllTestdomeTestsHandler = useCallback(async () => {
    setErrorHandling(initError);
    try {
      const result = await getAllTestdomeTestsAPI();

      setTestdomeTests(result.data.data);

      const deadlineDate = new Date();

      deadlineDate.setDate(deadlineDate.getDate() + 7);

      setTestdome((prevState) => ({
        ...prevState,
        deadlineDate: deadlineDate.toISOString(),
      }));
    } catch (err) {
      console.log(err);
      const errMsg = err.response.data.err?.msg || err.message;

      if (err.response.data.err) console.log(err.response.data.err);

      handleErrorFlash("something went wrong while fetching tests");
    }
  }, []);

  useEffect(() => {
    getAllTestdomeTestsHandler();
  }, [getAllTestdomeTestsHandler]);

  useEffect(() => {
    if (testdomeTests.length > 0) {
      setErrorHandling(initError);
      let testdomeTest = testdomeTests.find(
        (test) => parseInt(test.id) === parseInt(testId)
      );

      if (!testdomeTest) {
        // handle show error and don't continue
        setErrorHandling((prevState) => ({
          ...prevState,
          test: ["You Should Choose Test!"],
        }));
        return;
      }

      setTestdome((prevState) => ({
        ...prevState,
        test: testdomeTest,
      }));
    }
  }, [testdomeTests, testId]);

  const handleTestdomeTestSelection = (e) => {
    setErrorHandling(initError);
    let testdomeTest = testdomeTests.find(
      (test) => parseInt(test.id) === parseInt(e.currentTarget.value)
    );

    if (!testdomeTest || !e.currentTarget.value) {
      // handle show error and don't continue
      setErrorHandling((prevState) => ({
        ...prevState,
        test: ["You Should Choose Test!"],
      }));
      return;
    }

    setTestdome((prevState) => ({
      ...prevState,
      test: testdomeTest,
    }));
  };

  const handleDeadlineDate = (value) => {
    setTestdome((prevState) => ({
      ...prevState,
      deadlineDate: value._d.toISOString(),
    }));
  };

  const handleSendTest = async () => {
    if (!testdome.test.id) {
      setErrorHandling((prevState) => ({
        ...prevState,
        test: ["You Should Choose Test!"],
      }));
      handleErrorFlash("Test Selection is Required!")
      return;
    }
    if (!testdome.deadlineDate) {
      setErrorHandling((prevState) => ({
        ...prevState,
        deadlineDate: ["Deadline Required!"],
      }));
      handleErrorFlash("Deadline Selection is Required!")
      return;
    }

    try {
        const reqBody = {
            ...testdome,
            fullName,
            email
        }

        const result = await createTestLinkForCandidateAPI(reqBody);
        
        handleSuccessFlash("Successfully Created Test For Candidate!")
        updateTestdome(result.data.data)
        handleCloseModal();
    } catch (err) {
        console.log(err);
        const errMsg = err.response.data.err?.msg || err.message;
  
        if (err.response.data.err) console.log(err.response.data.err);
  
        handleErrorFlash("error occurred while creating test link");
    }
  };

  return (
    <div className={classes.container}>
      <h2>Send Testdome Test To {fullName}</h2>

<div className={classes.formContainer}>
    <div className={classes.fieldsContainer}>


<div className={`${classes.fullWidthField}`}>
        <Typography variant="body1" className={classes.fieldLabel}>
          Testdome Test
        </Typography>
        <select
          value={testdome.test.id}
          placeholder="Select Testdome Test"
          className={classes.selectField}
          onChange={handleTestdomeTestSelection}
        >
          <option selected="selected" disabled value="">
            Select Testdome Exam
          </option>
          {testdomeTests.length > 0 &&
            testdomeTests.map((testdomeTest) => (
              <option
                key={`testdome-${testdomeTest?.id}`}
                value={testdomeTest?.id}
              >
                {`(ID:${testdomeTest.id})`} {`(Name: ${testdomeTest.name})`}{" "}
                {testdomeTest.description
                  ? `(Description: ${testdomeTest.description.slice(0, 50)})`
                  : ""}
              </option>
            ))}
          {testdomeTests.length <= 0 && "Loading..."}
        </select>

        <ClientValidatorErrorDisplayer fieldErrors={errorHandling?.test} />
      </div>

      <div className={`${classes.fullWidthField}`}>
        <Typography variant="body1" className={classes.fieldLabel}>
          Deadline Date
        </Typography>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <MobileDateTimePicker
            className={classes.dateTimePicker}
            value={moment(testdome.deadlineDate)}
            onChange={handleDeadlineDate}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>

        <ClientValidatorErrorDisplayer
          fieldErrors={errorHandling?.deadlineDate}
        />
      </div>
      </div>

      
      <button className={classes.sendTest} onClick={handleSendTest}>Confirm Send Test</button>
</div>


    </div>
  );
};

export default TestdomeModal;
