import { useCallback, useEffect, useState } from "react";
import { getJobAPI } from "../services";
import useFlashMsg from "./useFlashMsg";

const initialJobState = {
    title: "",
    postType: "Job",
    country: "Egypt",
    city: "Cairo",
    jobType: [],
    testdome: {
      id: null,
      name: "",
      description: ""
    },
    details: "",
    yearsExperience: [1, 1],
    careerLevel: "",
    salaryRange: [0, 0],
    description: null,
    requirements: null,
    vacancyNumber: 1
};

const initialQuestionsState = []

const useJob = (jobId = null) => {
  const [jobState, setJobState] = useState(initialJobState);
  const [questionsState, setQuestionsState] = useState(initialQuestionsState);
  const {handleErrorFlash} = useFlashMsg();

  console.log(jobState);

  const getJobFromDB = useCallback(async () => {
    try {
      const resultJob = await getJobAPI(jobId);

      setJobState(resultJob.data.data.data);
      setQuestionsState(resultJob.data.data.data.questions);
    } catch (err) {
      console.log(err);
      const errMsg = err.response.data.err?.msg || err.message;

      if (err.response.data.err) console.log(err.response.data.err);

      handleErrorFlash("error occurred while fetching job");
    }
  }, [jobId])

  useEffect(() => {
    jobId && getJobFromDB();
  }, [getJobFromDB])

  const handleTextField = (value, fieldName) => {
    setJobState(prevState => ({
        ...prevState,
        [fieldName]: value
    }))
  }

  const handleSelectField = (value, fieldName) => {
    setJobState(prevState => ({
        ...prevState,
        [fieldName]: value
    }))
  }

  const handleJobType = (value) => {
    setJobState(prevState => {
        if(!prevState.jobType.includes(value)) {
         return ({
            ...prevState,
            jobType: [...prevState.jobType, value]
         })  
        }

        return ({
            ...prevState,
            jobType: prevState.jobType.filter(data => data !== value)
        })
    })
  }

  const handleYearsExperience = (value, rangeType) => {
    const newYearsExperience = [...jobState.yearsExperience]
    newYearsExperience[rangeType] = parseInt(value);
    if(newYearsExperience[0] > newYearsExperience[1]) newYearsExperience[1] = parseInt(value);
    setJobState(prevState => ({
        ...prevState,
        yearsExperience: newYearsExperience
    }))
  }

  const handleCareerLevel = (value) => {
    setJobState(prevState => ({
        ...prevState,
        careerLevel: value
    }))
  }

  const handleSalaryRange = (e, rangeType) => {
    const value = e.currentTarget.value;
    const newSalaryRange = [...jobState.salaryRange]
    newSalaryRange[rangeType] = Number(value.replace(/\D/,''));
    if(newSalaryRange[0] > newSalaryRange[1]) newSalaryRange[1] = Number(value.replace(/\D/,''));
    setJobState(prevState => ({
        ...prevState,
        salaryRange: newSalaryRange
    }))
  }

  const handleVacancyNumber = (value) => {
    setJobState(prevState => ({
        ...prevState,
        vacancyNumber: (isNaN(value) || value <= 0) ? 1 : parseInt(value)
    }))
  }

  const handleDraftField = (value, editorType) => {
    setJobState(prevState => ({
      ...prevState,
      [editorType]: value
    }))
  }

  const handleVacancyNumberChange = (changeType) => {
    if(changeType === "increment") {
        setJobState(prevState => ({
            ...prevState,
            vacancyNumber: prevState.vacancyNumber + 1
        }))
    }
    else {
        setJobState(prevState => ({
            ...prevState,
            vacancyNumber: prevState.vacancyNumber <= 1 ? 1 : prevState.vacancyNumber - 1
        }))
    }
  }

  const addNewQuestionHandler = (newQuestion) => {
    setQuestionsState(prevState => ([
      ...prevState,
      newQuestion
    ]))
  }

  const editQuestionHandler = (editedQuestion, questionIndex) => {
    setQuestionsState(prevState => {
      if(editedQuestion.whoAnsweredCount > 0) {
        return [...prevState.map((question, index) => {
          if(index === questionIndex) {
            return {...question, isActive: false}
          }

          return question;
        }), editedQuestion]
      }

      return prevState.map((question, index) => {
      if(index === questionIndex) return editedQuestion;

      return question;
    })
  })
  }

  const deleteQuestionHandler = (questionIndex) => {
    setQuestionsState(prevState => prevState.filter((question, index) => {
      if(index !== questionIndex) return question;
    }))
  }

  // handles the toggle on/off for the activate/deactivate button for each question that got answered by a candidate
  const toggleIsActiveBtn = (questionIndex) => {
    setQuestionsState(prevState => prevState.map((question, index) => {
      if(index === questionIndex) return {...question, isActive: !question.isActive};

      return question;
    }))
  }

  return {
    jobState,
    questionsState,
    handleTextField: (fieldName) => (e) => handleTextField(e.currentTarget.value, fieldName),
    handleSelectField: (fieldName) => (e) => handleSelectField(e.currentTarget.value, fieldName),
    handleJobType: (value) => () => handleJobType(value),
    handleYearsExperience: (rangeType) => (e) => handleYearsExperience(e.currentTarget.value, rangeType),
    handleCareerLevel: (value) => () => handleCareerLevel(value),
    handleSalaryRange: (rangeType) => (e) => handleSalaryRange(e, rangeType),
    handleDraftField: (editorType) => (value) => handleDraftField(value, editorType),
    handleVacancyNumber: (e) => handleVacancyNumber(e.currentTarget.value),
    handleVacancyNumberChange: (changeType) => () => handleVacancyNumberChange(changeType),
    addNewQuestionHandler: (newQuestion) => addNewQuestionHandler(newQuestion),
    editQuestionHandler: (editedQuestion, questionIndex) => editQuestionHandler(editedQuestion, questionIndex),
    deleteQuestionHandler: (questionIndex) => () => deleteQuestionHandler(questionIndex),
    toggleIsActiveBtn: (questionIndex) => () => toggleIsActiveBtn(questionIndex)
  };
};

export default useJob;
