import { Grid, Typography } from "@mui/material";
import classes from "./WhyChooseUsCard.module.css"

const WhyChooseUsCard = ({ icon, title, alt, description }) => {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className={classes.cardContainer}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          textAlign="center"
        >
          <img
          className={classes.icon}
            src={icon}
            alt={alt}
          />
          <Typography
            variant="h3"
            fontWeight={700}
            className={classes.title}
          >
            {title}
          </Typography>
          <Typography variant="body1">{description}</Typography>
        </Grid>
      </Grid>
    );
  };

  export default WhyChooseUsCard;