/**
 * FILE FOR TESTING ONLY
 */

import { Button, Grid, TextField, Typography } from "@mui/material";
import { useEffect } from "react";
import { useCallback } from "react";
import { useReducer } from "react";
import { memo } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import useFlashMsg from "../../hooks/useFlashMsg";
import { createApplicationAPI } from "../../services";
import ScreeningQuestions from "../ScreeningQuestions";

const APPLICATION_ACTIONS = {
  FIRSTNAME: "firstname",
  LASTNAME: "lastname",
  EMAIL: "email",
  SALARY: "salary",
  YEARSEXPERIENCE: "yearsExperience",
  CV: "cv",
};

const applicationReducer = (state, action) => {
  switch (action.type) {
    case APPLICATION_ACTIONS.FIRSTNAME:
      return { ...state, firstname: action.payload };
    case APPLICATION_ACTIONS.LASTNAME:
      return { ...state, lastname: action.payload };
    case APPLICATION_ACTIONS.EMAIL:
      return { ...state, email: action.payload };
    case APPLICATION_ACTIONS.SALARY:
      return { ...state, salary: action.payload };
    case APPLICATION_ACTIONS.YEARSEXPERIENCE:
      return { ...state, yearsExperience: action.payload };
    case APPLICATION_ACTIONS.CV:
      return { ...state, cv: action.payload };
    default:
      return state;
  }
};

const CustomTextField = memo(({ label, value, handler, fieldName, type }) => {
  return (
    <Grid item xs={12} sm={6} style={{ paddingRight: "20px" }}>
      <Grid item xs={12} style={{ margin: "20px 0" }}>
        <Typography variant="subtitle2">{label}</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={value}
          size="small"
          required
          variant="outlined"
          sx={{ marginBottom: "1rem", width: "100%", fontSize: "14px" }}
          onChange={(e) => handler(e, fieldName, type)}
        />
      </Grid>
    </Grid>
  );
});

const CustomUploadField = memo(({ handleCVUpload }) => {
  return (
    <Grid item xs={12} style={{ paddingRight: "20px" }}>
      <Grid item xs={12} style={{ margin: "20px 0" }}>
        <Typography variant="body">Upload CV</Typography>
      </Grid>
      <input
        type="file"
        onChange={handleCVUpload}
        required
        accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, .txt"
      />
    </Grid>
  );
});

const ApplicationForm = ({ job }) => {
  const authUser = useSelector((state) => state.authReducer);

  const [applicationForm, applicationDispatch] = useReducer(
    applicationReducer,
    {
      firstname: "marwan",
      lastname: "samih",
      email: "marwansamih15@gmail.com",
      salary: 500,
      yearsExperience: 3,
      cv: "",
    }
  );

  const [screeningQuestionsForm, setScreeningQuestionsForm] = useState([]);
  const [records, setRecords] = useState([]);

  useEffect(() => {
    if (!(job && job.questions?.length > 0)) return;
    setScreeningQuestionsForm(
      job?.questions?.map((question) => ({
        question: question._id,
        answer: {
          answer: null,
          type: question.type,
        },
      }))
    );
  }, [job]);

  const { handleErrorFlash, handleSuccessFlash } = useFlashMsg();

  const handleScreeningAnswer = useCallback(
    (answer, questionId, questionType) => {
      setScreeningQuestionsForm((prevState) => {
        return prevState.map((screeningQuestion) => {
          if (
            screeningQuestion.question === questionId &&
            screeningQuestion.answer.type === questionType
          ) {
            return {
              ...screeningQuestion, // this is the question attribute
              answer: {
                ...screeningQuestion.answer, // this is the type answer attribute
                answer: answer !== "" ? answer : null, // this is the answer comming from the user
              },
            };
          }
          return screeningQuestion;
        });
      });
    },
    []
  );

  // give file record name by the merge of loggedUser Id and question Id 
  const handleRecords = useCallback(
    (audio, questionId) => {
      if (!audio) {
        setRecords((prevState) =>
          prevState.filter((record) => record.questionId !== questionId)
        );
        return "";
      }
      const loggedUserId = authUser.user.id;

      const filename = `${loggedUserId}-${questionId}.wav`;

      const file = new File([audio], filename, { type: "audio/wav" });

      setRecords((prevState) => [...prevState, { file, questionId, blob: audio }]);

      return filename;
    },
    [authUser.user.id]
  );

  const handleApplicationForm = useCallback((e, fieldName, dataType) => {
    let value;
    switch (dataType) {
      case "number":
        value = parseInt(e.target.value);
        break;
      case "boolean":
        value = e.target.value ? true : false;
        break;
      default:
        value = e.target.value;
        break;
    }

    applicationDispatch({ type: fieldName, payload: value });
  }, []);

  const handleCVUpload = useCallback(
    (e) => {
      const file = e.target.files[0];

      applicationDispatch({ type: "cv", payload: file });
    },
    [applicationDispatch]
  );

  const submitApplication = useCallback(async () => {
    const formData = new FormData();
    Object.entries(applicationForm).forEach(([key, value]) => {
      formData.append(key, key === "cv" ? value : JSON.stringify(value));
    });

    if (records.length > 0) {
      for (let record of records) {
        formData.append("record", record.file);
      }
    }

    formData.append(
      "screeningQuestions",
      JSON.stringify(screeningQuestionsForm)
    );

    const config = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    try {
      const applicationResult = await createApplicationAPI(
        job._id,
        formData,
        config
      );

      console.log(applicationResult);

      handleSuccessFlash("Application Submitted Successfully!")
    } catch (err) {
      console.log(err);
      const errMsg = err.response.data.err?.msg || err.message;

      if (err.response.data.err) console.log(err.response.data.err);

      handleErrorFlash("error occurred while creating application");
    }
  }, [
    applicationForm,
    job._id,
    screeningQuestionsForm,
    records,
  ]);

  return (
    <Grid container item xs={6} sx={{ width: "100%" }}>
      <CustomTextField
        label="Firstname"
        value={applicationForm.firstname}
        fieldName="firstname"
        type="string"
        handler={handleApplicationForm}
      />
      <CustomTextField
        label="Lastname"
        value={applicationForm.lastname}
        fieldName="lastname"
        type="string"
        handler={handleApplicationForm}
      />
      <CustomTextField
        label="Email"
        value={applicationForm.email}
        fieldName="email"
        type="string"
        handler={handleApplicationForm}
      />
      <CustomTextField
        label="Salary"
        value={applicationForm.salary}
        fieldName="salary"
        type="string"
        handler={handleApplicationForm}
      />
      <CustomTextField
        label="Years of Experience"
        value={applicationForm.yearsExperience}
        fieldName="yearsExperience"
        type="number"
        handler={handleApplicationForm}
      />

      <CustomUploadField handleCVUpload={handleCVUpload} />

      {screeningQuestionsForm.length > 0 && (
        <ScreeningQuestions
          questions={job.questions}
          handleScreeningAnswer={handleScreeningAnswer}
          screeningQuestions={screeningQuestionsForm}
          handleRecords={handleRecords}
        />
      )}

      <Grid item xs={12} mt={2}>
        <Button variant="outlined" onClick={submitApplication}>
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};

export default ApplicationForm;
