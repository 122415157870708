import classes from "./TextAnswer.module.css"

const TextAnswer = ({ handleScreeningAnswer, question, answer }) => {
  const textHandler = (e) => {
    const answer = e.target.value;

    handleScreeningAnswer(answer, question.id, question.type);
  };

  return (
    <div>
      <input className={classes.textField} value={answer.answer} onChange={textHandler} />
    </div>
  );
};

export default TextAnswer;