import { useRoutes } from "react-router-dom";

import Register from "./views/Register";
import Login from "./views/Login";
import Home from "./views/Home";
import NotFound from "./views/NotFound";
import Auth from "./components/Auth";
import UnAuth from "./components/UnAuth";
import System from "./components/System";
import AdminSystem from "./components/AdminSystem";
import CreateApplication from "./views/Application/CreateApplication";
import JobsList from "./views/JobsList";
import OurModel from "./views/OurModel";
import Pricing from "./views/Pricing";
import ContactUs from "./views/ContactUs";
import AdminJobsList from "./views/Admin/Job/JobsList";
import AdminAddJob from "./views/Admin/Job/AddJob";
import AdminViewCandidates from "./views/Admin/Job/ViewCandidates";
import AdminViewCandidate from "./views/Admin/Job/ViewCandidate";
import ForgotPassword from "./views/ForgotPassword";
import ResetPassword from "./views/ResetPassword";
import Profile from "./views/Profile";
import ChangePassword from "./views/ChangePassword";
import ViewJob from "./views/ViewJob";
import ApplyToJob from "./views/ApplyToJob";
import AdminEditJob from "./views/Admin/Job/EditJob";
import AuthRolesWrapper from "./components/AuthRolesWrapper";
import PrivacyPolicy from "./views/PrivacyPolicy";
import TermsOfUse from "./views/TermsOfUse";
import CookiePolicy from "./views/CookiePolicy";
import VerifyAccount from "./views/VerifyAccount";
import AdminContactUsList from "./views/Admin/ContactUs/ContactUsList";
import CalendlyWrapper from "./components/CalendlyWrapper";

export const Routes = () => {
  const element = useRoutes(routes);

  return element;
};

const routes = [
  {
    element: (
      <System>
        <CalendlyWrapper>
          <Home />
        </CalendlyWrapper>
      </System>
    ),
    path: "/",
  },
  {
    element: (
      <Auth>
        <System displayFooter={false}>
          <CalendlyWrapper>
            <Profile />
          </CalendlyWrapper>
        </System>
      </Auth>
    ),
    path: "profile",
  },
  {
    element: (
      <UnAuth>
        <System displayFooter={false}>
          <Login />
        </System>
      </UnAuth>
    ),
    path: "login",
  },
  {
    element: (
      <UnAuth>
        <System displayFooter={false}>
          <Register />
        </System>
      </UnAuth>
    ),
    path: "register",
  },
  {
    element: <ForgotPassword />,
    path: "forgot-password",
  },
  {
    element: <ResetPassword />,
    path: "reset-password/:userId/:resetToken",
  },
  {
    element: (
      <Auth>
        <ChangePassword />
      </Auth>
    ),
    path: "change-password",
  },
  {
    element: (
      <AuthRolesWrapper>
        <AdminSystem>
          <AdminJobsList />
        </AdminSystem>
      </AuthRolesWrapper>
    ),
    path: "admin/jobs",
  },
  {
    element: (
      <AuthRolesWrapper>
        <AdminSystem>
          <AdminAddJob />
        </AdminSystem>
      </AuthRolesWrapper>
    ),
    path: "admin/add-job",
  },
  {
    element: (
      <AuthRolesWrapper>
        <AdminSystem>
          <AdminEditJob />
        </AdminSystem>
      </AuthRolesWrapper>
    ),
    path: "admin/edit-job/:jobId",
  },
  {
    element: (
      <AuthRolesWrapper>
        <AdminSystem>
          <AdminContactUsList />
        </AdminSystem>
      </AuthRolesWrapper>
    ),
    path: "admin/contact-us",
  },
  {
    element: (
      <AuthRolesWrapper>
        <AdminSystem>
          <AdminViewCandidates />
        </AdminSystem>
      </AuthRolesWrapper>
    ),
    path: "admin/job/view-candidates/:jobId",
  },
  {
    element: (
      <AuthRolesWrapper>
        <AdminSystem>
          <AdminViewCandidate />
        </AdminSystem>
      </AuthRolesWrapper>
    ),
    path: "admin/job/view-candidate/:applicationId",
  },
  {
    element: <CreateApplication />,
    path: "apply/:jobId",
  },
  {
    element: (
      <System>
        <CalendlyWrapper>
          <JobsList />
        </CalendlyWrapper>
      </System>
    ),
    path: "jobs",
  },
  {
    element: (
      <System>
        <ViewJob />
      </System>
    ),
    path: "view-job/:jobId",
  },
  {
    element: (
      <System>
        <Auth>
          <ApplyToJob />
        </Auth>
      </System>
    ),
    path: "job/apply-to-job/:jobId",
  },
  {
    element: (
      <System>
        <CalendlyWrapper>
          <OurModel />
        </CalendlyWrapper>
      </System>
    ),
    path: "our-model",
  },
  {
    element: (
      <System>
        <CalendlyWrapper>
          <Pricing />
        </CalendlyWrapper>
      </System>
    ),
    path: "pricing",
  },
  {
    element: (
      <System>
        <CalendlyWrapper>
          <ContactUs />
        </CalendlyWrapper>
      </System>
    ),
    path: "contact-us",
  },
  {
    element: (
      <System>
        <CalendlyWrapper>
          <PrivacyPolicy />
        </CalendlyWrapper>
      </System>
    ),
    path: "/privacy-policy",
  },
  {
    element: (
      <System>
        <CalendlyWrapper>
          <CookiePolicy />
        </CalendlyWrapper>
      </System>
    ),
    path: "/cookie-policy",
  },
  {
    element: (
      <System>
        <VerifyAccount />
      </System>
    ),
    path: "/verify-account/:resetToken",
  },
  {
    element: (
      <System>
        <CalendlyWrapper>
          <TermsOfUse />
        </CalendlyWrapper>
      </System>
    ),
    path: "/terms-of-use",
  },
  {
    path: "*",
    element: (
      <System>
        <NotFound />
      </System>
    ),
  },
];
