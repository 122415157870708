import { Grid, Typography } from "@mui/material";
import classes from "./SloganBanner.module.css"

const SloganBanner = () => {
  return (
    <Grid container className={classes.sloganContainer}>
      <Typography variant="h3">Hire The Top 5% Of Developers</Typography>
    </Grid>
  );
};

export default SloganBanner;
