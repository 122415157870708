import { Grid, Typography } from "@mui/material";
import classes from "./JoinUs.module.css";
import titleFrame from "../../../assets/images/title-frame.svg";
import joinUs from "../../../assets/images/join-us.png";
import { useNavigate } from "react-router-dom";

const JoinUs = () => {

  const navigate = useNavigate()

  const navigateToJobList = () => {
    navigate("/jobs")
  }
  return (
    <Grid container xs={12} className={classes.container}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        lg={12}
        xl={6}
        className={classes.leftContainer}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.headerContainer}
        >
          <img
            className={classes.headerIcon}
            src={titleFrame}
            alt="title-frame"
          />
          <Typography
            variant="h2"
            fontWeight={700}
            className={classes.headerTitle}
          >
            Join Us !
          </Typography>
        </Grid>

        <Typography variant="h2" fontWeight={500} className={classes.title}>
          Be apart of techforce
        </Typography>
        <Typography
          variant="h3"
          fontWeight={400}
          className={classes.description}
        >
          At Techforce, We're always looking for reliable, energetic and professional developers to join our Team. If this sounds like you, Apply Now!
        </Typography>
        <button onClick={navigateToJobList} className={classes.searchBtn}>Search Jobs</button>
      </Grid>
      <Grid container lg={12} xl={6} className={classes.imgContainer}>
        <img className={classes.img} src={joinUs} alt="join-us" />
      </Grid>
    </Grid>
  );
};

export default JoinUs;
