import { Grid, Typography } from "@mui/material";
import classes from "./OurModel.module.css";
import bgImg from "../../assets/images/our-model-bg.png";
import titleFrame from "../../assets/images/title-frame.svg";
import HiringProcess from "../../components/OurModel/HiringProcess";
import searchIcon from "../../assets/images/search-model.svg";
import selectIcon from "../../assets/images/select-model.svg";
import startupIcon from "../../assets/images/startup-model.svg";
import scaleupIcon from "../../assets/images/scaleup-model.svg";
import SloganBanner from "../../components/SloganBanner";

const hiringProcesses = [
  {
    icon: searchIcon,
    title: "Search",
    alt: "search-icon",
    description:
      "We analyze your requirements and search for qualified candidates.",
  },
  {
    icon: selectIcon,
    title: "Select",
    alt: "select-icon",
    description:
      "you can conduct video interviews with the shortlist to make your selection",
  },
  {
    icon: startupIcon,
    title: "Start up",
    alt: "startup-icon",
    description:
      "We'll hire them on your behalf and get them up and running with the right software and equipment",
  },
  {
    icon: scaleupIcon,
    title: "Scale up",
    alt: "scaleup-icon",
    description:
      "manage your remote developer. We provide secure firewalls and virtual private networks.",
  },
];

const OurModel = () => {
  return (
    <Grid>
      <Grid container alignItems="center" className={classes.bgImgContainer}>
        <img src={bgImg} className={classes.bgImg} />
      </Grid>

      <SloganBanner />

      <Grid className={classes.description}>
        <Typography variant="h3" fontWeight={400}>
          We put all our developers through a rigious recruitment process, if
          they don't meet our standard then we don't hire them. <br />
          Our developers are the{" "}
          <span style={{ fontWeight: 500 }}>Top 5% of all developers.</span>
        </Typography>
      </Grid>

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.headerContainer}
      >
        <img
          className={classes.headerIcon}
          src={titleFrame}
          alt="title-frame"
        />
        <Typography
          variant="h2"
          fontWeight={700}
          className={classes.headerTitle}
        >
          Hiring process
        </Typography>
      </Grid>

      <Grid
        container
        justifyContent="center"
        className={classes.hiringProcessContainer}
      >
        {hiringProcesses.map((hiringProcess, index) => (
          <HiringProcess
            key={`hiring-process-${index}`}
            number={`0${index + 1}.`}
            icon={hiringProcess.icon}
            title={hiringProcess.title}
            alt={hiringProcess.alt}
            description={hiringProcess.description}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default OurModel;
