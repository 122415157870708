import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import {  Alert, Dialog, Divider, Typography } from "@mui/material";
import htmlParser from "html-react-parser";

import freeTextIcon from "../../assets/images/free-text-icon.svg";
import yesNoIcon from "../../assets/images/yes-no-icon.svg";
import recordIcon from "../../assets/images/record-icon.svg";
import { questionTypes } from "../../constants/questionTypes";
import classes from "./ViewJob.module.css";
import rightArrowIcon from "../../assets/images/right-arrow-icon-white.svg";
import cancelIcon from "../../assets/images/cancel-icon.svg";
import useFlashMsg from "../../hooks/useFlashMsg";
import { viewApplicationForUserAPI, viewJobAPI } from "../../services";
import DisplayCandidateApplication from "../../components/DisplayCandidateApplication";
import useApplication from "../../hooks/useApplication";
import VerifyEmailSection from "../../components/VerifyEmailSection";
import { useSelector } from "react-redux";

const screeningQuestions = [
  { icon: "text", title: "Why do you want to join our company?" },
  {
    icon: "text",
    title: "What makes you the ideal candidate for this position?",
  },
  {
    icon: "text",
    title:
      "Tell us about yourself. What makes you unique from other candidates?",
  },
  { icon: "yes-no", title: "Do you have your own laptop?" },
  { icon: "record", title: "Please introduce yourself in English" },
  { icon: "text", title: "Why do you want to join our company?" },
];

const getQuestionTypeIcon = (type) => {
  switch (type) {
    case questionTypes.TEXT:
      return freeTextIcon;
    case questionTypes.YES_NO:
      return yesNoIcon;
    case questionTypes.RECORD:
      return recordIcon;
  }

  return freeTextIcon;
};

const ViewJob = () => {
  const navigate = useNavigate();
  const authUser = useSelector((state) => state.authReducer);
  const { jobId } = useParams();
  const { handleErrorFlash } = useFlashMsg();
  const [job, setJob] = useState({
    yearsExperience: [0, 0],
    salaryRange: [0, 0],
    applied: false
  });
  const [displayApplication, setDisplayApplication] = useState(false);
  const [application, setApplication] = useState({})

  const handleDisplayApplication = (type) => (e) => {
    setDisplayApplication(type === "open" ? true : false);
  };

  const getJobHandler = useCallback(async () => {
    try {
      const resultJob = await viewJobAPI(jobId);
      const resultJobData = resultJob.data.data.data

      setJob(resultJobData);

      // if applicant applied then recieve his application
      if(resultJobData.applied) {
        const resultApplication = await viewApplicationForUserAPI(jobId);
        setApplication(resultApplication.data.data.data);
      }
    } catch (err) {
      console.log(err);
      const errMsg = err.response.data.err?.msg || err.message;

      if (err.response.data.err) console.log(err.response.data.err);

      handleErrorFlash("error occurred while fetching candidate's application");
    }
  }, [jobId]);

  useEffect(() => {
    getJobHandler();
  }, [getJobHandler]);

  const navigateToApply = () => {
    navigate(`/job/apply-to-job/${job?._id}`);
  };

  const headToJobList = () => {
    navigate("/jobs");
  };

  return (
    <div className={classes.container}>
      <Dialog
          sx={{
            "& .MuiDialogContent-root": {
              padding: 0,
            },
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                margin: "0 15px",
                maxWidth: "1000px",  // Set your width here
              },
            },
          }}
        open={displayApplication}
      >
        <DisplayCandidateApplication application={application} handleDisplayApplication={handleDisplayApplication} />
      </Dialog>
      <div className={classes.mainContainer}>
        {
          authUser?.loggedIn && !authUser?.user?.isVerified && <VerifyEmailSection />
        }

{
          !authUser?.loggedIn && <Alert className={classes.alertInfo} severity="info">
            You Need to Login to Apply for this Job!
          </Alert>
        }

        <div className={classes.controlBtnsContainer}>
          <button onClick={headToJobList} className={classes.closeBtn}>
            Cancel <img src={cancelIcon} alt="cancel-icon" />
          </button>
          {job?.applied && (
            <>
                        <button
              className={`${classes.applyForJobBtn} ${classes.disabledBtn}`}
            >
              Already Applied
            </button>
            <button
            onClick={handleDisplayApplication("open")}
              className={`${classes.applyForJobBtn}`}
            >
              View My Application
            </button>
            </>
          )}
          {!job?.applied && (
            <button
              onClick={authUser?.user?.isVerified ? navigateToApply : () => {}}
              className={`${classes.applyForJobBtn} ${authUser?.user?.isVerified ? "" : classes.disabledBtn}`}
            >
              Apply for this job{" "}
              <img src={rightArrowIcon} alt="right-arrow-icon" />
            </button>
          )}
        </div>

        {job?.applied && (
        <div className={classes.applicationStatus}>
        <Typography textAlign="center" variant="h3" color="#42BF76">Your application is still being viewed</Typography>
        <p>You will be informed about the status of your application when It gets viewed soon.</p>
      </div>
          )}

        <div className={classes.jobDetails}>
          <Typography variant="h3">Job Details</Typography>
          <div className={classes.jobDetailsData}>
            <Typography
              className={classes.jobDetailData}
              variant="h3"
              fontWeight={400}
            >
              Job Title:{" "}
              <span className={classes.jobDetailValue}>{job?.title}</span>
            </Typography>
            <Typography
              className={classes.jobDetailData}
              variant="h3"
              fontWeight={400}
            >
              Job Category:{" "}
              <span className={classes.jobDetailValue}>{job?.postType}</span>
            </Typography>
            <Typography
              className={classes.jobDetailData}
              variant="h3"
              fontWeight={400}
            >
              Country:{" "}
              <span className={classes.jobDetailValue}>{job?.country}</span>
            </Typography>
            <Typography
              className={classes.jobDetailData}
              variant="h3"
              fontWeight={400}
            >
              City: <span className={classes.jobDetailValue}>{job?.city}</span>
            </Typography>
            <Typography
              className={classes.jobDetailData}
              variant="h3"
              fontWeight={400}
            >
              Job Type:{" "}
              <span className={classes.jobDetailValue}>
                {job?.jobType?.join(", ")}
              </span>
            </Typography>
            <Typography
              className={classes.jobDetailData}
              variant="h3"
              fontWeight={400}
            >
              Years Of Experiences:{" "}
              <span className={classes.jobDetailValue}>
                {job?.yearsExperience[0]}-{job?.yearsExperience[1]} Years
              </span>
            </Typography>
            <Typography
              className={classes.jobDetailData}
              variant="h3"
              fontWeight={400}
            >
              Career Level:{" "}
              <span className={classes.jobDetailValue}>{job.careerLevel}</span>
            </Typography>
            <Typography
              className={classes.jobDetailData}
              variant="h3"
              fontWeight={400}
            >
              Salary Range:{" "}
              <span className={classes.jobDetailValue}>
              Confidential
              </span>
            </Typography>
          </div>
        </div>

        <div className={classes.jobDescription}>
          <Typography variant="h3">Job Description</Typography>
          <Typography className={classes.jobDescriptionData}>
            {htmlParser(job?.description || "")}
          </Typography>
          <Divider className={classes.dividerStyle} />
          <Typography variant="h3">Job Requirements</Typography>
          <Typography className={classes.jobRequirementsData}>
            {htmlParser(job?.description || "")}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default ViewJob;

/*

        <div className={classes.screeningQuestions}>
          <Typography variant="h3">Screening Questions</Typography>
          <div className={classes.screeningQuestionsContainer}>
            {screeningQuestions.map((screeningQuestion, index) => (
              <div key={index} className={classes.screeningQuestion}>
                <img src={getQuestionTypeIcon(screeningQuestion.icon)} />
                <Typography>{screeningQuestion.title}</Typography>
              </div>
            ))}
          </div>
        </div>

*/
