import { useState } from "react";
import { useDispatch } from "react-redux";
import useFlashMsg from "../../hooks/useFlashMsg";
import { reverifyAccountAPI, verifyAccountAPI } from "../../services";
import classes from "./VerifySnackbar.module.css";

const VerifyEmailSection = () => {
  const [initial, setInitial] = useState(true);
  const { handleErrorFlash } = useFlashMsg();

  const verifyAccountHandler  = async () => {
    if(initial) setInitial(false);
    try {
      const result = await reverifyAccountAPI();
    } catch(err) {
      console.log(err);
      const errMsg = err?.response?.data?.err?.msg || err?.message;

      handleErrorFlash("error occurred while re-verifying your account")
    }
  }
  return (
        <div className={`${classes.container} ${initial ? classes.warning : classes.info}`}>
          {
            initial ? <>
                        <p>Email Verification is Needed!</p>
            <button onClick={verifyAccountHandler} className={classes.verifyBtn}>Verify</button>
            </> : <>
            <p>Email Verification Has been sent Check your Email Inbox!</p>
            <button onClick={verifyAccountHandler} className={classes.verifyBtn}>Resend Email</button>
            </>
          }

        </div>
  );
};

export default VerifyEmailSection;
