import { Grid, Menu, MenuItem, Typography } from "@mui/material";
import { useState } from "react";
import moment from "moment";

import classes from "./JobPost.module.css";
import shareIcon from "../../../assets/images/share-icon.svg";
import threeDotsIcon from "../../../assets/images/three-dots-icon.svg";
import timeIcon from "../../../assets/images/time-icon.svg";
import rightArrowIcon from "../../../assets/images/right-arrow-icon-white.svg";
import homeIcon from "../../../assets/images/home-icon.svg";
import { Link } from "react-router-dom";
import { toggleActivationJobAPI } from "../../../services";
import useFlashMsg from "../../../hooks/useFlashMsg";

const JobPost = ({ job, updateJobToggle }) => {
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const { handleErrorFlash } = useFlashMsg()

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const openMenu = Boolean(anchorElMenu);

  const handleMenuOpen = (e) => {
    setAnchorElMenu(e.currentTarget);
  };

  const toggleActivationHandler = async () => {
    try {
      await toggleActivationJobAPI(job._id, !job.isActive);

      handleCloseMenu()
      updateJobToggle(job._id);
    } catch (err) {
      console.log(err);
      const errMsg = err.response.data.err?.msg || err.message;

      if (err.response.data.err) console.log(err.response.data.err);

      handleErrorFlash("error occurred while activating job");
    }
  };

  return (
    <Grid
      container
      className={`${classes.jobContainer} ${
        job.isActive ? "" : classes.inactiveJob
      }`}
    >
      <Menu
        anchorEl={anchorElMenu}
        open={openMenu}
        onClose={handleCloseMenu}
        PaperProps={{
          style: {
            width: "15ch",
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Link to={`/admin/edit-job/${job._id}`} className={classes.editMenuBtn}>
          <MenuItem>Edit</MenuItem>
        </Link>
        {job.isActive ? (
          <MenuItem onClick={toggleActivationHandler}>Deactivate</MenuItem>
        ) : (
          <MenuItem onClick={toggleActivationHandler}>Activate</MenuItem>
        )}
      </Menu>

      <Grid
        container
        justifyContent="space-between"
        className={classes.jobTitle}
      >
        <Typography variant="h3" fontWeight={700}>
          {job.title}
        </Typography>
        <Grid container columnGap="26px" className={classes.jobIconContainer}>
          <img src={shareIcon} alt="share-icon" />
          <img
            onClick={handleMenuOpen}
            src={threeDotsIcon}
            alt="three-dots-icon"
          />
        </Grid>
      </Grid>
      <Grid className={classes.jobDetailsContainer}>
        <Typography
          variant="body1"
          fontWeight={400}
          className={classes.locationVacanies}
        >
          {job.country ? job.country : "Egypt"}, {job.city ? job.city : "Cairo"}{" "}
          - {job.vacancyNumber} Vacanies
        </Typography>
        <Typography
          variant="body1"
          fontWeight={400}
          className={classes.jobViewsApplicants}
        >
          200 Job views - 20 Applicants
        </Typography>
        <Typography
          variant="body1"
          fontWeight={500}
          className={classes.newStrongApplicants}
        >
          6 New strong applicants
        </Typography>
        <Typography
          variant="body1"
          fontWeight={400}
          className={classes.postDate}
        >
          Posted{" "}
          {moment.utc(job.createdAt).local().startOf("seconds").fromNow()} by
          you
        </Typography>
        <Grid className={classes.jobTypesContainer}>
          {job.jobType.includes("full-time") && (
            <Grid className={classes.jobTypeContainer}>
              <img src={timeIcon} className={classes.timeIcon} />{" "}
              <Typography
                variant="body1"
                fontWeight={400}
                className={classes.jobTypeIconText}
              >
                Full Time
              </Typography>
            </Grid>
          )}

          {job.jobType.includes("work-from-home") && (
            <Grid
              className={`${classes.jobTypeContainer} ${classes.workFromHomeContainer}`}
            >
              <img src={homeIcon} className={classes.homeIcon} />{" "}
              <Typography
                variant="body1"
                fontWeight={400}
                className={classes.jobTypeIconText}
              >
                Work From Home
              </Typography>
            </Grid>
          )}

          {job.jobType.includes("part-time") && (
            <Grid
              className={`${classes.jobTypeContainer} ${classes.workFromHomeContainer}`}
            >
              <img src={homeIcon} className={classes.homeIcon} />{" "}
              <Typography
                variant="body1"
                fontWeight={400}
                className={classes.jobTypeIconText}
              >
                Part Time
              </Typography>
            </Grid>
          )}

          {job.jobType.includes("freelance-project") && (
            <Grid
              className={`${classes.jobTypeContainer} ${classes.workFromHomeContainer}`}
            >
              <img src={homeIcon} className={classes.homeIcon} />{" "}
              <Typography
                variant="body1"
                fontWeight={400}
                className={classes.jobTypeIconText}
              >
                Freelance/Project
              </Typography>
            </Grid>
          )}

          {job.jobType.includes("shift-based") && (
            <Grid
              className={`${classes.jobTypeContainer} ${classes.workFromHomeContainer}`}
            >
              <img src={homeIcon} className={classes.homeIcon} />{" "}
              <Typography
                variant="body1"
                fontWeight={400}
                className={classes.jobTypeIconText}
              >
                Shift Based
              </Typography>
            </Grid>
          )}
        </Grid>
        <Typography
          variant="body1"
          fontWeight={500}
          className={classes.notViewed}
        >
          41 Not viewed
        </Typography>
      </Grid>

            <div className={classes.viewCandidatesBtnContainer}>
            <Link className={classes.navigateBtn} to={`/admin/job/view-candidates/${job._id}`}>
              <button className={classes.viewCandidatesBtn}>
                  View Candidates
              </button>
            </Link>
            </div>

    </Grid>
  );
};

export default JobPost;
