import { FormControlLabel, Radio, RadioGroup } from "@mui/material"
import classes from "./YesNoAnswer.module.css";

const YesNoAnswer = ({handleScreeningAnswer, question, answer}) => {

  const handleYesNo = (e) => {
    const answer = e.target.value;

    handleScreeningAnswer(answer, question.id, question.type);
  };

    return(
        <div>
        <RadioGroup
          value={answer.answer}
          onChange={handleYesNo}
          row
        >
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel className={classes.noBtn} value="no" control={<Radio />} label="No" />
        </RadioGroup>
        </div>
    )
}

export default YesNoAnswer