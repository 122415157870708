export const loginFormValidation = (data) => {

    let errors = {email: [], password: []}

    const {email, password} = data

    errors = {
        ...errors,
        email: validateEmail(email),
        password: validatePassword(password)
    }
    
    const hasError = Object.values(errors).find(err => err.length > 0) ? true : false;

    return {hasError, errors}
}

const validateEmail = (email) => {
    const errors = []

    if(email.length <= 0) errors.push("Email is Required")
    if(!email.toLowerCase().match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) errors.push("invalid email format")

    return errors
}

const validatePassword = (password) => {
    const errors = []

    if(password <= 0) errors.push("Password is Required")

    return errors
}