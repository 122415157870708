import classes from "./ClientValidatorErrorDisplayer.module.css";

const ClientValidatorErrorDisplayer = ({fieldErrors}) => {

    return(
        <div className={classes.container}>
            {
                fieldErrors?.length > 0 && fieldErrors.map(error => <span className={classes.errorMsg}>* {error}</span>)
            }
        </div>
    )
}

export default ClientValidatorErrorDisplayer;