import { Grid, Typography } from "@mui/material"
import moment from "moment"
import classes from "./JobPost.module.css"
import shareIcon from "../../assets/images/share-icon.svg";
import threeDotsIcon from "../../assets/images/three-dots-icon.svg";
import timeIcon from "../../assets/images/time-icon.svg";
import homeIcon from "../../assets/images/home-icon.svg";
import rightArrowIcon from "../../assets/images/right-arrow-icon-white.svg";
import { Link } from "react-router-dom";

const JobPost = ({job}) => {
  
    return (
        <Grid container className={classes.jobContainer}>
        <Grid
          container
          justifyContent="space-between"
          className={classes.jobTitle}
        >
          <Typography variant="h3" fontWeight={700}>
            {job.title}
          </Typography>
          <Grid
            container
            columnGap="26px"
            className={classes.jobIconContainer}
          >
            <img src={shareIcon} alt="share-icon" />
            {
              /* <img src={threeDotsIcon} alt="three-dots-icon" /> */
            }
          </Grid>
        </Grid>
        <div className={classes.jobBodyContainer}>
            <Grid className={classes.jobDetailsContainer}>
                <Typography
                    variant="body1"
                    fontWeight={400}
                    className={classes.locationVacanies}
                >
                    {job.country ? job.country : "Egypt"}, {job.city ? job.city : "Cairo"} - {job.vacancyNumber} Vacanies
                </Typography>
                <Grid className={classes.jobTypesContainer}>
                    <Grid className={classes.jobTypeContainer}>
                    <img src={timeIcon} className={classes.timeIcon} />{" "}
                    <Typography variant="body1" fontWeight={400} className={classes.jobTypeIconText}>
                    {job.jobType[0]}
                    </Typography>
                    </Grid>
                    <Grid className={`${classes.jobTypeContainer} ${classes.workFromHomeContainer}`}>
                    <img src={homeIcon} className={classes.homeIcon} />{" "}
                    <Typography variant="body1" fontWeight={400} className={classes.jobTypeIconText}>
                        {job.jobType[1]}
                    </Typography>
                    </Grid>
                </Grid>

                <Typography
                    variant="body1"
                    fontWeight={400}
                    className={classes.postDate}
                >
                    Posted {moment(job.createdAt).fromNow()}
                </Typography>
            </Grid>

            <Link className={classes.navigateBtn} to={`/view-job/${job._id}`}>
              <button className={classes.viewJobBtn}>
                  View this job
                  <img src={rightArrowIcon} alt="right-arrow-icon" />
              </button>
            </Link>
        </div>
      </Grid>
    )
}

export default JobPost