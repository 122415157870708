import { authServiceProvider, serviceProvider } from "./serviceProvider";

export const registerAPI = (data) =>
  serviceProvider.post(`auth/register`, data);

export const loginAPI = (data) => serviceProvider.post(`auth/login`, data);

export const logoutAPI = (data) => serviceProvider.delete(`auth/logout`, {data});

export const getAccessTokenAPI = (data) => serviceProvider.post("auth/token", data);

export const testAPI = (data, config) => serviceProvider.post("test", data, config);

export const createApplicationAPI = (jobId, data, config) => authServiceProvider.post(`application/${jobId}`, data, config)

export const getJobsListAPI = () => serviceProvider.get("job")

export const getAdminJobsListAPI = () => authServiceProvider.get("job/admin-jobs")

export const getJobAPI = (jobId) => serviceProvider.get(`job/${jobId}`)

export const viewJobAPI = jobId => authServiceProvider.get(`job/view-job/${jobId}`)

export const createJobAPI = (data) => authServiceProvider.post(`job`, data)

export const editJobAPI = (jobId, data) => authServiceProvider.put(`job/${jobId}`, data)

export const toggleActivationJobAPI = (jobId, data) => authServiceProvider.get(`job/${jobId}/${data}`)

export const getCountriesAPI = () => serviceProvider.get('get-countries')

export const getCitiesAPI = countryId => serviceProvider.get(`get-cities/${countryId}`)

export const viewApplicationForUserAPI = jobId => authServiceProvider.get(`application/applicant/view-application/${jobId}`)

export const getAllApplicationsAPI = jobId => authServiceProvider.get(`application/by-job/${jobId}`)

export const moveApplicationToAPI = (applicationId, moveTo) => authServiceProvider.get(`application/move-to/${applicationId}/${moveTo}`)

export const getCandidateApplicationAPI = (applicationId) => authServiceProvider.get(`application/${applicationId}`);

export const updateProfileAPI = (data) => authServiceProvider.put(`auth/update-profile`, data);

export const forgotPasswordAPI = (data) => serviceProvider.post("auth/forgot-password", data)

export const checkIfValidTokenAPI = (userId, resetToken) => serviceProvider.get(`auth/check-valid-token/${userId}/${resetToken}`)

export const resetPasswordAPI = data => serviceProvider.post('auth/reset-password', data)

export const changePasswordAPI = data => authServiceProvider.post('auth/change-password', data)

export const verifyAccountAPI = data => authServiceProvider.post('auth/verify-account', data)

export const reverifyAccountAPI = () => authServiceProvider.get('auth/reverify-account')

export const createContactUsFormAPI = data => serviceProvider.post("/contact-us", data);

export const getContactUsListAPI = () => authServiceProvider.get("/contact-us");

export const getAllTestdomeTestsAPI = () => authServiceProvider.get("/testdome/tests");

export const createTestLinkForCandidateAPI = data => authServiceProvider.post("/testdome/create-candidate-test", data)

export const getCandidateTestDataAPI = data => authServiceProvider.post("/testdome/get-candidate-data", data)