import { Alert, Button, Grid, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useFlashMsg from "../../hooks/useFlashMsg";
import { getJobsListAPI } from "../../services";
import searchIcon from "../../assets/images/search-icon.svg";
import addIcon from "../../assets/images/add-icon.svg";
import titleFrame from "../../assets/images/title-frame.svg";
import classes from "./JobList.module.css";
import JobPost from "../../components/JobPost";
import briefcaseIcon from "../../assets/images/briefcase.png"
import { useSelector } from "react-redux";
import VerifyEmailSection from "../../components/VerifyEmailSection";

const JobsList = () => {
  const navigate = useNavigate();
  const authUser = useSelector(state => state.authReducer);

  const [jobs, setJobs] = useState([]);
  const { handleErrorFlash } = useFlashMsg();

  const getJobsList = useCallback(async () => {
    try {
      const res = await getJobsListAPI();

      setJobs(res.data.data.data);
    } catch (err) {
      console.log(err);
      const errMsg = err.response.data.err?.msg || err.message;

      if (err.response.data.err) console.log(err.response.data.err);

      handleErrorFlash("error occurred while fetcing job list");
    }
  }, []);

  useEffect(() => {
    getJobsList();
  }, [getJobsList]);

  const handleApply = (jobId) => {
    navigate(`/apply/${jobId}`);
  };

  return (
    <div className={classes.page}>
      <Grid className={classes.container}>

        {/*
                 <Grid container columnGap="40px" className={classes.headerContainer}>
          <div className={classes.searchInputContainer}>
            <img
              src={searchIcon}
              className={classes.searchIcon}
              alt="search-icon"
            />
            <input
              type="text"
              placeholder="Search by job title"
              className={classes.searchInput}
            />
          </div>
        </Grid>
         */}
                {
          authUser?.loggedIn && !authUser?.user?.isVerified && <VerifyEmailSection />
        }

{
          !authUser?.loggedIn && <Alert className={classes.alertInfo} severity="info">
            You Need to Login to Apply for this Job!
          </Alert>
        }


        <Grid
          container
          justifyContent="center"
          className={classes.tabsContainer}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.tabContainer}
          >
            <img
              className={classes.tabIcon}
              src={titleFrame}
              alt="title-frame"
            />
            <Typography
              variant="h3"
              fontWeight={700}
              className={`${classes.tabTitle} ${classes.activeTabTitle}`}
            >
              All Jobs {`(${jobs.length})`}
            </Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.jobsContainer} rowGap="32px">
          {
            jobs.length === 0 && <div className={classes.emptyJobs}>
              <img className={classes.noJobsIcon} src={briefcaseIcon} />
              <p>No Jobs Available at the moment!</p>
            </div>
          }
          {jobs.length > 0 && jobs.map((job, index) => (
            <JobPost key={index} job={job} />
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default JobsList;