import { Grid, TextareaAutosize, TextField, Typography } from "@mui/material";
import classes from "./ContactForm.module.css";
import titleFrame from "../../assets/images/title-frame.svg";
import useContactForm from "../../hooks/useContactFom";
import ClientValidatorErrorDisplayer from "../ClientValidatorErrorDisplayer";

const ContactForm = () => {
  
  const {contactFormState, handleFieldChange, clientValidationErrors, handleFormSubmit} = useContactForm();

  return (
    <Grid className={classes.contactForm}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.headerContainer}
      >
        <img
          className={classes.headerIcon}
          src={titleFrame}
          alt="title-frame"
        />
        <Typography
          variant="h2"
          fontWeight={700}
          className={classes.headerTitle}
        >
          Get in touch
        </Typography>
      </Grid>

      <Grid xs={12} className={classes.fieldContainer}>
        <Grid xs={12} className={classes.fieldNameContainer}>
          <Typography variant="body1" className={classes.fieldName}>
            Your Name
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={contactFormState.name}
            size="small"
            required
            variant="outlined"
            sx={{ width: "100%", fontSize: "14px" }}
            onChange={handleFieldChange("name")}
          />
        </Grid>
        <ClientValidatorErrorDisplayer fieldErrors={clientValidationErrors?.name} />
      </Grid>

      <Grid xs={12} className={classes.fieldContainer}>
        <Grid xs={12} className={classes.fieldNameContainer}>
          <Typography variant="body1" className={classes.fieldName}>
            Your Email
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={contactFormState.email}
            size="small"
            required
            variant="outlined"
            sx={{ width: "100%", fontSize: "14px" }}
            onChange={handleFieldChange("email")}
          />
        </Grid>
        <ClientValidatorErrorDisplayer fieldErrors={clientValidationErrors?.email} />
      </Grid>

      <Grid xs={12} className={classes.fieldContainer}>
        <Grid xs={12} className={classes.fieldNameContainer}>
          <Typography variant="body1" className={classes.fieldName}>
            Title
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={contactFormState.title}
            size="small"
            required
            variant="outlined"
            sx={{ width: "100%", fontSize: "14px" }}
            onChange={handleFieldChange("title")}
          />
        </Grid>
        {contactFormState?.title.length === 60 && <ClientValidatorErrorDisplayer fieldErrors={["Title Shouldn't Exceed 60 Characters"]} />}
        <ClientValidatorErrorDisplayer fieldErrors={clientValidationErrors?.title} />
      </Grid>

      <Grid xs={12} className={classes.textAreaContainer}>
        <Grid xs={12} className={classes.fieldNameContainer}>
          <Typography variant="body1" className={classes.fieldName}>
            Message
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextareaAutosize value={contactFormState.message}  className={classes.textArea} onChange={handleFieldChange("message")} />
        </Grid>
        <ClientValidatorErrorDisplayer fieldErrors={clientValidationErrors?.message} />
      </Grid>

      <Grid container justifyContent="center">
        <button onClick={handleFormSubmit} className={classes.btn}>Send message</button>
      </Grid>
    </Grid>
  );
};

export default ContactForm;
