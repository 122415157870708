import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
} from "@mui/material";
import { useSelector } from "react-redux";
import LoginIcon from "@mui/icons-material/Login";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";

import classes from "./DrawerComponent.module.css";
import logo from "../../assets/images/logo.svg"


const DrawerComponent = ({ pages, pathname }) => {
  const navigate = useNavigate();
  const authUser = useSelector((state) => state.authReducer);
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleOpenDrawer = (value) => {
    return () => setOpenDrawer(value);
  };

  const handleNotLoggedInLink = (link) => (e) => {
    navigate(link);
    handleOpenDrawer(false);
  };

  return (
    <div className={classes.container}>
      <Drawer PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" }, square: false }} open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <div className={classes.drawerContentContainer}>
          <div className={classes.logoContainer}>
            <img className={classes.logoImg} src={logo} alt="sidebar-logo" />
          </div>
          <List className={classes.listContainer}>
            {pages.map((page, index) => (
              <Link
                key={`nav-elements-${index}`}
                to={page.link}
                className={classes.navLink}
              >
                <ListItem className={classes.listItem} onClick={handleOpenDrawer(false)}>
                  {page.icon}
                  <ListItemText
                    className={`${classes.linkText} ${
                      pathname === page.link ? classes.activeLink : ""
                    }`}
                  >
                    {page.text}
                  </ListItemText>
                </ListItem>
              </Link>
            ))}
          </List>
          {!authUser.loggedIn && (
            <div className={classes.notLoggedInContainer}>
              <Divider className={classes.divider} />
              <div className={classes.notLoggedInText}>
                <div
                  className={classes.notLoggedInItem}
                  onClick={handleNotLoggedInLink("/login")}
                >
                  <p>Login</p>
                </div>

                <div
                  className={classes.notLoggedInItem}
                  onClick={handleNotLoggedInLink("/register")}
                >

                  <p>Register</p>
                </div>
              </div>

            </div>
          )}
        </div>
      </Drawer>
      <IconButton
        size="large"
        onClick={() => setOpenDrawer(!openDrawer)}
        color="inherit"
      >
        <MenuIcon className={classes.burgerIcon} />
      </IconButton>
    </div>
  );
};

export default DrawerComponent;
