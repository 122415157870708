import classes from "./System.module.css"
import Navbar from "../Navbar";
import Footer from "../Footer";


const System = ({children, displayFooter = true}) => {

    return(
        <div className={classes.container}>
            <Navbar />
            {children}
            {displayFooter && <Footer /> }
        </div>
    )
}

export default System;