/**
 * FILE FOR TESTING ONLY
 */

import { Grid, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApplicationForm from "../../../components/ApplicationForm";
import useFlashMsg from "../../../hooks/useFlashMsg";
import { getJobAPI } from "../../../services";

const CreateApplication = () => {

    const { jobId } = useParams();
    const [job, setJob] = useState({})
    const { handleErrorFlash } = useFlashMsg()

    const getJob = useCallback(async () => {
        try {
            const res = await getJobAPI(jobId);

            setJob(res.data.data.data)
        } catch(err) {
            console.log(err);
            const errMsg = err.response.data.err?.msg || err.message;
      
            if (err.response.data.err) console.log(err.response.data.err);
      
            handleErrorFlash("error occurred while fetching job");
        }
    }, [jobId])

    useEffect(() => {
        getJob()
    }, [getJob])

    return(
        <Grid>
            <Typography variant="h6">{job.title} Job Position</Typography>
            <ApplicationForm job={job} />
        </Grid>
    )
}

export default CreateApplication;