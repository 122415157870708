import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useFlashMsg from "../../hooks/useFlashMsg";
import { verifyAccountAPI } from "../../services";
import { verifyAccountAction } from "../../slices/auth-slice";

const VerifyAccount = () => {
    const { resetToken } = useParams();
    const authUser = useSelector(state => state.authReducer)
    const navigate = useNavigate();
    const { handleErrorFlash } = useFlashMsg();
    const dispatch = useDispatch();

    const verifyAccountHandler = useCallback(async () => {
        try {
            const result = await verifyAccountAPI({resetToken});

            dispatch(verifyAccountAction());

            navigate("/")
        } catch(err) {
            console.log(err);
            const errMsg = err?.response?.data?.err?.msg || err?.message;

            handleErrorFlash("Verification Link Either Expired or Something went wrong!")
      
            navigate("/")
        }
    }, [])

    useEffect(() => {
        if(!authUser.loggedIn) {
            navigate(`/login?link=${resetToken}`)
            handleErrorFlash("You need to be Logged In First! Login Then use the Link Again")
        } else {
            verifyAccountHandler();
        }
    }, [])

    return(
        <div>
            Loading...
        </div>
    )
}

export default VerifyAccount;