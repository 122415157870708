import { Divider, Typography } from "@mui/material";
import htmlParser from "html-react-parser";
import freeTextIcon from "../../../assets/images/free-text-icon.svg";
import yesNoIcon from "../../../assets/images/yes-no-icon.svg";
import recordIcon from "../../../assets/images/record-icon.svg";
import { questionTypes } from "../../../constants/questionTypes";
import classes from "./Preview.module.css";

const getQuestionTypeIcon = (type) => {
    switch (type) {
      case questionTypes.TEXT:
        return freeTextIcon;
      case questionTypes.YES_NO:
        return yesNoIcon;
      case questionTypes.RECORD:
        return recordIcon;
    }
  
    return freeTextIcon;
  };

const Preview = ({ jobState, questionsState }) => {
  return (
    <div className={classes.container}>
      <div className={classes.jobDetails}>
        <Typography variant="h3">Job Details</Typography>
        <div className={classes.jobDetailsData}>
          <Typography
            className={classes.jobDetailData}
            variant="h3"
            fontWeight={400}
          >
            Job Title:{" "}
            <span className={classes.jobDetailValue}>{jobState.title}</span>
          </Typography>
          <Typography
            className={classes.jobDetailData}
            variant="h3"
            fontWeight={400}
          >
            Job Category:{" "}
            <span className={classes.jobDetailValue}>{jobState.postType}</span>
          </Typography>
          <Typography
            className={classes.jobDetailData}
            variant="h3"
            fontWeight={400}
          >
            Country: <span className={classes.jobDetailValue}>{jobState.country}</span>
          </Typography>
          <Typography
            className={classes.jobDetailData}
            variant="h3"
            fontWeight={400}
          >
            City: <span className={classes.jobDetailValue}>{jobState.city}</span>
          </Typography>
          <Typography
            className={classes.jobDetailData}
            variant="h3"
            fontWeight={400}
          >
            Job Type:{" "}
            <span className={classes.jobDetailValue}>
              {jobState.jobType.join(", ")}
            </span>
          </Typography>
          <Typography
            className={classes.jobDetailData}
            variant="h3"
            fontWeight={400}
          >
            Years Of Experiences:{" "}
            <span className={classes.jobDetailValue}>{jobState.yearsExperience[0]}-{jobState.yearsExperience[1]} Years</span>
          </Typography>
          <Typography
            className={classes.jobDetailData}
            variant="h3"
            fontWeight={400}
          >
            Career Level:{" "}
            <span className={classes.jobDetailValue}>{jobState.careerLevel} level</span>
          </Typography>
          <Typography
            className={classes.jobDetailData}
            variant="h3"
            fontWeight={400}
          >
            Salary Range:{" "}
            <span className={classes.jobDetailValue}>{jobState.salaryRange[0]}-{jobState.salaryRange[1]}$</span>
          </Typography>
          <Typography
            className={classes.jobDetailData}
            variant="h3"
            fontWeight={400}
          >
            Testdome Test:{" "}
            <span className={classes.jobDetailValue}>{jobState.testdome?.id} - {jobState.testdome?.name}</span>
            <span className={classes.jobDetailValue}>{jobState.testdome?.description}</span>
          </Typography>
        </div>
      </div>

        <div className={classes.jobDescription}>
            <Typography variant="h3">Job Description</Typography>
            <Typography className={classes.jobDescriptionData}>{htmlParser(jobState.description || "")}</Typography>
            <Divider className={classes.dividerStyle} />
            <Typography variant="h3">Job Requirements</Typography>
            <Typography className={classes.jobRequirementsData}>{htmlParser(jobState.requirements || "")}</Typography>
        </div>

        <div className={classes.screeningQuestions}>
            <Typography variant="h3">Screening Questions</Typography>
            <div className={classes.screeningQuestionsContainer}>
                {
                    questionsState.map((question, index) => question.isActive && (
                        <div key={index} className={classes.screeningQuestion}>
                            <img src={getQuestionTypeIcon(question.type)} />
                            <Typography>{question.title}</Typography>
                        </div>
                    ))
                }
            </div>
        </div>

    </div>
  );
};

export default Preview;
