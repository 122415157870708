import { Grid } from "@mui/material"

const MultipleRatingAnswer = () => {

    return(
        <Grid>
            Under Construction
        </Grid>
    )
}

export default MultipleRatingAnswer