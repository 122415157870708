import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import logo from "../../assets/images/logo.svg";
import DrawerComponent from "../DrawerComponent";
import classes from "./Navbar.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { pages } from "../../utils/pages";
import NavbarRightSide from "./RightSide";

const Navbar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const location = useLocation();

  const navigateHome = () => {
    navigate("/")
  }

  return (
    <Grid
      container
      alignItems="center"
      className={classes.navbarContainer}
    >
            <div className={classes.navbarContentContainer}>
      {isMobile && <DrawerComponent pages={pages} pathname={location.pathname} />}


      <div className={classes.iconContainer}>
            <img onClick={navigateHome} src={logo} className={classes.logo} alt="logo" />
          </div>

      {!isMobile && (

<>

          <div className={classes.linksContainer}>
            {
              pages.map((page, index) => (
                <div key={`pages-${index}`}>
                <Link to={page.link} className={classes.routerLink}>
                  <Typography variant="body1" className={`${classes.navbarLink} ${location.pathname === page.link ? classes.activeLink : ""}`}>
                    {page.text}
                  </Typography>
                </Link>
              </div>
              ))
            }
          </div>


</>
      )}
            <NavbarRightSide />
            </div>

    </Grid>
  );
};

export default Navbar;
