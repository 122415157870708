import { useCallback, useReducer } from "react";
import { useDispatch } from "react-redux"

const APPLICATION_ACTIONS = {
  FIRSTNAME: "firstname",
  LASTNAME: "lastname",
  EMAIL: "email",
  GITHUB: "github",
  LINKEDIN: "linkedIn",
  WUZZUF: "wuzzuf",
  SALARY: "salary",
  COUNTRY: "country",
  CITY: "city",
  YEARSEXPERIENCE: "yearsExperience",
  CV: "cv",
};

const applicationReducer = (state, action) => {
  switch (action.type) {
    case APPLICATION_ACTIONS.FIRSTNAME:
      return { ...state, firstname: action.payload };
    case APPLICATION_ACTIONS.LASTNAME:
      return { ...state, lastname: action.payload };
    case APPLICATION_ACTIONS.EMAIL:
      return { ...state, email: action.payload };
    case APPLICATION_ACTIONS.GITHUB:
      return { ...state, github: action.payload };
    case APPLICATION_ACTIONS.LINKEDIN:
      return { ...state, linkedIn: action.payload };
    case APPLICATION_ACTIONS.WUZZUF:
      return { ...state, wuzzuf: action.payload };  
    case APPLICATION_ACTIONS.SALARY:
      return { ...state, salary: action.payload };
    case APPLICATION_ACTIONS.COUNTRY:
      return { ...state, country: action.payload };
    case APPLICATION_ACTIONS.CITY:
      return { ...state, city: action.payload };
    case APPLICATION_ACTIONS.YEARSEXPERIENCE:
      return { ...state, yearsExperience: action.payload };
    case APPLICATION_ACTIONS.CV:
      return { ...state, cv: action.payload };
    default:
      return state;
  }
};


const useApplication = () => {

    const [applicationForm, applicationDispatch] = useReducer(
      applicationReducer,
      {
        firstname: "",
        lastname: "",
        email: "",
        country: "Egypt",
        city: "Cairo",
        salary: 0,
        github: "",
        linkedIn: "",
        wuzzuf: "",
        yearsExperience: 0,
        cv: "",
      }
    );

    const handleApplicationForm = useCallback((e, fieldName, dataType) => {
      let value;
      switch (dataType) {
        case "number":
          value = Number(e.target.value.replace(/\D/,''));
          if(fieldName === "yearsExperience" && value > 40) value = 40
          break;
        case "boolean":
          value = e.target.value ? true : false;
          break;
        default:
          value = e.target.value;
          break;
      }
  
      applicationDispatch({ type: fieldName, payload: value });
    }, []);

    const handleCountry = (value) => {
      applicationDispatch({ type: "country", payload: value });
    }

    const handleCity = (value) => {
      applicationDispatch({ type: "city", payload: value });
    }

    return {
      applicationForm,
      applicationDispatch,
      handleCountry: (value) => handleCountry(value),
      handleCity: (e) => handleCity(e.target.value),
      handleApplicationForm: (fieldName, dataType = null) => (e) => handleApplicationForm(e, fieldName, dataType)
    }
}

export default useApplication