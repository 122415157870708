import { Grid, Typography } from "@mui/material";
import classes from "./ContactUs.module.css";
import bgImg from "../../assets/images/contact-us-bg.png";
import titleFrame from "../../assets/images/title-frame.svg";
import ContactForm from "../../components/ContactForm";
import emailIcon from "../../assets/images/email-contact.svg";
import phoneIcon from "../../assets/images/phone-contact.svg";
import addressIcon from "../../assets/images/address-contact.svg";

const ContactUs = () => {
  return (
    <Grid>
      <Grid container alignItems="center" className={classes.bgImgContainer}>
        <img src={bgImg} className={classes.bgImg} />
      </Grid>

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.headerContainer}
      >
        <img
          className={classes.headerIcon}
          src={titleFrame}
          alt="title-frame"
        />
        <Typography
          variant="h2"
          fontWeight={700}
          className={classes.headerTitle}
        >
          Contact informations
        </Typography>
      </Grid>

      <Grid
        container
        justifyContent="center"
        className={classes.cardsContainer}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          className={classes.cardContainer}
        >
          <img className={classes.icon} src={emailIcon} />
          <Typography
            variant="h3"
            fontWeight={400}
            className={`${classes.cardText} ${classes.email}`}
          >
            kevin@gettechforce.com
          </Typography>
        </Grid>

        <Grid
          container
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          className={classes.cardContainer}
        >
          <img className={classes.icon} src={phoneIcon} />
          <Typography
            variant="h3"
            fontWeight={400}
            className={classes.cardText}
          >
            +353 87 1432313
          </Typography>
        </Grid>

        <Grid
          container
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          className={classes.cardContainer}
        >
          <img className={classes.icon} src={addressIcon} />
          <Typography
            variant="h3"
            fontWeight={400}
            className={classes.cardText}
          >
            Cairo, Egypt
          </Typography>
        </Grid>
      </Grid>

      <div className={classes.contactFormContainer}>
        <ContactForm />
      </div>
    </Grid>
  );
};

export default ContactUs;
