import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { useNavigate, useParams } from "react-router-dom";

import cancelIcon from "../../../../assets/images/cancel-icon.svg";
import rightArrowIcon from "../../../../assets/images/right-arrow-icon.svg";
import whiteRightArrowIcon from "../../../../assets/images/right-arrow-icon-white.svg";
import leftArrowIcon from "../../../../assets/images/left-arrow-icon.svg";
import titleFrame from "../../../../assets/images/title-frame.svg";
import classes from "./EditJob.module.css";
import JobInformation from "../../../../components/Admin/JobInformation";
import AdminScreeningQuestions from "../../../../components/Admin/ScreeningQuestions";
import Preview from "../../../../components/Admin/Preview";
import useJob from "../../../../hooks/useJob";
import { editJobAPI } from "../../../../services";
import useFlashMsg from "../../../../hooks/useFlashMsg";
import APIValidatorErrorDisplayer from "../../../../components/APIValidatorErrorDisplayer";
import { JobInformationValidator } from "../../../../components/Admin/JobInformation/validator";


const EditJob = () => {
  const [activeTab, setActiveTab] = useState(1);
  const { handleErrorFlash, handleSuccessFlash } = useFlashMsg();
  const [clientValidationErrors, setClientValidationErrors] = useState(null);
  const [displayAPIValidation, setDisplayAPIValidation] = useState(false);
  const { jobId } = useParams();
  const navigate = useNavigate()
  const {
    jobState,
    questionsState,
    handleTextField,
    handleSelectField,
    handleJobType,
    handleYearsExperience,
    handleCareerLevel,
    handleSalaryRange,
    handleDraftField,
    handleVacancyNumber,
    handleVacancyNumberChange,
    addNewQuestionHandler,
    editQuestionHandler,
    deleteQuestionHandler,
    toggleIsActiveBtn,
  } = useJob(jobId);

  const handleBackPage = () => {
    setClientValidationErrors(null);
    setActiveTab((prevState) => (prevState === 1 ? 1 : prevState - 1));
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleProceedPage = () => {
    let validator;
    if(activeTab === 1) {
      validator = new JobInformationValidator(jobState);
    } else if (activeTab === 2) {
      //validator = new JobScreeningQuestionsValidator(applicationForm);
    }

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    // if validation caught display error
    if (validator?.foundError){
      setClientValidationErrors(validator.errors);
      return
    }

    setActiveTab((prevState) => (prevState === 3 ? 3 : prevState + 1));
  };
  
  const handleSubmitJob = async () => {
    setDisplayAPIValidation(null);
    const job = {
      ...jobState,
      questions: questionsState
    }

    try {
      const jobResult = await editJobAPI(jobId, job);

      handleSuccessFlash("Job Updated Successfully!");
      navigate("/admin/jobs");
    } catch (err) {
      console.log(err);
      const errMsg = err.response.data.err?.msg || err.message;

      if (err.response.data.err) {
        console.log(err.response.data.err);
        setDisplayAPIValidation(err.response.data.err);
      }

      handleErrorFlash("error occurred while updating the job");
    }
  }

  const headToJobsList = () => {
    navigate("/admin/jobs")
  }

  return (
    <Grid container justifyContent="center">
      <Grid className={classes.container}>
        <Grid
          container
          justifyContent="flex-end"
          className={classes.controlBtnContainer}
          columnGap="24px"
        >
          <button onClick={headToJobsList} className={`${classes.controlBtn} ${classes.cancelBtn}`}>
            Cancel
            <img
              src={cancelIcon}
              className={classes.controlIcon}
              alt="add-icon"
            />
          </button>
          <button
            className={`${classes.controlBtn} ${classes.submitBtn} ${
              activeTab === 3 ? classes.activeSubmitBtn : ""
            }`}
            onClick={handleSubmitJob}
          >
            Submit
            <img
              src={activeTab === 3 ? whiteRightArrowIcon : rightArrowIcon}
              className={classes.controlIcon}
              alt="right-arrow-con-icon"
            />
          </button>
        </Grid>

        <Grid className={classes.jobContainer}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.headerContainer}
          >
            <img
              className={classes.headerIcon}
              src={titleFrame}
              alt="title-frame"
            />
            <Typography
              variant="h2"
              fontWeight={700}
              className={classes.headerTitle}
            >
              Edit {jobState.title} job
            </Typography>
          </Grid>

          <div className={`${classes.stepperWrapper}`}>
            <div
              className={`${classes.stepperItem} ${
                activeTab > 1
                  ? classes.completed
                  : activeTab === 1
                  ? classes.active
                  : ""
              }`}
            >
              <div className={`${classes.stepCounter}`}>
                {activeTab > 1 ? <CheckIcon /> : "1"}
              </div>
              <div className={`${classes.stepName}`}>Job Information</div>
            </div>
            <div
              className={`${classes.stepperItem} ${
                activeTab > 2
                  ? classes.completed
                  : activeTab === 2
                  ? classes.active
                  : ""
              }`}
            >
              <div className={`${classes.stepCounter}`}>
                {activeTab > 2 ? <CheckIcon /> : "2"}
              </div>
              <div className={`${classes.stepName}`}>Screening Questions</div>
            </div>
            <div
              className={`${classes.stepperItem} ${
                activeTab > 3
                  ? classes.completed
                  : activeTab === 3
                  ? classes.active
                  : ""
              }`}
            >
              <div className={`${classes.stepCounter}`}>
                {activeTab > 3 ? <CheckIcon /> : "3"}
              </div>
              <div className={`${classes.stepName}`}>Preview</div>
            </div>
          </div>

          {
            displayAPIValidation && <APIValidatorErrorDisplayer displayAPIValidation={displayAPIValidation} />
          }

          {activeTab === 1 && (
            <JobInformation
              jobState={jobState}
              handleTextField={handleTextField}
              handleSelectField={handleSelectField}
              handleJobType={handleJobType}
              handleYearsExperience={handleYearsExperience}
              handleCareerLevel={handleCareerLevel}
              handleSalaryRange={handleSalaryRange}
              handleDraftField={handleDraftField}
              handleVacancyNumber={handleVacancyNumber}
              handleVacancyNumberChange={handleVacancyNumberChange}
              clientValidationErrors={clientValidationErrors}
            />
          )}
          {activeTab === 2 && (
            <AdminScreeningQuestions
              questionsState={questionsState}
              addNewQuestionHandler={addNewQuestionHandler}
              editQuestionHandler={editQuestionHandler}
              deleteQuestionHandler={deleteQuestionHandler}
              toggleIsActiveBtn={toggleIsActiveBtn}
            />
          )}
          {activeTab === 3 && <Preview jobState={jobState} questionsState={questionsState} />}

          <div className={classes.controlContainerBtn}>
            {activeTab > 1 ? (
              <button className={classes.backBtn} onClick={handleBackPage}>
                <img src={leftArrowIcon} />
              </button>
            ) : (
              <div></div>
            )}

            {activeTab < 3 ? (
              <button
                className={classes.proceedBtn}
                onClick={handleProceedPage}
              >
                <img src={whiteRightArrowIcon} />
              </button>
            ) : (
              <div></div>
            )}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EditJob;
