import CookieConsent from "react-cookie-consent";
import { useNavigate } from "react-router-dom";
import classes from "./CookiePopup.module.css";
import { useDispatch } from "react-redux";
import { hideCookieConsentAction } from "../../slices/popup-slice";

const CookiePopup = () => {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const handleNavigation = () => {
        navigate("/cookie-policy")
    }

    const cookieConsentAcceptHandler = () => {
        dispatch(hideCookieConsentAction())
    }

    return (
        <div>
            <CookieConsent cookieName="cookie-consent" onAccept={cookieConsentAcceptHandler}>This website uses cookies to enhance the user experience. <button onClick={handleNavigation} className={classes.learnMoreBtn} style={{ fontSize: "10px" }}>Learn More...</button></CookieConsent>
        </div>
    )
}

export default CookiePopup;