import { Typography } from "@mui/material";
import classes from "./QuestionModal.module.css";
import closeIcon from "../../../assets/images/close-icon.svg";
import freeTextIcon from "../../../assets/images/free-text-icon.svg";
import yesNoIcon from "../../../assets/images/yes-no-icon.svg";
import recordIcon from "../../../assets/images/record-icon.svg";
import bookmarkIcon from "../../../assets/images/bookmark-icon.svg";
import { questionTypes } from "../../../constants/questionTypes";
import { useState } from "react";
const questionTypesData = [
  { label: "Free text", value: questionTypes.TEXT, icon: freeTextIcon },
  { label: "Yes/No", value: questionTypes.YES_NO, icon: yesNoIcon },
  { label: "Record", value: questionTypes.RECORD, icon: recordIcon },
];

const QuestionModal = ({
  handleToggleQuestionModal,
  addNewQuestionHandler,
  editQuestionHandler,
  mode,
  question = {
    title: "",
    type: questionTypes.TEXT,
    whoAnsweredCount: 0,
    isActive: true
  },
  questionIndex
}) => {
  const [newQuestion, setNewQuestion] = useState(question);

  const handleTitleValue = (e) => {
    setNewQuestion((prevState) => ({
      ...prevState,
      title: e.target.value,
    }));
  };

  const handleTypeValue = (value) => {
    return () =>
      setNewQuestion((prevState) => ({
        ...prevState,
        type: value,
      }));
  };

  const handleSaveQuestion = () => {
    mode === "add" && addNewQuestionHandler(newQuestion);
    mode === "edit" && editQuestionHandler(newQuestion, questionIndex);
    handleToggleQuestionModal(null)();
  };

  const questionTypeClasses = (value) =>
    `${classes.tagContainer} ${
      newQuestion.type &&
      newQuestion.type === value &&
      classes.selectedTagContainer
    }`;

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <Typography variant="h3" className={classes.headerTitle}>
          {mode === "add" ? "New" : "Edit"} Question
        </Typography>
        <button
          onClick={handleToggleQuestionModal("close", null)}
          className={classes.closeBtn}
        >
          <img src={closeIcon} alt="close-icon" />
        </button>
      </div>

      <div>
        <Typography>Question type</Typography>

        <div className={classes.tagsContainer}>
          {questionTypesData.map((questionType, index) => (
            <div
              onClick={handleTypeValue(questionType.value)}
              key={`question-type-${index}`}
              className={questionTypeClasses(questionType.value)}
            >
              <Typography className={classes.tagLabel} variant="body1">
                {questionType.label}
              </Typography>
              <img src={questionType.icon} className={classes.addIcon} />
            </div>
          ))}
        </div>
      </div>

      <div className={classes.questionTitleContainer}>
        <Typography>Question</Typography>
        <input value={newQuestion.title} onChange={handleTitleValue} className={classes.textField} />
      </div>

      <div className={classes.saveBtnContainer}>
        <button onClick={handleSaveQuestion} className={classes.saveBtn}>
          Save <img className={classes.bookmarkIcon} src={bookmarkIcon} />
        </button>
      </div>
    </div>
  );
};

export default QuestionModal;
