import { useEffect, useState } from "react";
import { useReactMediaRecorder } from "react-media-recorder";

const initialState = {
  recordingMinutes: 0,
  recordingSeconds: 0,
  initRecording: false,
  isPaused: false,
  audio: null,
};

const useRecorder = (audio = null) => {
  const [recorderState, setRecorderState] = useState({...initialState, audio});
  const {
    status,
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    mediaBlobUrl,
    clearBlobUrl,
  } = useReactMediaRecorder({
    video: false,
    audio: true,
    mediaRecorderOptions: {  mimeType: "audio/wav" },
  });


  useEffect(() => {
    if(mediaBlobUrl){
        setRecorderState((prevState) => ({
            ...prevState,
            audio: mediaBlobUrl
        }))
    }
  },[mediaBlobUrl])

  useEffect(() => {
    const MAX_RECORDER_TIME = 2;
    let recordingInterval = null;

    if (status === "recording" && status !== "paused")
      recordingInterval = setInterval(() => {
        setRecorderState((prevState) => {
          if (
            prevState.recordingMinutes === MAX_RECORDER_TIME &&
            prevState.recordingSeconds === 0
          ) {
            clearInterval(recordingInterval);
            saveRecording(); // if we reached max duration then auto save the audio
            return prevState;
          }

          if (
            prevState.recordingSeconds >= 0 &&
            prevState.recordingSeconds < 59
          )
            return {
              ...prevState,
              recordingSeconds: prevState.recordingSeconds + 1,
            };

          if (prevState.recordingSeconds === 59)
            return {
              ...prevState,
              recordingMinutes: prevState.recordingMinutes + 1,
              recordingSeconds: 0,
            };
        });
      }, 1000);
    else clearInterval(recordingInterval);

    return () => clearInterval(recordingInterval);
  });

  const startRecordingHandler = async () => {
    startRecording();
    setRecorderState((prevState) => ({
      ...prevState,
      initRecording: true,
    }));
  };

  const saveRecording = () => {
    stopRecording();
  };

  const cancelRecording = () => {
    setRecorderState(initialState);
    clearBlobUrl();
  };

  const pauseRecordingHandler = () => {
    setRecorderState((prevState) => ({
        ...prevState,
        isPaused: true
      }));
      pauseRecording();
  }

  const resumeRecordingHandler = () => {
    setRecorderState((prevState) => ({
        ...prevState,
        isPaused: false
      }));
      resumeRecording();
  }

  return {
    recorderState,
    startRecording: () => startRecordingHandler(), // Done
    cancelRecording: () => cancelRecording(), // Done
    saveRecording: () => saveRecording(), // Done
    deleteAudio: () => cancelRecording(), // Done
    pauseRecording: () => pauseRecordingHandler(), // Done
    resumeRecording: () => resumeRecordingHandler(), // Done
  };
};

export default useRecorder;
