import { useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoggedIn from "../LoggedIn";
import classes from "./RightSide.module.css";

const NavbarRightSide = () => {
  const authUser = useSelector((state) => state.authReducer);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const handleNotLoggedInLink = (link) => (e) => {
    navigate(link);
  };

  return (
    <div className={classes.container}>
      {authUser.loggedIn && <LoggedIn />}
      {!authUser.loggedIn && (<div className={classes.emptyLoggedInSection}>

      </div>)}
      {!authUser.loggedIn && !isMobile && (
        <div className={classes.notLoggedInContainer}>
          <p onClick={handleNotLoggedInLink("/login")}>Login</p>
          <p onClick={handleNotLoggedInLink("/register")}>Register</p>
        </div>
      )}
    </div>
  );
};

export default NavbarRightSide;
