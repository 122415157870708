import { Avatar, Typography } from "@mui/material";
import moment from "moment";

import classes from "./CandidateCard.module.css";
import demoPic from "../../../../assets/images/demo.jfif";
import { Link } from "react-router-dom";

const CandidateCard = ({application}) => {

    const stringAvatar = `${application.firstname[0]}${application.lastname[0]}`

    return(
        <div
        id={application._id}
        className={classes.applicationContainer}
      >
        <div className={classes.applicationInfoContainer}>
        <Avatar src="" alt="Profile Pic" sx={{height: 64, width: 64, fontSize: "25px"}}>
            {stringAvatar}
            </Avatar>
        <div className={classes.applicationDetailsContainer}>
          <Typography variant="h3" className={classes.name}>
            {`${application.firstname} ${application.lastname}`}
          </Typography>
          <Typography variant="body1" className={classes.location}>
            {`${application.country}, ${application.city}`}
          </Typography>
          <Typography
            variant="body1"
            className={classes.yearsExperience}
          >
            {application.yearsExperience} year experience - Min salary{" "}
            {application.salary}$
          </Typography>
          <Typography variant="body1" className={classes.applyDate}>
          Applied {moment.utc(application.createdAt).local().startOf("seconds").fromNow()}
          </Typography>
          {
            /*
          <div
            className={`${classes.matchContainer} ${classes.lowMatchContainer}`}
          >
            Low match
          </div>
*/}
        </div>
        </div>
        <div className={classes.viewCandidateBtnContainer}>
            <Link className={classes.navigateBtn} to={`/admin/job/view-candidate/${application._id}`}>
              <button className={classes.viewCandidateBtn}>
                  View Candidate
              </button>
            </Link>
            </div>
      </div>
    )
}

export default CandidateCard;