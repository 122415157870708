import { Typography } from "@mui/material";
import { useRef } from "react";

import useCountriesCities from "../../hooks/useCountriesCities";
import ClientValidatorErrorDisplayer from "../ClientValidatorErrorDisplayer";
import classes from "./CandidateInfo.module.css";

const CandidateInfo = ({
  applicationForm,
  handleCVUpload,
  handleApplicationForm,
  handleCountry,
  handleCity,
  clientValidationErrors
}) => {
  const fileInput = useRef(null);
  const { countries, cities, getAllCities } = useCountriesCities(applicationForm.country);

  const handleFileFieldClick = () => {
    fileInput.current.click();
  };

  const handleCountryField = (e) => {
    const countryName = e.currentTarget.value;

    handleCountry(countryName);
    getAllCities(countryName);
  };

  return (
    <div className={classes.container}>
      <div className={`${classes.fullWidthField}`}>
        <Typography variant="body1" className={classes.fieldLabel}>
          Your resume
          <span className={classes.requiredAsterisk}>*</span>
        </Typography>
        <span className={classes.cvHelperText}>Max size for file is 2MB and only {"(.doc, docx, pdf, txt)"} are supported!</span>
        <div className={classes.fileField} onClick={handleFileFieldClick}>
          <input
            ref={fileInput}
            type="file"
            onChange={handleCVUpload}
            accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, .txt"
          />
          <Typography className={classes.fileName}>
            {applicationForm.cv && applicationForm.cv.name}
          </Typography>
          <div className={classes.browseFileContainer}>
            <Typography className={classes.browseFileText}>
              Browse File
            </Typography>
          </div>
        </div>

        <ClientValidatorErrorDisplayer fieldErrors={clientValidationErrors?.cv} />
      </div>

      <div className={`${classes.halfWidthField} ${classes.leftField}`}>
        <Typography variant="body1" className={classes.fieldLabel}>
          First name
          <span className={classes.requiredAsterisk}>*</span>
        </Typography>
        <input
          value={applicationForm.firstname}
          className={classes.textField}
          onChange={handleApplicationForm("firstname")}
        />
        <ClientValidatorErrorDisplayer fieldErrors={clientValidationErrors?.firstname} />
      </div>

      <div className={classes.halfWidthField}>
        <Typography variant="body1" className={classes.fieldLabel}>
          Last name
          <span className={classes.requiredAsterisk}>*</span>
        </Typography>
        <input
          value={applicationForm.lastname}
          className={classes.textField}
          onChange={handleApplicationForm("lastname")}
        />
                <ClientValidatorErrorDisplayer fieldErrors={clientValidationErrors?.lastname} />
      </div>

      <div className={`${classes.fullWidthField}`}>
        <Typography variant="body1" className={classes.fieldLabel}>
          Your email
          <span className={classes.requiredAsterisk}>*</span>
        </Typography>
        <input
          value={applicationForm.email}
          onChange={handleApplicationForm("email")}
          type="email"
          className={classes.textField}
        />
                <ClientValidatorErrorDisplayer fieldErrors={clientValidationErrors?.email} />
      </div>
      
      <div className={`${classes.fullWidthField}`}>
        <Typography variant="body1" className={classes.fieldLabel}>
          Your Github Link
        </Typography>
        <input
          value={applicationForm.github}
          onChange={handleApplicationForm("github")}
          type="text"
          className={classes.textField}
        />
                <ClientValidatorErrorDisplayer fieldErrors={clientValidationErrors?.github} />
      </div>

      <div className={`${classes.fullWidthField}`}>
        <Typography variant="body1" className={classes.fieldLabel}>
          Your LinkedIn Link
        </Typography>
        <input
          value={applicationForm.linkedIn}
          onChange={handleApplicationForm("linkedIn")}
          type="text"
          className={classes.textField}
        />
                <ClientValidatorErrorDisplayer fieldErrors={clientValidationErrors?.linkedIn} />
      </div>

      <div className={`${classes.fullWidthField}`}>
        <Typography variant="body1" className={classes.fieldLabel}>
          Your Wuzzuf Link {`(if you have one)`}
        </Typography>
        <input
          value={applicationForm.wuzzuf}
          onChange={handleApplicationForm("wuzzuf")}
          type="text"
          className={classes.textField}
        />
                <ClientValidatorErrorDisplayer fieldErrors={clientValidationErrors?.wuzzuf} />
      </div>

      <div className={`${classes.halfWidthField} ${classes.leftField}`}>
        <Typography variant="body1" className={classes.fieldLabel}>
          Country
        </Typography>
        <select
          value={applicationForm.country}
          placeholder="Search for a country"
          className={classes.selectField}
          onChange={handleCountryField}
        >
          {
            countries.length > 0 && countries.map((country) => (
              <option key={country.iso3} value={country.name}>{country.name}</option>
            ))
          }
                    {
            countries.length <= 0 && "Loading..."
          }
        </select>
        <ClientValidatorErrorDisplayer fieldErrors={clientValidationErrors?.country} />
      </div>

      <div className={classes.halfWidthField}>
        <Typography variant="body1" className={classes.fieldLabel}>
          City
        </Typography>
        <select
          value={applicationForm.city}
          disabled={cities.length <= 0 ? true : false}
          onChange={handleCity}
          placeholder="Search for a city"
          className={classes.selectField}
        >
          {cities.length > 0 &&
            cities.map((city) => (
              <option key={city.id} value={city.name}>
                {city.name}
              </option>
            ))}
          {cities.length <= 0 && "Loading..."}
        </select>
        <ClientValidatorErrorDisplayer fieldErrors={clientValidationErrors?.city} />
      </div>

      <div className={`${classes.halfWidthField}`}>
        <Typography variant="body1" className={classes.fieldLabel}>
          Years of experience
        </Typography>
        <div className={classes.yearsExperienceField}>
          <input
            value={applicationForm.yearsExperience}
            onChange={handleApplicationForm("yearsExperience", "number")}
            className={classes.textField}
          />
        </div>
        <ClientValidatorErrorDisplayer fieldErrors={clientValidationErrors?.yearsExperience} />
      </div>

      <div className={`${classes.fullWidthField}`}>
        <Typography variant="body1" className={classes.fieldLabel}>
          Your Expected Salary
          <span className={classes.requiredAsterisk}>*</span>
        </Typography>
        <div className={classes.salaryField}>
          <input
            value={applicationForm.salary}
            onChange={handleApplicationForm("salary", "number")}
            className={classes.textField}
          />
          <Typography>$/Per Month</Typography>
        </div>
        <ClientValidatorErrorDisplayer fieldErrors={clientValidationErrors?.salary} />
      </div>
    </div>
  );
};

export default CandidateInfo;
