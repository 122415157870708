import AuthRoles from "../AuthRoles";
import Auth from "../Auth";

const AuthRolesWrapper = ({children, allowedRoles = []}) => {

  return (
    <Auth>
        <AuthRoles allowedRoles={allowedRoles}>
            {children}
        </AuthRoles>
    </Auth>
  );
};

export default AuthRolesWrapper;
