import DeleteIcon from "@mui/icons-material/Delete";
import { Grid } from "@mui/material";
import CustomAudioPlayer from "../CustomAudioPlayer";
import classes from "./Recording.module.css";

const Recording = ({ audio, handlers }) => {
  const { deleteAudio } = handlers;
  return (
    <div className="recordings-container">
      {audio && (
        <>
          <div className="recordings-list">
            <div className={classes.record}>
            <CustomAudioPlayer src={audio} />
              <div className="delete-button-container">
                <div
                  title="Delete this audio"
                  onClick={deleteAudio}
                  className={classes.deleteIconContainer}
                >
                  <DeleteIcon  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Recording;
