import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileAPI } from "../services";
import { updateProfileAction } from "../slices/auth-slice";
import useCountriesCities from "./useCountriesCities";
import useFlashMsg from "./useFlashMsg";

const useProfile = () => {

  const dispatch = useDispatch()
  const authUser = useSelector((state) => state.authReducer);
  const { handleErrorFlash } = useFlashMsg()

  const [updateFlag, setUpdateFlag] = useState(false);
  const [profileForm, setProfileForm] = useState({
    firstname: "",
    lastname: "",
    email: "",
    country: "",
    city: "",
  })
  const { getAllCities } = useCountriesCities(profileForm.country)

  useEffect(() => {
    const {firstname, lastname, email, country, city} = authUser.user;

    if(country) {
      getAllCities(country)
    }
    setProfileForm({firstname, lastname, email, country, city})
  }, [])

  const undoUpdate = (newUser = null) => {
    const {firstname, lastname, email, country, city} = newUser ? newUser : authUser.user;
    setProfileForm({firstname, lastname, email, country, city})
    setUpdateFlag(false);
  }

  const handleUpdateProfile = async () => {
    try {
      const res = await updateProfileAPI(profileForm);

      const newUser = { ...authUser.user, ...profileForm }
      dispatch(updateProfileAction({ ...authUser.user, ...profileForm }));
      undoUpdate(newUser);
    } catch (err) {
      console.log(err);
      const errMsg = err.response.data.err?.msg || err.message;

      if (err.response.data.err) console.log(err.response.data.err);

      handleErrorFlash("error occurred while updating profile");
    }
  }

  const checkFormChange = useCallback((profileData) => {
    for(const key in profileData) {
      if(profileData[key] !== authUser.user[key]) {
        console.log(profileData[key])
        console.log(authUser.user[key])
        setUpdateFlag(true);
        return
      }
    }
    setUpdateFlag(false);
  }, [authUser.user])

  const handleFieldChange = (e, fieldName) => {
    setProfileForm((prevState) => ({...prevState, [fieldName]: e.target.value}))
    if(fieldName === "country") {
      getAllCities(e.target.value)
    }

    checkFormChange({...profileForm, [fieldName]: e.target.value});
  }

  
      return {
        profileForm,
        updateFlag,
        handleFieldChange: fieldName => (e) => handleFieldChange(e, fieldName),
        handleUpdateProfile: () => handleUpdateProfile(),
        undoUpdate: () => undoUpdate()
      }
}

export default useProfile;