import { Typography } from "@mui/material";
import { questionTypes } from "../../constants/questionTypes";
import freeTextIcon from "../../assets/images/free-text-icon.svg";
import yesNoIcon from "../../assets/images/yes-no-icon.svg";
import recordIcon from "../../assets/images/record-icon.svg";
import classes from "./ViewCandidateApplication.module.css";
import CustomAudioPlayer from "../CustomAudioPlayer";
import env from "../../env";

const getQuestionTypeIcon = (type) => {
  switch (type) {
    case questionTypes.TEXT:
      return freeTextIcon;
    case questionTypes.YES_NO:
      return yesNoIcon;
    case questionTypes.RECORD:
      return recordIcon;
  }

  return freeTextIcon;
};

const ViewCandidateApplication = ({ application }) => {
  const { screeningQuestions } = application;

  return (
    <div className={classes.container}>
      <div className={classes.applicationDetails}>
        <Typography variant="h3">Candidate Information</Typography>
        <div className={classes.applicationDetailsData}>
          <Typography
            className={classes.applicationDetailData}
            variant="h3"
            fontWeight={400}
          >
            First name:{" "}
            <span className={classes.applicationDetailValue}>
              {application.firstname}
            </span>
          </Typography>
          <Typography
            className={classes.applicationDetailData}
            variant="h3"
            fontWeight={400}
          >
            Last name:{" "}
            <span className={classes.applicationDetailValue}>
              {application.lastname}
            </span>
          </Typography>
          <Typography
            className={classes.applicationDetailData}
            variant="h3"
            fontWeight={400}
          >
            Email:{" "}
            <span className={classes.applicationDetailValue}>
              {application.email}
            </span>
          </Typography>
          <Typography
            className={classes.applicationDetailData}
            variant="h3"
            fontWeight={400}
          >
            Github:{" "}
            <span className={classes.applicationDetailValue}>
              {application.github}
            </span>
          </Typography>
          <Typography
            className={classes.applicationDetailData}
            variant="h3"
            fontWeight={400}
          >
            LinkedIn:{" "}
            <span className={classes.applicationDetailValue}>
              {application.linkedIn}
            </span>
          </Typography>
          <Typography
            className={classes.applicationDetailData}
            variant="h3"
            fontWeight={400}
          >
            Wuzzuf:{" "}
            <span className={classes.applicationDetailValue}>
              {application.wuzzuf}
            </span>
          </Typography>
          <Typography
            className={classes.applicationDetailData}
            variant="h3"
            fontWeight={400}
          >
            Country:{" "}
            <span className={classes.applicationDetailValue}>
              {application.country}
            </span>
          </Typography>
          <Typography
            className={classes.applicationDetailData}
            variant="h3"
            fontWeight={400}
          >
            City:{" "}
            <span className={classes.applicationDetailValue}>
              {application.city}
            </span>
          </Typography>
          <Typography
            className={classes.applicationDetailData}
            variant="h3"
            fontWeight={400}
          >
            Years of experience:{" "}
            <span className={classes.applicationDetailValue}>
              {application.yearsExperience}
            </span>
          </Typography>
          <Typography
            className={classes.applicationDetailData}
            variant="h3"
            fontWeight={400}
          >
            Expected Salary:{" "}
            <span className={classes.applicationDetailValue}>
              {application.salary}$
            </span>
          </Typography>
        </div>
      </div>

      <div className={classes.screeningQuestions}>
        <Typography variant="h3">Screening Questions</Typography>
        <div className={classes.screeningQuestionsContainer}>
          {screeningQuestions.map((screeningQuestion, index) => (
            <div key={index} className={classes.screeningQuestion}>
              <div className={classes.question}>
                <img src={getQuestionTypeIcon(screeningQuestion.answer.type)} />
                <Typography>{screeningQuestion.title}</Typography>
              </div>

              <div className={classes.questionAnswer}>
                {screeningQuestion.answer.type !== "record" && (
                  <Typography>{screeningQuestion.answer.answer}</Typography>
                )}
                {screeningQuestion.answer.type === "record" &&
                  process.env.REACT_APP_REACT_ENV === "production" && (
<CustomAudioPlayer
                      src={`${env.API_URL}/get-file/records/${screeningQuestion.answer.answer}`}
                    />
                  )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ViewCandidateApplication;
