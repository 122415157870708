import classes from "./APIValidatorErrorDisplayer.module.css"

const APIValidatorErrorDisplayer = ({displayAPIValidation}) => {

    return (
        <div className={classes.container}>
            {
                displayAPIValidation &&
                Object.entries(displayAPIValidation).map(([fieldName, fieldErrors], index) => (
                    <div key={`error-${index}`} className={classes.fieldErrorContainer}>
                        {
                            fieldErrors.length > 0 && fieldName !== "questions" && fieldErrors.map(error => (
                                <span className={classes.errorMsg}>* {error}</span>
                            ))
                        }
                    </div>
                ))
            }
        </div>
    )
}

export default APIValidatorErrorDisplayer