import { Grid, Typography } from "@mui/material";
import titleFrame from "../../assets/images/title-frame.svg";
import specialInPrice from "../../assets/images/special-in-price.png";
import classes from "./SpecialInPrice.module.css";

const SpecialInPrice = ({style}) => {
  return (
    <Grid container xs={12} className={classes.container} style={style}>
      <Grid container lg={12} xl={6} className={classes.leftSideContainer}>
        <Grid container alignItems="center" className={classes.headerContainer}>
          <img
            className={classes.headerIcon}
            src={titleFrame}
            alt="title-frame"
          />
          <Typography
            variant="h2"
            fontWeight={700}
            className={classes.headerTitle}
          >
            what does make us special in pricing?
          </Typography>
        </Grid>

        <ol>
          <li className={classes.list}>
            One invoice:{" "}
            <span className={classes.listSpan}>
              our price includes taxes, insurances, accountancy issues.
            </span>
          </li>
          <li className={classes.list}>
            Transparent pricing:{" "}
            <span className={classes.listSpan}>
              our prices are lower than everyone else.
            </span>
          </li>
          <li className={classes.list} style={{ margin: 0 }}>
            Happiness first:{" "}
            <span className={classes.listSpan}>
              you only pay when happy with your developer.
            </span>
          </li>
        </ol>
      </Grid>

      <Grid
        container
        lg={12}
        xl={6}
        justifyContent="flex-end"
        alignItems="flex-end"
        className={classes.imgContainer}
      >
        <img
          className={classes.img}
          src={specialInPrice}
          alt="special-in-price"
        />
      </Grid>
    </Grid>
  );
};

export default SpecialInPrice;
