import classes from "./ScreeningQuestions.module.css";
import addIcon from "../../../assets/images/add-icon.svg";
import freeTextIcon from "../../../assets/images/free-text-icon.svg";
import yesNoIcon from "../../../assets/images/yes-no-icon.svg";
import recordIcon from "../../../assets/images/record-icon.svg";
import displayIcon from "../../../assets/images/display-icon.svg";
import dontDisplayIcon from "../../../assets/images/dont-display-icon.svg";
import editIcon from "../../../assets/images/edit-icon.svg";
import deleteIcon from "../../../assets/images/delete-icon.svg";
import { Typography } from "@mui/material";
import { questionTypes } from "../../../constants/questionTypes";
import QuestionModal from "../QuestionModal";
import { useState } from "react";

const baseQuestions = [
  {
    type: "text",
    title: "Why do you want to join our company?",
    display: true,
  },
  {
    type: "text",
    title: "What makes you the ideal candidate for this position?",
    display: false,
  },
  {
    type: "text",
    title:
      "Tell us about yourself. What makes you unique from other candidates?",
    display: true,
  },
  { type: "yes-no", title: "Do you have your own laptop?", display: true },
  {
    type: "record",
    title: "Please introduce yourself in English",
    display: true,
  },
];

const additionalQuestions = [
  {
    type: "text",
    title: "Why do you want to join our company?",
  },
  {
    type: "text",
    title: "Why do you want to join our company?",
  },
];

const getQuestionTypeIcon = (type) => {
  switch (type) {
    case questionTypes.TEXT:
      return freeTextIcon;
    case questionTypes.YES_NO:
      return yesNoIcon;
    case questionTypes.RECORD:
      return recordIcon;
  }

  return freeTextIcon;
};

const ScreeningQuestions = ({
  questionsState,
  addNewQuestionHandler,
  editQuestionHandler,
  deleteQuestionHandler,
  toggleIsActiveBtn,
}) => {
  const [openNewQuestionModal, setOpenNewQuestionModal] = useState({
    open: false,
    mode: null,
    questionIndex: null,
  });

  const handleToggleQuestionModal = (type, mode, questionIndex = null) => {
    return () =>
      setOpenNewQuestionModal(
        type === "open"
          ? { open: true, mode, questionIndex }
          : { open: false, mode: null, questionIndex: null }
      );
  };

  return (
    <div className={classes.container}>
      {openNewQuestionModal.open && (
        <QuestionModal
          handleToggleQuestionModal={handleToggleQuestionModal}
          addNewQuestionHandler={addNewQuestionHandler}
          editQuestionHandler={editQuestionHandler}
          mode={openNewQuestionModal.mode}
          question={
            openNewQuestionModal.questionIndex !== null
              ? questionsState[openNewQuestionModal.questionIndex]
              : undefined
          }
          questionIndex={openNewQuestionModal.questionIndex}
        />
      )}

      <div className={classes.headerContainer}>
        <div className={classes.questionFiltersContainer}>
          <Typography variant="body1" className={classes.questionFilter}>
            All {`(5)`}
          </Typography>
          <Typography variant="body1" className={classes.questionFilter}>
            <img src={freeTextIcon} /> Free text {`(5)`}
          </Typography>
          <Typography variant="body1" className={classes.questionFilter}>
            <img src={yesNoIcon} /> Yes/No {`(1)`}
          </Typography>
          <Typography variant="body1" className={classes.questionFilter}>
            <img src={recordIcon} /> Record {`(1)`}
          </Typography>
        </div>
        <button
          onClick={handleToggleQuestionModal("open", "add")}
          className={classes.newQuestionBtn}
        >
          New question <img className={classes.addIcon} src={addIcon} />
        </button>
      </div>

      <Typography className={classes.sectionTitle}>Base Questions</Typography>

      <div className={classes.questionsContainer}>
        {/*baseQuestions.map((question) => (
          <div className={classes.questionContainer}>
            <div className={classes.leftQuestionSection}>
              <img src={getQuestionTypeIcon(question.type)} />
              <Typography>{question.title}</Typography>
            </div>
            <img
              className={classes.displayIcon}
              src={question.display ? displayIcon : dontDisplayIcon}
            />
          </div>
        ))*/}
        <Typography>Feature not available yet.</Typography>
      </div>

      <Typography className={classes.sectionTitle}>
        Additional Questions
      </Typography>

      <div className={classes.questionsContainer}>
        {questionsState.length === 0 && (
          <Typography>Questions not added yet.</Typography>
        )}
        {questionsState.map((question, index) => (
          <div
            key={`question-${index}`}
            className={`${classes.questionContainer} ${
              !question.isActive ? classes.inactiveQuestion : ""
            }`}
          >
            <div className={classes.leftQuestionSection}>
              <img src={getQuestionTypeIcon(question.type)} />
              <div>
                <Typography>{question.title}</Typography>
                {question.whoAnsweredCount > 0 && (
                  <Typography className={classes.whoAnsweredCount}>
                    {question.whoAnsweredCount} Answers Submitted
                  </Typography>
                )}
              </div>
            </div>
            <div className={classes.controlBtnsContainer}>
              <img
                onClick={handleToggleQuestionModal("open", "edit", index)}
                className={classes.editIcon}
                src={editIcon}
                alt="edit-icon"
              />
              {question?.whoAnsweredCount === 0 && (
                <img
                  onClick={deleteQuestionHandler(index)}
                  className={classes.deleteIcon}
                  src={deleteIcon}
                  alt="delete-icon"
                />
              )}


                <>
                  {question?.isActive ? (
                    <img
                      onClick={toggleIsActiveBtn(index)}
                      className={classes.displayIcon}
                      src={displayIcon}
                      alt="delete-icon"
                    />
                  ) : (
                    <img
                      onClick={toggleIsActiveBtn(index)}
                      className={classes.displayIcon}
                      src={dontDisplayIcon}
                      alt="delete-icon"
                    />
                  )}
                </>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScreeningQuestions;
