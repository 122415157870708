import { useCallback, useEffect, useState } from "react";
import { getCitiesAPI, getCountriesAPI } from "../services";
import useFlashMsg from "./useFlashMsg";

const useCountriesCities = (countryName = null) => {
const { handleErrorFlash } = useFlashMsg()
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);

  const getAllCountries = useCallback(async () => {
    try {
      const countriesResult = await getCountriesAPI();
      setCountries(countriesResult.data);
    } catch (err) {
      console.log(err);
      const errMsg = err.response.data.err?.msg || err.message;

      if (err.response.data.err) console.log(err.response.data.err);

      handleErrorFlash("error occurred while fetching countries");
    }
  }, []);

  useEffect(() => {
    getAllCountries();
  }, [getAllCountries]);

  const getAllCities = useCallback(
    async (countryName) => {
      try {
        const citiesResult = await getCitiesAPI(countryName);

        setCities(citiesResult.data);
      } catch (err) {
        console.log(err);
        const errMsg = err.response.data.err?.msg || err.message;

        if (err.response.data.err) console.log(err.response.data.err);

        handleErrorFlash("error occurred while fetching cities");
      }
    },
    []
  );

  useEffect(() => {
    if(countryName) {
        getAllCities(countryName);
    }
  }, [countryName])

  return {
    countries,
    cities,
    getAllCities: (countryName) => getAllCities(countryName),
  };
};

export default useCountriesCities;
