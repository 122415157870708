import { Grid, Typography } from "@mui/material";
import { useState, useEffect, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";

import classes from "./JobsList.module.css";
import searchIcon from "../../../../assets/images/search-icon.svg";
import addIcon from "../../../../assets/images/add-icon.svg";
import titleFrame from "../../../../assets/images/title-frame.svg";
import JobPost from "../../../../components/Admin/JobPost";
import { getAdminJobsListAPI } from "../../../../services";
import useFlashMsg from "../../../../hooks/useFlashMsg";

const JobsList = () => {
  const [jobsList, setJobsList] = useState([]);
  const [activeJobsToggle, setActiveJobsToggle] = useState(true);
  const { handleErrorFlash } = useFlashMsg();

  const fetchAllJobs = useCallback(async () => {
    try {
      const adminJobsResult = await getAdminJobsListAPI();

      const adminsJobs = adminJobsResult.data.data.data;
      setJobsList(adminsJobs);
    } catch (err) {
      console.log(err);
      const errMsg = err.response.data.err?.msg || err.message;

      if (err.response.data.err) console.log(err.response.data.err);

      handleErrorFlash("error occurred while fetching job list");
    }
  }, []);

  console.log(jobsList)

  useEffect(() => {
    fetchAllJobs();
  }, [fetchAllJobs]);

  const handleActivejobsToggle = (value) => () => {
    if (value !== activeJobsToggle) setActiveJobsToggle(value);
  };

  const activeJobs = useMemo(
    () => jobsList.filter((job) => job.isActive),
    [jobsList]
  );
  const inActiveJobs = useMemo(
    () => jobsList.filter((job) => !job.isActive),
    [jobsList]
  );

  const updateJobToggle = (jobId) => {
    setJobsList(prevState => prevState.map(job => {
        if(job._id !== jobId) return job;
        return {
          ...job,
          isActive: !job.isActive
        }
      })
    )
  }

  return (
    <Grid container justifyContent="center" className={classes.page}>
      <Grid className={classes.container}>
        <Grid container columnGap="40px" className={classes.headerContainer}>
          <div className={classes.searchInputContainer}>
            <img
              src={searchIcon}
              className={classes.searchIcon}
              alt="search-icon"
            />
            <input
              type="text"
              placeholder="Search by job title"
              className={classes.searchInput}
            />
          </div>
          <Link className={classes.linkAddBtn} to="/admin/add-job">
            <button className={classes.addBtn}>
              Add new job
              <img src={addIcon} className={classes.addIcon} alt="add-icon" />
            </button>
          </Link>
        </Grid>

        <Grid
          container
          justifyContent="center"
          className={classes.tabsContainer}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.tabContainer}
            onClick={handleActivejobsToggle(true)}
          >
            <img
              className={classes.tabIcon}
              src={titleFrame}
              alt="title-frame"
            />
            <Typography
              variant="h3"
              fontWeight={700}
              className={`${classes.tabTitle} ${
                activeJobsToggle && classes.activeTabTitle
              }`}
            >
              Active Jobs {`(${activeJobs.length})`}
            </Typography>
          </Grid>

          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.tabContainer}
            onClick={handleActivejobsToggle(false)}
          >
            <img
              className={classes.tabIcon}
              src={titleFrame}
              alt="title-frame"
            />
            <Typography
              variant="h3"
              className={`${classes.tabTitle} ${
                !activeJobsToggle && classes.activeTabTitle
              }`}
            >
              Closed Jobs {`(${inActiveJobs.length})`}
            </Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.jobsContainer} rowGap="32px">
          {activeJobsToggle &&
            activeJobs.map((job) => (
              <JobPost key={`admin-jobs-${job._id}`} job={job} updateJobToggle={updateJobToggle} />
            ))}
          {!activeJobsToggle &&
            inActiveJobs.map((job) => (
              <JobPost key={`admin-jobs-${job._id}`} job={job} updateJobToggle={updateJobToggle} />
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default JobsList;
