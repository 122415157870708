import { useState } from "react";
import { ContactUsFormValidator } from "../components/ContactForm/validator";
import { createContactUsFormAPI } from "../services";
import useFlashMsg from "./useFlashMsg";

const useContactForm = () => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [clientValidationErrors, setClientValidationErrors] = useState(null)
    const { handleSuccessFlash, handleErrorFlash } = useFlashMsg()
  
    const handleFieldChange = (e, fieldName) => {
      switch (fieldName) {
        case "name":
          setName(e.target.value);
          break;
        case "email":
          setEmail(e.target.value);
          break;
        case "title":
          if(e.target.value.length <= 60) setTitle(e.target.value);
          break;
        case "message":
          setMessage(e.target.value);
          break;
        default:
          return;
      }
    };

    const handleFormSubmit = async () => {

      const form = {name, email, title, message}
      let validator = new ContactUsFormValidator(form);
  
      // if validation caught display error
      if (validator?.foundError) {
        setClientValidationErrors(validator.errors);
        return;
      }
        try {
            const result = await createContactUsFormAPI(form)

            setName("")
            setEmail("")
            setTitle("")
            setMessage("")

            handleSuccessFlash(result.data.data.msg)
            setClientValidationErrors(null);
        } catch (err) {
            console.log(err);
            const errMsg = err.response.data.err?.msg || err.message;
      
            if (err.response.data.err) console.log(err.response.data.err);
      
            handleErrorFlash("error occurred while submitting form");
            setClientValidationErrors(null);
        }
    }

    return {
        contactFormState: {name, email, title, message},
        clientValidationErrors,
        handleFieldChange: (field) => (e) => handleFieldChange(e, field),
        handleFormSubmit: () => handleFormSubmit(),
    }
}

export default useContactForm