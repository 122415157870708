import { Avatar, Modal, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

import profilePic from "../../../../assets/images/demo.jfif";
import classes from "./ViewCandidate.module.css";
import videoIcon from "../../../../assets/images/video-icon.svg";
import addIcon from "../../../../assets/images/add-icon-green.svg";
import titleFrame from "../../../../assets/images/title-frame.svg";
import CandidateDetails from "../../../../components/Admin/CandidateDetails";
import CandidateSQAnwers from "../../../../components/Admin/CandidateSQAnswers";
import CandidateResume from "../../../../components/Admin/CandidateResume";
import { useState, useCallback, useEffect } from "react";
import ScheduleInterviewModal from "../../../../components/Admin/ScheduleInterviewModal";
import InvToAnotherJobModal from "../../../../components/Admin/InvToAnotherJobModal";
import useFlashMsg from "../../../../hooks/useFlashMsg";
import {
  getCandidateApplicationAPI,
  moveApplicationToAPI,
} from "../../../../services";
import { moveToTypes } from "../../../../constants/moveToTypes";
import moment from "moment";

const ViewCandidate = () => {
  const { applicationId } = useParams();
  const { handleErrorFlash, handleSuccessFlash } = useFlashMsg();
  const [application, setApplication] = useState(null);
  const [activeTab, setActiveTab] = useState("details");
  const [openModal, setOpenModal] = useState({ open: false, type: null });
  const [activeStage, setActiveStage] = useState(null);

  const getApplicationFromAPI = useCallback(async () => {
    try {
      const resultData = await getCandidateApplicationAPI(applicationId);
      const returnedApplication = resultData.data.data.data;

      setApplication(returnedApplication);
      setActiveStage(returnedApplication?.moveTo);
    } catch (err) {
      console.log(err);
      const errMsg = err.response.data.err?.msg || err.message;

      if (err.response.data.err) console.log(err.response.data.err);

      handleErrorFlash("error occurred while fetching candidate application");
    }
  }, []);

  useEffect(() => {
    getApplicationFromAPI();
  }, [getApplicationFromAPI]);

  const handleActiveTab = (tabName) => {
    return () => setActiveTab(tabName);
  };

  const checkActiveTab = useCallback(
    (tabName) => (tabName === activeTab ? true : false),
    [activeTab]
  );

  const handleOpenModal = (selectedModalType) => {
    return () => setOpenModal({ open: true, type: selectedModalType });
  };

  const handleCloseModal = () => {
    setOpenModal({ open: false, type: null });
  };

  const handleActiveStage = (newStage) => async () => {
    try {
      const resultData = await moveApplicationToAPI(application?._id, newStage);

      setApplication(prevState => ({
        ...prevState,
        moveTo: newStage
      }))

      handleSuccessFlash(`Application has been Moved to ${newStage} Successfully!`)
    } catch (err) {
      console.log(err);
      const errMsg = err.response.data.err?.msg || err.message;

      if (err.response.data.err) console.log(err.response.data.err);

      handleErrorFlash("error occurred while moving candidate's application");
    }

    setActiveStage(newStage);
  };

  const activeStageStyle = useCallback(
    (currStage) =>
      activeStage === currStage ? classes.activeStageContainer : "",
    [activeStage]
  );

  const updateTestdome = (testdome) => {
    setApplication(prevState => ({
      ...prevState,
      testdome
    }))
  }

  
  const stringAvatar = `${application?.firstname[0]}${application?.lastname[0]}`

  return (
    <>
      <Modal open={openModal.open}>
        <>
          {openModal.type === "schedule-interview" && (
            <ScheduleInterviewModal handleCloseModal={handleCloseModal} />
          )}
          {openModal.type === "inv-to-another-job" && (
            <InvToAnotherJobModal handleCloseModal={handleCloseModal} />
          )}
        </>
      </Modal>
      <div className={classes.mainContainer}>
        <div className={classes.container}>
          <div className={classes.stagesContainer}>
            <div
              className={`${classes.stageContainer} ${activeStageStyle(
                moveToTypes.INBOX
              )}`}
              onClick={handleActiveStage(moveToTypes.INBOX)}
            >
              <Typography className={classes.textTitle}>
                Applications
              </Typography>
            </div>
            <div
              className={`${classes.stageContainer} ${activeStageStyle(
                moveToTypes.OK
              )}`}
              onClick={handleActiveStage(moveToTypes.OK)}
            >
              <Typography className={classes.textTitle}>Ok</Typography>
            </div>
            <div
              className={`${classes.stageContainer} ${activeStageStyle(
                moveToTypes.SHORTLISTED
              )}`}
              onClick={handleActiveStage(moveToTypes.SHORTLISTED)}
            >
              <Typography className={classes.textTitle}>Shortlisted</Typography>
            </div>
            <div
              className={`${classes.stageContainer} ${activeStageStyle(
                moveToTypes.TESTDOME
              )}`}
              onClick={handleActiveStage(moveToTypes.TESTDOME)}
            >
              <Typography className={classes.textTitle}>Testdome</Typography>
            </div>
            <div
              className={`${classes.stageContainer} ${activeStageStyle(
                moveToTypes.HIRED
              )}`}
              onClick={handleActiveStage(moveToTypes.HIRED)}
            >
              <Typography className={classes.textTitle}>Hired</Typography>
            </div>
          </div>

          <div className={classes.profileContainer}>
            <div className={classes.leftSideProfile}>
            <Avatar className={classes.profileImg} src="" alt="Profile Pic" sx={{height: 112, width: 112, fontSize: "45px"}}>
            {stringAvatar}
            </Avatar>
              <div>
                <Typography variant="h2" className={classes.profileName}>
                  {`${application?.firstname} ${application?.lastname}`}
                </Typography>
                <Typography variant="h3" className={classes.jobTitle}>
                  Candidate
                </Typography> 
                <Typography className={classes.applyDate}>
                  Applied {moment.utc(application?.createdAt).local().startOf("seconds").fromNow()}
                </Typography>
              </div>
            </div>

            <div className={classes.controlBtnsContainer}>
              <button
                onClick={handleOpenModal("schedule-interview")}
                className={`${classes.controlBtnContainer} ${classes.scheduleOnlineBtn}`}
              >
                schedule online interview{" "}
                <img
                  src={videoIcon}
                  alt="video-icon"
                  className={classes.controlIcon}
                />
              </button>
              <button
                onClick={handleOpenModal("inv-to-another-job")}
                className={`${classes.controlBtnContainer} ${classes.inviteToAnotherJobBtn}`}
              >
                Invite to another job{" "}
                <img
                  src={addIcon}
                  alt="add-icon"
                  className={classes.controlIcon}
                />
              </button>
            </div>
          </div>

          <div className={classes.candidateInfoContainer}>
            {/** Tabs containers */}
            <div className={classes.tabsContainer}>
              <div
                onClick={handleActiveTab("details")}
                className={classes.tabContainer}
              >
                <img
                  className={classes.tabIcon}
                  src={titleFrame}
                  alt="title-frame"
                />
                <Typography
                  variant="h3"
                  className={`${classes.tabTitle} ${
                    checkActiveTab("details") ? classes.activeTabTitle : ""
                  }`}
                >
                  Details
                </Typography>
              </div>

              <div
                onClick={handleActiveTab("screening-questions")}
                className={classes.tabContainer}
              >
                <img
                  className={classes.tabIcon}
                  src={titleFrame}
                  alt="title-frame"
                />
                <Typography
                  variant="h3"
                  className={`${classes.tabTitle} ${
                    checkActiveTab("screening-questions")
                      ? classes.activeTabTitle
                      : ""
                  }`}
                >
                  Screening questions {`(${application?.numberOfQuestionsAnswered}/${application?.screeningQuestions?.length})`}
                </Typography>
              </div>

              <div
                onClick={handleActiveTab("resume")}
                className={classes.tabContainer}
              >
                <img
                  className={classes.tabIcon}
                  src={titleFrame}
                  alt="title-frame"
                />
                <Typography
                  variant="h3"
                  className={`${classes.tabTitle} ${
                    checkActiveTab("resume") ? classes.activeTabTitle : ""
                  }`}
                >
                  Resume
                </Typography>
              </div>
            </div>

            {activeTab === "details" && application && <CandidateDetails application={application} job={application?.job} updateTestdome={updateTestdome} />}
            {activeTab === "screening-questions" && application && <CandidateSQAnwers screeningQuestions={application?.screeningQuestions} />}
            {activeTab === "resume" && application && <CandidateResume cv={application.cv} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewCandidate;
