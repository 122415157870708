import { CircularProgress, Grid, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { useState, useReducer, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import cancelIcon from "../../assets/images/cancel-icon.svg";
import rightArrowIcon from "../../assets/images/right-arrow-icon.svg";
import whiteRightArrowIcon from "../../assets/images/right-arrow-icon-white.svg";
import leftArrowIcon from "../../assets/images/left-arrow-icon.svg";
import titleFrame from "../../assets/images/title-frame.svg";
import classes from "./ApplyToJob.module.css";
import CandidateInfo from "../../components/CandidateInfo";
import ScreeningQuestions from "../../components/ScreeningQuestions";
import { createApplicationAPI, getJobAPI } from "../../services";
import useFlashMsg from "../../hooks/useFlashMsg";
import useApplication from "../../hooks/useApplication";
import Preview from "../../components/Preview";
import APIValidatorErrorDisplayer from "../../components/APIValidatorErrorDisplayer";
import { ApplicationCandidateInfoValidator } from "../../components/CandidateInfo/validator";

const ApplyToJob = () => {
  const { jobId } = useParams();
  const [job, setJob] = useState({});
  const navigate = useNavigate();
  const authUser = useSelector((state) => state.authReducer);
  const {
    applicationForm,
    applicationDispatch,
    handleApplicationForm,
    handleCountry,
    handleCity,
  } = useApplication();
  const [displayAPIValidation, setDisplayAPIValidation] = useState(false);
  const { handleErrorFlash, handleSuccessFlash } = useFlashMsg();
  const [activeTab, setActiveTab] = useState(1);
  const [screeningQuestionsForm, setScreeningQuestionsForm] = useState([]);
  const [clientValidationErrors, setClientValidationErrors] = useState(null);
  const [records, setRecords] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false)

  const getJob = useCallback(async () => {
    try {
      const res = await getJobAPI(jobId);

      setJob(res.data.data.data);
    } catch (err) {
      console.log(err);
      const errMsg = err.response.data.err?.msg || err.message;

      if (err.response.data.err) console.log(err.response.data.err);

      handleErrorFlash("error occurred while fetching job");
    }
  }, [jobId]);

  useEffect(() => {
    getJob();
  }, [getJob]);

  useEffect(() => {
    if (!(job && job.questions?.length > 0)) return;
    setScreeningQuestionsForm(
      job?.questions
        ?.filter((question) => question.isActive)
        .map((question) => ({
          question: question._id,
          title: question.title,
          answer: {
            answer: null,
            type: question.type,
          },
        }))
    );
  }, [job]);

  const handleScreeningAnswer = useCallback(
    (answer, questionId, questionType) => {
      setScreeningQuestionsForm((prevState) => {
        return prevState.map((screeningQuestion) => {
          if (
            screeningQuestion.question === questionId &&
            screeningQuestion.answer.type === questionType
          ) {
            return {
              ...screeningQuestion, // this is the question attribute
              answer: {
                ...screeningQuestion.answer, // this is the type answer attribute
                answer: answer !== "" ? answer : null, // this is the answer comming from the user
              },
            };
          }
          return screeningQuestion;
        });
      });
    },
    []
  );

  const handleRecords = useCallback(
    async (audio, questionId) => {
      if (!audio) {
        setRecords((prevState) =>
          prevState.filter((record) => record.questionId !== questionId)
        );
        return "";
      }
      const loggedUserId = authUser.user.id;

      const filename = `${loggedUserId}-${questionId}.wav`;

      const audioBlob = await fetch(audio).then((r) => r.blob());

      const file = new File([audioBlob], filename, { type: "audio/wav" });

      setRecords((prevState) => [
        ...prevState,
        { file, questionId, blob: audio },
      ]);

      return filename;
    },
    [authUser.user.id]
  );

  const handleCVUpload = useCallback(
    (e) => {
      const file = e.target.files[0];

      applicationDispatch({ type: "cv", payload: file });
    },
    [applicationDispatch]
  );

  const submitApplication = useCallback(async () => {
    setSubmitLoading(true);
    setDisplayAPIValidation(null);
    let numberOfQuestionsAnswered = 0;
    const formData = new FormData();
    Object.entries(applicationForm).forEach(([key, value]) => {
      formData.append(key, key === "cv" ? value : JSON.stringify(value));
    });

    if (records.length > 0) {
      for (let record of records) {
        formData.append("record", record.file);
      }
    }

    // count how many question got answered
    for (let screeningQuestion of screeningQuestionsForm) {
      if (screeningQuestion.answer.answer !== null) {
        numberOfQuestionsAnswered++;
      }
    }

    formData.append(
      "numberOfQuestionsAnswered",
      JSON.stringify(numberOfQuestionsAnswered)
    );

    formData.append(
      "screeningQuestions",
      JSON.stringify(screeningQuestionsForm)
    );

    const config = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    try {
      const applicationResult = await createApplicationAPI(
        job._id,
        formData,
        config
      );

      setSubmitLoading(false)
      handleSuccessFlash("Application Submitted Successfully!");

      navigate("/jobs");
    } catch (err) {
      setSubmitLoading(false)
      console.log(err);
      const errMsg = err.response.data.err?.msg || err.message;

      if (
        err.response.data.err &&
        !err.response.data.err.hasOwnProperty("errors")
      ) {
        console.log(err.response.data.err);
        setDisplayAPIValidation(err.response.data.err);
      }

      handleErrorFlash("error occurred while submitting application");
    }
  }, [
    applicationForm,
    handleErrorFlash,
    job._id,
    screeningQuestionsForm,
    records,
  ]);

  const handleBackPage = () => {
    setClientValidationErrors(null);
    setActiveTab((prevState) => (prevState === 1 ? 1 : prevState - 1));
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleProceedPage = () => {
    let validator;
    if (activeTab === 1) {
      validator = new ApplicationCandidateInfoValidator(applicationForm);
    } else if (activeTab === 2) {
      //validator = new ApplicationScreeningQuestionsValidator(applicationForm);
    }

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    // if validation caught display error
    if (validator?.foundError) {
      setClientValidationErrors(validator.errors);
      return;
    }

    setActiveTab((prevState) => (prevState === 3 ? 3 : prevState + 1));
  };

  const headToViewJob = () => {
    navigate(`/view-job/${job?._id}`);
  };

  return (
    <Grid container justifyContent="center">
      <Grid className={classes.container}>
        <Grid
          container
          justifyContent="flex-end"
          className={classes.controlBtnContainer}
          columnGap="24px"
        >
          <button
            onClick={headToViewJob}
            className={`${classes.controlBtn} ${classes.cancelBtn}`}
          >
            Cancel
            <img
              src={cancelIcon}
              className={classes.controlIcon}
              alt="add-icon"
            />
          </button>

          {submitLoading ? (
            <button
            style={{cursor: "default"}}
              className={`${classes.controlBtn} ${classes.submitBtn} ${
                activeTab === 3 ? classes.activeSubmitBtn : ""
              }`}
            >
              <CircularProgress style={{height: "27px"}} />
            </button>
          ) : (
            <button
              onClick={activeTab === 3 ? () => submitApplication() : () => {}}
              className={`${classes.controlBtn} ${classes.submitBtn} ${
                activeTab === 3 ? classes.activeSubmitBtn : ""
              }`}
            >
              Submit
              <img
                src={activeTab === 3 ? whiteRightArrowIcon : rightArrowIcon}
                className={classes.controlIcon}
                alt="right-arrow-con-icon"
              />
            </button>
          )}
        </Grid>

        <Grid className={classes.jobContainer}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.headerContainer}
          >
            <img
              className={classes.headerIcon}
              src={titleFrame}
              alt="title-frame"
            />
            <Typography
              variant="h2"
              fontWeight={700}
              className={classes.headerTitle}
            >
              Apply for job
            </Typography>
          </Grid>

          <div className={`${classes.stepperWrapper}`}>
            <div
              className={`${classes.stepperItem} ${
                activeTab > 1
                  ? classes.completed
                  : activeTab === 1
                  ? classes.active
                  : ""
              }`}
            >
              <div className={`${classes.stepCounter}`}>
                {activeTab > 1 ? <CheckIcon /> : "1"}
              </div>
              <div className={`${classes.stepName}`}>Candidate Information</div>
            </div>
            <div
              className={`${classes.stepperItem} ${
                activeTab > 2
                  ? classes.completed
                  : activeTab === 2
                  ? classes.active
                  : ""
              }`}
            >
              <div className={`${classes.stepCounter}`}>
                {activeTab > 2 ? <CheckIcon /> : "2"}
              </div>
              <div className={`${classes.stepName}`}>Screening Questions</div>
            </div>

            <div
              className={`${classes.stepperItem} ${
                activeTab > 3
                  ? classes.completed
                  : activeTab === 3
                  ? classes.active
                  : ""
              }`}
            >
              <div className={`${classes.stepCounter}`}>
                {activeTab > 3 ? <CheckIcon /> : "3"}
              </div>
              <div className={`${classes.stepName}`}>Preview</div>
            </div>
          </div>

          {displayAPIValidation && (
            <APIValidatorErrorDisplayer
              displayAPIValidation={displayAPIValidation}
            />
          )}

          {activeTab === 1 && (
            <CandidateInfo
              applicationForm={applicationForm}
              handleCVUpload={handleCVUpload}
              handleCountry={handleCountry}
              handleCity={handleCity}
              handleApplicationForm={handleApplicationForm}
              clientValidationErrors={clientValidationErrors}
            />
          )}
          {activeTab === 2 && screeningQuestionsForm.length > 0 && (
            <ScreeningQuestions
              questions={job.questions.filter((question) => question.isActive)}
              handleScreeningAnswer={handleScreeningAnswer}
              screeningQuestions={screeningQuestionsForm}
              handleRecords={handleRecords}
              records={records}
            />
          )}
          {activeTab === 3 && (
            <Preview
              applicationForm={applicationForm}
              screeningQuestions={screeningQuestionsForm}
              records={records}
              questions={job.questions.filter((question) => question.isActive)}
            />
          )}

          <div className={classes.controlContainerBtn}>
            {activeTab > 1 ? (
              <button className={classes.backBtn} onClick={handleBackPage}>
                <img src={leftArrowIcon} />
              </button>
            ) : (
              <div></div>
            )}

            {activeTab < 3 ? (
              <button
                className={classes.proceedBtn}
                onClick={handleProceedPage}
              >
                <img src={whiteRightArrowIcon} />
              </button>
            ) : (
              <div></div>
            )}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ApplyToJob;
