import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import classes from "./DisplayCandidateApplication.module.css"
import ViewCandidateApplication from "../ViewCandidateApplication";

const DisplayCandidateApplication = ({application, handleDisplayApplication}) => {
    

    return(
        <div>
        <DialogTitle className={classes.dialogTitle}>
          My Application
        </DialogTitle>
        <DialogContent>
          <ViewCandidateApplication application={application} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisplayApplication("close")}>Close</Button>
        </DialogActions>
        </div>
    )
}

export default DisplayCandidateApplication