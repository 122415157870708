import { Grid, Typography } from "@mui/material";
import BookmarkIcon from '@mui/icons-material/Bookmark';
import classes from "./Profile.module.css";
import titleFrame from "../../assets/images/title-frame.svg";
import lockIcon from "../../assets/images/lock-icon.svg";
import cancelIcon from "../../assets/images/cancel-icon.svg";
import { useSelector } from "react-redux";
import { useState } from "react";
import useProfile from "../../hooks/useProfile";
import useCountriesCities from "../../hooks/useCountriesCities";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const navigate = useNavigate();
  const { profileForm, handleFieldChange, handleUpdateProfile, undoUpdate, updateFlag } = useProfile();

  const { countries, cities } = useCountriesCities(
    profileForm.country
  );

  const navigateToChangePassword = () => {
    navigate("/change-password")
  }

  return (
    <Grid container justifyContent="center">
    <div className={classes.container}>
      {updateFlag && (
        <div
          className={classes.controlBtnContainer}
          columnGap="24px"
        >
          <button
            onClick={undoUpdate}
            className={`${classes.controlBtn} ${classes.cancelBtn}`}
          >
            Cancel
            <img
              src={cancelIcon}
              className={classes.controlIcon}
              alt="add-icon"
            />
          </button>
          <button
            className={`${classes.controlBtn} ${classes.updateBtn} ${classes.activeUpdateBtn}`}
            onClick={handleUpdateProfile}
          >
            Save Changes
            <BookmarkIcon
              className={classes.controlIcon}
            />
          </button>
        </div>
      )}
      <div className={`${classes.profileContainer} ${!updateFlag && classes.updateProfileContainer}`}>
        <div className={classes.titleContiner}>
          <div className={classes.headerContainer}>
            <img
              className={classes.headerIcon}
              src={titleFrame}
              alt="title-frame"
            />
            <Typography
              variant="h3"
              fontWeight={700}
              className={classes.headerTitle}
            >
              Profile info
            </Typography>
          </div>
        </div>

        <div className={classes.formContainer}>
          <div className={`${classes.halfWidthField} ${classes.leftField}`}>
            <Typography variant="body1" className={classes.fieldLabel}>
              Firstname
            </Typography>
            <input
              value={profileForm.firstname}
              onChange={handleFieldChange("firstname")}
              className={classes.textField}
            />
          </div>

          <div className={`${classes.halfWidthField}`}>
            <Typography variant="body1" className={classes.fieldLabel}>
              Lastname
            </Typography>
            <input
              value={profileForm.lastname}
              onChange={handleFieldChange("lastname")}
              className={classes.textField}
            />
          </div>

          <div className={`${classes.fullWidthField}`}>
            <Typography variant="body1" className={classes.fieldLabel}>
              Email
            </Typography>
            <input
              value={profileForm.email}
              type="email"
              onChange={handleFieldChange("email")}
              className={classes.textField}
            />
          </div>

          {/* 
          <div className={`${classes.halfWidthField}`}>
            <Typography variant="body1" className={classes.fieldLabel}>
              Phone Number
            </Typography>
            <input value={profileForm.phoneNumber} value={profileForm.firstname} className={classes.textField} />
          </div>
          */}

          <div className={`${classes.halfWidthField} ${classes.leftField}`}>
            <Typography variant="body1" className={classes.fieldLabel}>
              Country
            </Typography>
            <select
              value={profileForm.country}
              placeholder="Search for a country"
              onChange={handleFieldChange("country")}
              className={classes.selectField}
            >
              {countries.length > 0 &&
                countries.map((country) => (
                  <option key={country.iso3} value={country.name}>
                    {country.name}
                  </option>
                ))}
              {countries.length <= 0 && "Loading..."}
            </select>
          </div>

          <div className={classes.halfWidthField}>
            <Typography variant="body1" className={classes.fieldLabel}>
              City
            </Typography>
            <select
              value={profileForm.city}
              placeholder="Search for a city"
              onChange={handleFieldChange("city")}
              className={classes.selectField}
            >
              {cities.length > 0 &&
                cities.map((city) => (
                  <option key={city.id} value={city.name}>
                    {city.name}
                  </option>
                ))}
              {cities.length <= 0 && <option disabled>Loading...</option>}
            </select>
          </div>

          <button onClick={navigateToChangePassword} className={classes.changePasswordBtn}>
            Change Password <img src={lockIcon} alt="lock-icon" />
          </button>
        </div>
      </div>
    </div>
    </Grid>
  );
};

export default Profile;
