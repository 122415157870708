import { useCallback, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { ReactSortable } from "react-sortablejs";
import { useParams } from "react-router-dom";
import TuneIcon from "@mui/icons-material/Tune";

import classes from "./ViewCandidates.module.css";
import searchIcon from "../../../../assets/images/search-icon.svg";
import titleFrame from "../../../../assets/images/title-frame.svg";
import useFlashMsg from "../../../../hooks/useFlashMsg";
import { getAllApplicationsAPI, moveApplicationToAPI, moveToAPI } from "../../../../services";
import CandidateCard from "../CandidateCard";
import { moveToTypes } from "../../../../constants/moveToTypes";

const ViewCandidates = () => {
  const { jobId } = useParams();
  const [job, setJob] = useState();
  const { handleErrorFlash } = useFlashMsg();
  const [inboxPhase, setInboxPhase] = useState([]);
  const [okPhase, setOkPhase] = useState([]);
  const [shortListedPhase, setShortListedPhase] = useState([]);
  const [testdomePhase, setTestdomePhase] = useState([]);
  const [hiredPhase, setHiredPhase] = useState([]);
  const [applicationsCount, setApplicationsCount] = useState({
    all: 0,
    viewed: 140,
    notViewed: 140,
    application: 0,
    ok: 0,
    shortlisted: 0,
    testdome: 0,
    hired: 0,
  })

  useEffect(() => {
    const totalCount = inboxPhase.length + okPhase.length + shortListedPhase.length + testdomePhase.length + hiredPhase.length

    setApplicationsCount(prevState => ({
      ...prevState,
      application: inboxPhase.length,
      ok: okPhase.length,
      shortlisted: shortListedPhase.length,
      testdome: testdomePhase.length,
      hired: hiredPhase.length,
      all: totalCount
    }))
  }, [inboxPhase, okPhase, shortListedPhase, testdomePhase, hiredPhase])

  const getApplicationsFromAPI = useCallback(async () => {
    try {
      const resultData = await getAllApplicationsAPI(jobId);
      const { job: returnedJob, applications } = resultData.data.data.data;

      const {inboxApplications, okApplications, shortlistedApplications, testdomeApplications, hiredApplications} = applications;
      
      setJob(returnedJob);
      setInboxPhase(inboxApplications);
      setOkPhase(okApplications);
      setShortListedPhase(shortlistedApplications);
      setTestdomePhase(testdomeApplications);
      setHiredPhase(hiredApplications);
    } catch (err) {
      console.log(err);
      const errMsg = err.response.data.err?.msg || err.message;

      if (err.response.data.err) console.log(err.response.data.err);

      handleErrorFlash("error occurred while fetching application list");
    }
  }, []);

  useEffect(() => {
    getApplicationsFromAPI();
  }, [getApplicationsFromAPI]);

  const handleApplicationStatus = (value) => {
    setInboxPhase(value);
  };

  const handleOkStatus = (value) => {
    setOkPhase(value);
  };

  const handleShortListedStatus = (value) => {
    setShortListedPhase(value);
  };

  const handleTestdomeStatus = (value) => {
    setTestdomePhase(value);
  };

  const handleHiredStatus = (value) => {
    setHiredPhase(value);
  };

  const handleApplicationMove = async (event) => {
    const applicationId = event.item.id;
    const moveTo = event.to.id;

    try {
      const resultData = await moveApplicationToAPI(applicationId, moveTo);

    } catch (err) {
      console.log(err);
      const errMsg = err.response.data.err?.msg || err.message;

      if (err.response.data.err) console.log(err.response.data.err);

      handleErrorFlash("error occurred while moving candidate's application");
    }
}

  return (
    <div>
      <div className={classes.titleContainer}>
        <Typography variant="h3">{job?.title}</Typography>
        <Typography className={classes.titleShortInfo}>
          {job?.country}, {job?.city} - {job?.vacancyNumber} Vacancies
        </Typography>
      </div>

      <div className={classes.headerContainerFlex}>
        <div className={classes.headerContainer}>
          <div className={classes.searchInputContainer}>
            <img
              src={searchIcon}
              className={classes.searchIcon}
              alt="search-icon"
            />
            <input
              type="text"
              placeholder="Search Candidates"
              className={classes.searchInput}
            />
          </div>
          <button className={classes.addBtn}>
            Filter
            <TuneIcon className={classes.filterIcon} />
          </button>
        </div>
      </div>

      <div className={classes.filterContainerFlex}>
        <div className={classes.filterContainer}>
          <div className={classes.filterLeftSection}>
            <div className={classes.tabContainer}>
              <img
                className={classes.tabIcon}
                src={titleFrame}
                alt="title-frame"
              />
              <Typography
                variant="h3"
                fontWeight={700}
                className={`${classes.tabTitle} ${classes.activeTabTitle}`}
              >
                All {`(${applicationsCount.all})`}
              </Typography>
            </div>

            <div
              container
              justifyContent="center"
              alignItems="center"
              className={classes.tabContainer}
            >
              <img
                className={classes.tabIcon}
                src={titleFrame}
                alt="title-frame"
              />
              <Typography variant="h3" className={`${classes.tabTitle} `}>
                Viewed {`(100)`}
              </Typography>
            </div>

            <div
              container
              justifyContent="center"
              alignItems="center"
              className={classes.tabContainer}
            >
              <img
                className={classes.tabIcon}
                src={titleFrame}
                alt="title-frame"
              />
              <Typography variant="h3" className={`${classes.tabTitle} `}>
                Not Viewed {`(40)`}
              </Typography>
            </div>
          </div>

          <div className={classes.filterRightSection}>
            <Typography variant="h3" className={classes.sortBy}>
              Sort by
            </Typography>
            <select
              placeholder="Search for a category"
              className={classes.selectFilterField}
            >
              <option>Newest</option>
            </select>
          </div>
        </div>
      </div>

      <div className={classes.processesContainer}>
        <div className={classes.processContainer}>
          <div className={classes.processTitleContainer}>
            <Typography className={classes.processTitle} variant="h3">
              Application {`(${applicationsCount.application})`}
            </Typography>
          </div>

          <ReactSortable
          id={moveToTypes.INBOX}
            group="phases"
            list={inboxPhase}
            setList={handleApplicationStatus}
            animation={200}
            delayOnTouchStart={true}
            delay={2}
            className={classes.applicationsContainer}
                        onAdd={handleApplicationMove}
          >
            {inboxPhase.length> 0 && inboxPhase.map((application) => (
              <CandidateCard key={application.id} application={application} />
            ))}
          </ReactSortable>
        </div>

        <div className={classes.processContainer}>
          <div className={classes.processTitleContainer}>
            <Typography className={classes.processTitle} variant="h3">
              Ok {`(${applicationsCount.ok})`}
            </Typography>
          </div>

          <ReactSortable
          id={moveToTypes.OK}
            group="phases"
            list={okPhase}
            setList={handleOkStatus}
            animation={200}
            delayOnTouchStart={true}
            delay={2}
            className={classes.applicationsContainer}
                        onAdd={handleApplicationMove}
          >
            {okPhase.length > 0 && okPhase.map((application) => (
              <CandidateCard key={application.id} application={application} />
            ))}
          </ReactSortable>
        </div>

        <div className={classes.processContainer}>
          <div className={classes.processTitleContainer}>
            <Typography className={classes.processTitle} variant="h3">
              Shortlisted {`(${applicationsCount.shortlisted})`}
            </Typography>
          </div>

          <ReactSortable
          id={moveToTypes.SHORTLISTED}
            group="phases"
            list={shortListedPhase}
            setList={handleShortListedStatus}
            animation={200}
            delayOnTouchStart={true}
            delay={2}
            className={classes.applicationsContainer}
                        onAdd={handleApplicationMove}
          >
            {shortListedPhase.length > 0 && shortListedPhase.map((application) => (
              <CandidateCard key={application.id} application={application} />
            ))}
          </ReactSortable>
        </div>

        <div className={classes.processContainer}>
          <div className={classes.processTitleContainer}>
            <Typography className={classes.processTitle} variant="h3">
              Testdome {`(${applicationsCount.testdome})`}
            </Typography>
          </div>

          <ReactSortable
                    id={moveToTypes.TESTDOME}
            group="phases"
            list={testdomePhase}
            setList={handleTestdomeStatus}
            animation={200}
            delayOnTouchStart={true}
            delay={2}
            className={classes.applicationsContainer}
                        onAdd={handleApplicationMove}
          >
            {testdomePhase.length > 0 && testdomePhase.map((application) => (
              <CandidateCard key={application.id} application={application} />
            ))}
          </ReactSortable>
        </div>

        <div className={classes.processContainer}>
          <div className={classes.processTitleContainer}>
            <Typography className={classes.processTitle} variant="h3">
              Hired {`(${applicationsCount.hired})`}
            </Typography>
          </div>

          <ReactSortable
                    id={moveToTypes.HIRED}
            group="phases"
            list={hiredPhase}
            setList={handleHiredStatus}
            animation={200}
            delayOnTouchStart={true}
            delay={2}
            className={classes.applicationsContainer}
                        onAdd={handleApplicationMove}
          >
            {hiredPhase.length > 0 && hiredPhase.map((application) => (
              <CandidateCard key={application.id} application={application} />
            ))}
          </ReactSortable>
        </div>
      </div>
    </div>
  );
};

export default ViewCandidates;