import HomeIcon from '@mui/icons-material/Home';
import BusinessIcon from '@mui/icons-material/Business';
import SellIcon from '@mui/icons-material/Sell';
import WorkIcon from '@mui/icons-material/Work';
import CallIcon from '@mui/icons-material/Call';

export const pages = [
    {text: "Home", link: "/", icon: <HomeIcon />},
    /*{text: "About", link: "/about"},*/
    {text: "Our Model", link: "/our-model", icon: <BusinessIcon />},
    {text: "Pricing", link: "/pricing", icon: <SellIcon />},
    {text: "Job Offers", link: "/jobs", icon: <WorkIcon />},
    /*{text: "FAQs", link: "/faqs"},*/
    {text: "Contact Us", link: "/contact-us", icon: <CallIcon />},
]