import axios from "axios";
import { getAccessTokenAPI } from ".";
import { BACKEND_URI } from "../constants/endpoint";
import { flashMessagesType } from "../constants/flashMessages";
import { setFlashMessageAction } from "../slices/flashMessage-slice";
import { store } from "../store";
import { authHeader } from "../utils/authHeader";
import { authService } from "./authService";

export const serviceProvider = axios.create({ baseURL: BACKEND_URI });

export const authServiceProvider = axios.create({ baseURL: BACKEND_URI });

// created it so we don't keep going inside the infinte 403 loop request
// because of the response interpreter
const secondAuthServiceProvider = axios.create({ baseURL: BACKEND_URI });

secondAuthServiceProvider.interceptors.request.use(
  (config) => {
    config.headers.Authorization = authHeader();
    return config;
  },
  (err) => Promise.reject(err)
);

authServiceProvider.interceptors.request.use(
  (config) => {
    config.headers.Authorization = authHeader();
    return config;
  },
  (err) => Promise.reject(err)
);

authServiceProvider.interceptors.response.use(
  (res) => res,
  async (err) => {

    // if the refresh token expired then logout and reload to go to logout page
    if (err.response.status === 401) {
      authService.logout();
      window.location.reload(true);
    }

    // if he tried to access the endpoint and the accesstoken time out ended we go to this condition
    if (err.response.status === 403) {
      // we fetch the refresh token from the localStorage
      const user = JSON.parse(localStorage.getItem("user"));

      // we make a request to the backend and request a new access token with the refresh token
      const { refreshToken } = user.tokens;
      return getAccessTokenAPI({ refreshToken })
        .then((res) => {
          user.tokens.accessToken = res.data.data.accessToken;
          localStorage.setItem("user", JSON.stringify(user));
          return secondAuthServiceProvider.request(err.config);
        })
        .catch(async (error) => {
          // if the refresh token expired then logout and reload to go to logout page
          if (err.response.status === 401) {
            authService.logout();
            window.location.reload(true);
          }

          // if the refresh token expired then logout and reload to go to logout page
          if (error.response.status === 403) {
            try {
              const res = await authService.logout();

              setTimeout(() => {
                window.location.reload(true);
              }, 1000);

              if (res.status === 205)
                store.dispatch(setFlashMessageAction({message: res.data.data.msg, flashType: flashMessagesType.SUCCESS}));
            } catch (subSubErr) {
              //alert(subSubErr)
              store.dispatch(setFlashMessageAction({message: "Something Went Wrong!" , flashType: flashMessagesType.ERROR}));
            }
          }
          return Promise.reject(err);
        });
    }

    return Promise.reject(err);
  }
);