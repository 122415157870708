import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    flashType: "info",
    message: ""
}

const flashMessageSlice = createSlice({
    name: "flashMessage",
    initialState,
    reducers: {
        setFlashMessageAction: (state, action) => {
            state.message = action.payload.message;
            state.flashType = action.payload.flashType;
        },
        clearFlashMessageAction: state => {
            state.message = "";
        }
    } 
})

export const { setFlashMessageAction, clearFlashMessageAction } = flashMessageSlice.actions

export default flashMessageSlice.reducer