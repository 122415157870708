import classes from "./SingleRatingAnswer.module.css";

const SingleRatingAnswer = ({ handleScreeningAnswer, question, answer }) => {
  const handleRating = (e) => {
    const answer = e.target.value < 0 ? (e.target.value = 0) : e.target.value;

    handleScreeningAnswer(parseInt(answer), question.id, question.type);
  };

  return (
    <div>
      <input className={classes.textField} value={answer.answer} type="number" onChange={handleRating} />
    </div>
  );
};

export default SingleRatingAnswer;
