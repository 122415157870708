import { Grid, Typography } from "@mui/material";
import classes from "./Pricing.module.css"
import SloganBanner from "../../components/SloganBanner"
import SpecialInPrice from "../../components/SpecialInPrice"
import titleFrame from "../../assets/images/title-frame.svg";
import PricingCard from "../../components/PricingCard";

const prices = [
    {title: "Junior Developer", price: "€3250", list: ["Design & maintain Websites", "Good knowledge of technology", "1 - 3 years experience", "Good communication", "Problem-solving skills"]},
    {title: "Mid-Level Developer", price: "€3500", list: ["Design & maintain Websites", "Good knowledge of technology", "3 - 5 years experience", "Good communication", "Problem-solving skills"]},
    {title: "Senior Developer", price: "€3750", list: ["Design & maintain Websites", "Good knowledge of technology", "5+ years experience", "Good communication", "Problem-solving skills"]}
]

const Pricing = () => {

    return(
        <Grid>
            <SpecialInPrice style={{paddingTop: "0px", minHeight: "595px"}} />
            <SloganBanner />

            <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.headerContainer}
      >
        <img
          className={classes.headerIcon}
          src={titleFrame}
          alt="title-frame"
        />
        <Typography
          variant="h2"
          fontWeight={700}
          className={classes.headerTitle}
        >
          Our pricing
        </Typography>
      </Grid>

      <Grid container justifyContent="center" className={classes.pricingContainer}>
            {
                prices.map((price,index) => (
                    <PricingCard key={index} title={price.title} price={price.price} list={price.list} />
                ))
            }
        </Grid>
        </Grid>
    )
}

export default Pricing