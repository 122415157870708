import { Validator } from "../../../utils/Validator";

export class JobInformationValidator extends Validator {

  constructor(job) {
    super()
    const {
      title,
      postType,
      jobType,
      country,
      city,
      careerLevel,
      yearsExperience,
      salaryRange,
      vacancyNumber,
      details,
      description,
      requirements,
    } = job;

    this._errors = {
      title: this.validateTitle(title),
      postType: this.validatePostType(postType),
      jobType: this.validateJobType(jobType),
      careerLevel: this.validateCareerLevel(careerLevel),
      yearsExperience: this.validateYearsExperience(yearsExperience),
      salaryRange: this.validateSalaryRange(salaryRange),
      vacancyNumber: this.validateVacancyNumber(vacancyNumber),
      details: this.validateDetails(details),
      country: this.validateCountry(country),
      city: this.validateCity(city),
      description: this.validateDescription(description),
      requirements: this.validateRequirements(requirements),
    };
  }

  validateTitle(value) {
    const errors = [];
  
    if(!(typeof value === 'string' || value instanceof String)) {
      errors.push(this.detectError("Title Should be string!"));
      ;
      return errors;
    }
    if (value.length <= 0) {
      errors.push(this.detectError("Title required!"));
    }
    if (value.length < 5) {
      errors.push(this.detectError("Title shouldn't be less than 5 characters"));
    }
    if (value.length > 300) {
      errors.push(this.detectError("Title shouldn't be more than 300 characters"));
    }
  
    return errors;
  };
  
  validatePostType(value) {
    const errors = [];
  
    if(!(typeof value === 'string' || value instanceof String)) {
      errors.push(this.detectError("Post type Should be string!"));
      return errors;
    }
    if (value.length <= 0) {
      errors.push(this.detectError("Post type required!"));
    }
  
    return errors;
  };
  
  validateJobType(value) {
    const errors = [];
  
    if(!Array.isArray(value)){
      errors.push(this.detectError("Job type should be array!"));
      return errors
    }
  
    if (value.length <= 0) {
      errors.push(this.detectError("Job type required!"));
    }
  
    return errors;
  };
  
  validateCareerLevel(value) {
    const errors = [];
  
    if(!(typeof value === 'string' || value instanceof String)) {
      errors.push(this.detectError("Career level Should be string!"));
      return errors;
    }
    if (value.length <= 0) {
      errors.push(this.detectError("Career level required!"));
    }
  
    return errors;
  };
  
  // yearsExperience is two elements array Ex. [1, 2]
  validateYearsExperience(value) {
    const errors = [];
  
    if(!Number.isFinite(value[0]) || !Number.isFinite(value[1])) {
      errors.push(this.detectError("Years of Experience should be a number!"));
      return errors;
    }
    if (value[0] < 0 || value[1] < 0) {
      errors.push(this.detectError("Years of Experience shouldn't be less than 0!"));
    }
  
    return errors;
  };
  
  validateSalaryRange(value) {
    const errors = [];
  
    if(!Array.isArray(value)){
      errors.push(this.detectError("Salary range should be array!"));
      return errors;
    }
    if (!Number.isFinite(value[0]) || !Number.isFinite(value[1])) {
      errors.push(this.detectError("Min Salary and Max Salary Should be Number!"));
    }
    if (value.length !== 2) {
      errors.push(this.detectError("Salary range should be two values only!"));
    }
    if (value[0] < 0 || value[1] < 0) {
      errors.push(this.detectError("Salary range shouldn't be less than 0!"));
    }
    if (value[1] < value[0]) {
      errors.push(this.detectError("Max Salary shouldn't be less than Min Salary!"));
    }
  
    return errors;
  };

  validateCountry(value) {
    const errors = [];

    return errors;
  };

  validateCity(value) {
    const errors = [];
  
    return errors;
  };
  
  validateVacancyNumber(value) {
    const errors = [];
  
    if(!Number.isFinite(value)) {
      errors.push(this.detectError("Vacancy should be a Number!"));
    }
    if (value < 1) {
      errors.push(this.detectError("Vacancy Number shouldn't be less than 1!"));
    }
  
    return errors;
  };
  
  validateDetails(value) {
    const errors = [];
  
    if(!(typeof value === 'string' || value instanceof String)) {
      errors.push(this.detectError("Details Should be string!"));
      return errors;
    }
    /*
    if (value.length <= 0) {
      errors.push(this.detectError("Details required!"));
    }
    */
  
    return errors;
  };
  
  validateDescription(value) {
    const errors = [];
  
    if (!value) {
      errors.push(this.detectError("Description required!"));
    }
    /*
    if(!(typeof value === 'string' || value instanceof String)) {
      errors.push(this.detectError("Description Should be string!"));
      return errors;
    }
    if (value.length <= 0) {
      errors.push(this.detectError("Description required!"));
    }
    */
  
    return errors;
  };
  
  validateRequirements(value) {
    const errors = [];
  
    if (!value) {
      errors.push(this.detectError("Requirements required!"));
    }
    /*
    if(!(typeof value === 'string' || value instanceof String)) {
      errors.push(this.detectError("Requirements Should be string!"));
      return errors;
    }
    if (value.length <= 0) {
      errors.push(this.detectError("Requirements required!"));
    }
    */
  
    return errors;
  };
}