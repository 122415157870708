import classes from "./ForgotPassword.module.css";
import titleFrame from "../../assets/images/title-frame.svg";
import { Typography } from "@mui/material";
import { useState } from "react";
import sendIcon from "../../assets/images/send-icon.svg";
import { forgotPasswordAPI } from "../../services";
import useFlashMsg from "../../hooks/useFlashMsg";
import ClientValidatorErrorDisplayer from "../../components/ClientValidatorErrorDisplayer";

const ForgotPassword = () => {
  const { handleSuccessFlash, handleErrorFlash } = useFlashMsg();
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState([]);

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const requestRecoveryLink = async () => {
    const fieldErrors = [];

    if (email.length === 0) {
      fieldErrors.push("Email Required!");
      setErrors(fieldErrors);
      return;
    }
    if (!(typeof email === "string" || email instanceof String)) {
      fieldErrors.push("Email Should be string!");
      setErrors(fieldErrors);
      return;
    }
    if (!email.toLowerCase().match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
      fieldErrors.push("invalid email format");
      setErrors(fieldErrors);
      return;
    }

    try {
      const res = await forgotPasswordAPI({email});

      handleSuccessFlash(res.data.data.msg);
    } catch (err) {
      const errMsg = err.response.data.err?.msg || err.message;

      if (err.response.data.err) console.log(err.response.data.err);

      handleErrorFlash("error occurred while requesting to update password");
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.formContainer}>
        <div className={classes.titleContiner}>
          <div className={classes.headerContainer}>
            <img
              className={classes.headerIcon}
              src={titleFrame}
              alt="title-frame"
            />
            <Typography
              variant="h3"
              fontWeight={700}
              className={classes.headerTitle}
            >
              Forgot Password
            </Typography>
          </div>
        </div>

        <Typography className={classes.helperText}>
          Don't worry, enter your email to reset your password
        </Typography>

        <div className={`${classes.fullWidthField} ${classes.email}`}>
          <label className={classes.fieldLabel}>Email</label>
          <input
            name="email"
            value={email}
            onChange={handleInputChange}
            type="text"
            className={classes.textField}
          />

          <ClientValidatorErrorDisplayer fieldErrors={errors} />
        </div>

        <button
          onClick={requestRecoveryLink}
          className={classes.forgotPasswordBtn}
        >
          Send Recovery link <img src={sendIcon} alt="send-icon" />
        </button>

        <Typography className={classes.resendEmail}>
          Haven't recieved recovery link yet? <span>Resend Email</span>
        </Typography>
      </div>
    </div>
  );
};

export default ForgotPassword;
