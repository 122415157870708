import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "../../slices/auth-slice";
import { loginFormValidation } from "./utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import classes from "./Login.module.css";
import { Typography } from "@mui/material";
import rightArrowIcon from "../../assets/images/right-arrow-icon-white.svg";
import titleFrame from "../../assets/images/title-frame.svg";
import useFlashMsg from "../../hooks/useFlashMsg";

const ErrorDisplayer = ({errors}) => {
  return (
    <div className={classes.fieldErrorContainer}>
      {errors.length > 0 && errors.map((err) => (
        <span className={classes.errorMsg}>* {err}</span>
      ))}
    </div>
  );
};

const initFormErrors = {
  email: [],
  password: [],
}

const Login = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleErrorFlash } = useFlashMsg();

  const authUser = useSelector((state) => state.authReducer);

  const navigateToForgotPassword = () => navigate("/forgot-password");

  useEffect(() => {
    if (localStorage.getItem("user") && authUser.loggedIn) {
      navigate("/");
    }
  }, [authUser, navigate]);

  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });

  const [formErrors, setFormErrors] = useState(initFormErrors);

  const handleInputChange = (e) => {
    const inputName = e.target.getAttribute("name");
    const value = e.target.value;

    setLoginForm({ ...loginForm, [inputName]: value });
  };

  const loginUser = async () => {
    setFormErrors(initFormErrors)
    const formVal = loginFormValidation(loginForm);

    if (formVal.hasError) {
      setFormErrors(formVal.errors);
      return;
    }
    
    const link = searchParams.get("link")

    dispatch(loginAction({ ...loginForm, navigate, link })).then((res) => {
      if(res.meta.requestStatus === "fulfilled") {
        if(link) navigate(`/verify-account/${link}`)
      }
    });
  };

  const navigateToSignUp = () => {
    navigate("/register");
  };

  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}></div>

      <div className={classes.rightSideContainer}>
        <div className={classes.formContainer}>
          <div className={classes.titleContiner}>
            <div className={classes.headerContainer}>
              <img
                className={classes.headerIcon}
                src={titleFrame}
                alt="title-frame"
              />
              <Typography
                variant="h3"
                fontWeight={700}
                className={classes.headerTitle}
              >
                Sign in
              </Typography>
            </div>
          </div>

          <div className={`${classes.fullWidthField} ${classes.email}`}>
            <label className={classes.fieldLabel}>Email</label>
            <input
              name="email"
              value={loginForm.email}
              onChange={handleInputChange}
              type="text"
              className={classes.textField}
            />
            <ErrorDisplayer errors={formErrors.email} />
          </div>
          <div className={`${classes.fullWidthField} ${classes.password}`}>
            <label className={classes.fieldLabel}>Password</label>
            <input
              name="password"
              value={loginForm.password}
              onChange={handleInputChange}
              type="password"
              className={classes.textField}
            />
            <ErrorDisplayer errors={formErrors.password} />
          </div>
          <Typography
            className={classes.forgotPassword}
            onClick={navigateToForgotPassword}
          >
            Forgot password?
          </Typography>
          <button onClick={loginUser} className={classes.signInBtn}>
            Sign in <img src={rightArrowIcon} />
          </button>
          <Typography className={classes.newUser}>
            New user? <span onClick={navigateToSignUp}>Sign up</span>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Login;
