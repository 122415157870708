import { useEffect, useState } from "react";
import AudioPlayer from "react-modern-audio-player";

const CustomAudioPlayer = ({ src }) => {
  const [playlist, setPlaylist] = useState([
    {
      id: 1,
      src: ""
    },
  ]);

  useEffect(() => {
    setPlaylist([
      {
        id: 1,
        src
      },
    ]);
  }, []);

  return (
    <AudioPlayer
      playList={playlist}
      placement={{
        volumeSlider: "top",
      }}
      activeUI={{
        all: true,
        playButton: true,
        prevNnext: false,
        volume: true,
        playList: false,
        volumeSlider: true,
        repeatType: false,
        trackTime: true,
        trackInfo: false,
        artwork: false,
        progress: "bar",
      }}
    />
  );
};

export default CustomAudioPlayer;
