import { Avatar, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutAction } from "../../../slices/auth-slice";
import { userRoles } from "../../../utils/roles";

import classes from "./LoggedIn.module.css";

const LoggedIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authUser = useSelector((state) => state.authReducer);

  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (e) => {
    setAnchorElUser(e.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSettingsActions = (action) => (e) => {
    if (action === "logout") {
      dispatch(logoutAction());
      return;
    }

    navigate(action);
  };

  const settings = [
    { label: "Profile", link: "/profile" },
    {
      label: "Jobs Admin",
      link: "/admin/jobs",
      allowedRoles: [userRoles.owner, userRoles.admin],
    },
    {
      label: "Contact Us",
      link: "/admin/contact-us",
      allowedRoles: [userRoles.owner, userRoles.admin],
    },
    { label: "Logout", link: "logout" },
  ];

  const stringAvatar = authUser?.loggedIn ? `${authUser?.user?.firstname[0]}${authUser?.user?.lastname[0]}` : ""

  return (
    <div className={classes.container}>
      <Tooltip title="Open settings">
        <Avatar
        sx={{fontSize: "16px"}}
          className={classes.avatar}
          onClick={handleOpenUserMenu}
          alt="Profile Img"
          src=""
        >
          {stringAvatar}
          </Avatar>
      </Tooltip>
      <Menu
        className={classes.menu}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            width: 130,
          },
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting) => {
          if (setting.hasOwnProperty("allowedRoles")) {
            if (!setting.allowedRoles?.includes(authUser.user.role)) return;
          }
          return(
            <MenuItem
              className={classes.menuItem}
              key={setting.label}
              onClick={handleSettingsActions(setting.link)}
            >
              <p className={classes.menuText}>{setting.label}</p>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default LoggedIn;
