const env = {
    API_URL: "",
}

if(process.env.REACT_APP_REACT_ENV === "production") {
    env.API_URL = process.env.REACT_APP_PRODUCTION_API_URL;
}
else if(process.env.REACT_APP_REACT_ENV === "development") {
    env.API_URL = process.env.REACT_APP_DEVELOPMENT_API_URL;
}

export default env;