import { Typography } from "@mui/material";
import { questionTypes } from "../../../constants/questionTypes";
import freeTextIcon from "../../../assets/images/free-text-icon.svg";
import yesNoIcon from "../../../assets/images/yes-no-icon.svg";
import recordIcon from "../../../assets/images/record-icon.svg";
import classes from "./CandidateSQAnswers.module.css";
import CustomAudioPlayer from "../../CustomAudioPlayer";
import env from "../../../env";

const getQuestionTypeIcon = (type) => {
  switch (type) {
    case questionTypes.TEXT:
      return freeTextIcon;
    case questionTypes.YES_NO:
      return yesNoIcon;
    case questionTypes.RECORD:
      return recordIcon;
  }

  return freeTextIcon;
};

const CandidateSQAnwers = ({ screeningQuestions }) => {
  return (
    <div className={classes.container}>
      {screeningQuestions.map((screeningQuestion) => (
        <div className={classes.questionAnswerContainer}>
          <div className={classes.questionContainer}>
            <div className={classes.greenRec}></div>
            <Typography className={classes.question}>
              {screeningQuestion.title}
            </Typography>
            <img src={getQuestionTypeIcon(screeningQuestion.answer.type)} />
          </div>
          <div className={classes.answerContainer}>
            {screeningQuestion.answer.type !== "record" && (
              <Typography className={classes.textAnswer}>
                {screeningQuestion.answer.answer}
              </Typography>
            )}
            {screeningQuestion.answer.type === "record" && process.env.REACT_APP_REACT_ENV === "production" && (
              <CustomAudioPlayer src={`${env.API_URL}/get-file/records/${screeningQuestion.answer.answer}`} />
            )}
          </div>
        </div>
      ))}

    </div>
  );
};

export default CandidateSQAnwers;
