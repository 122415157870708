export class Validator {
  constructor() {
    this._foundError = false;
  }

  detectError(msg) {
    if (!this._foundError) this._foundError = true;
    return msg;
  }

  get foundError() {
    return this._foundError;
  }

  get errors() {
    return this._errors;
  }
}
