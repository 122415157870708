import { Grid, Typography } from "@mui/material";
import classes from "./home.module.css";
import WhyChooseUs from "../../components/Home/WhyChooseUs";
import JoinUs from "../../components/Home/JoinUs";
import SpecialInPrice from "../../components/SpecialInPrice";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Home = () => {
  const navigate = useNavigate()

  const navigateToPricing = () => {
    navigate("/jobs")
  }
  return (
    <Grid>
      <Grid
        container
        alignItems="center"
        className={classes.firstHomeContainer}
      >
        <Grid className={classes.backgroundShadowLayer}></Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.sloganContainer}
        >
          <Typography color="white" variant="h3" fontWeight={400} textAlign="center">
            Hire The Top 5% Of Developers
          </Typography>
        </Grid>
        <Grid container xs={12} className={classes.firstHomeTextHolder}>
          <Grid className={classes.firstHomeTextContainer}>
            <Typography variant="h1" fontWeight={700} className={classes.firstHomeTitle}>
              Hire <span className={classes.eliteSpan}>ELITE</span> Developers
              For Your Startup
            </Typography>
            <Typography variant="h2" fontWeight={400} color="white">
              we provide in-house developers for your company who work
              <br /> remotely from our offices in Cairo, Egypt.
            </Typography>
            <button onClick={navigateToPricing} className={classes.learnMoreBtn}>Learn More</button>
          </Grid>
        </Grid>
      </Grid>

      <WhyChooseUs />
      <JoinUs />
      <SpecialInPrice style={{paddingTop: "60px", minHeight: "500px"}} />
    </Grid>
  );
};

export default Home;
