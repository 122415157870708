import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/auth-slice";
import flashMessageReducer from "./slices/flashMessage-slice";
import popupReducer from "./slices/popup-slice";

// configureStore is the new replacement for createStore which contains thunk
export const store = configureStore({
  /**
   * this will automatically tells configureStore to use combineReducers
   */
  reducer: {
    authReducer,
    flashMessageReducer,
    popupReducer
  },
});