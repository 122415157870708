import { Grid, Typography } from "@mui/material";
import classes from "./HiringProcess.module.css";

const HiringProcess = ({ number, icon, title, description, alt }) => {
  return (
    <Grid className={classes.hiringProcessContainer}>
      <Grid container justifyContent="center" className={classes.circleContainer}>
        <Typography variant="h2" fontWeight={500} className={classes.numbering}>
          {number}
        </Typography>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.bgCircle}
        >
          <img className={classes.icon} src={icon} alt={alt} />
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        {" "}
        <Grid className={classes.verticalLine}></Grid>
      </Grid>
      <Grid container alignItems="center" flexDirection="column">
        <Typography variant="h3" fontWeight={700} className={classes.title}>
          {title}
        </Typography>
        <Typography variant="body1" fontWeight={400} className={classes.description}>
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default HiringProcess;
