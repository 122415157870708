import logo from "../../assets/images/logo.svg";
import facebookIcon from "../../assets/images/facebook-icon.svg";
import twitterIcon from "../../assets/images/twitter-icon.svg";
import instagramIcon from "../../assets/images/instagram-icon.svg";
import linkedInIcon from "../../assets/images/linkedin-icon.svg";
import classes from "./Footer.module.css";
import { useNavigate } from "react-router-dom";

const pages = {home: "/",termsOfUse: "/terms-of-use", contactUs: "/contact-us", privacyPolicy: "/privacy-policy", cookiePolicy: "/cookie-policy", facebook: "https://www.facebook.com/gettechforce/", instagram: "https://www.instagram.com/gettechforce/", linkedIn: "https://www.linkedin.com/company/gettechforce", twitter: "https://twitter.com/gettechforce"}

const Footer = () => {

    const navigate = useNavigate()

    const handlenavigation = (page) => () => {
        navigate(page)
    }
    
  return (
    <div className={classes.container}>
      <div className={classes.topContainer}>
      <div className={classes.leftSideContainer}>
        <img onClick={handlenavigation(pages.home)} alt="logo" src={logo} />
        <div className={classes.socialMediaContainer}>
          <a href={pages.facebook}>
            <div className={classes.iconContainer}>
              <img src={facebookIcon} />
            </div>
          </a>

          <a href={pages.twitter}>
            <div className={classes.iconContainer}>
              <img src={twitterIcon} />
            </div>
          </a>

          <a href={pages.instagram}>
            <div className={classes.iconContainer}>
              <img src={instagramIcon} />
            </div>
          </a>

          <a href={pages.linkedIn}>
            <div className={classes.iconContainer}>
              <img src={linkedInIcon} />
            </div>
          </a>
        </div>

        <p>
            We help you build you team of remote developers, from €3,500 Euros
            per month. The developers are managed completely by you, we don't get
          involved in the project we just provide the office for the developer
          to work out of, handle the paperwork and help you build your own team
        </p>
      </div>
      <div className={classes.rightSideContainer}>
        <div>
          <h3>ABOUT</h3>
          <div className={classes.linksContainer}>
          <p onClick={handlenavigation(pages.privacyPolicy)}>Privacy Policy</p>
          <p onClick={handlenavigation(pages.cookiePolicy)}>Cookie Policy</p>
          <p onClick={handlenavigation(pages.termsOfUse)}>Terms of use</p>
          </div>

        </div>
        <div >
          <h3>CONTACT</h3>
          <div className={classes.linksContainer}>
          <p onClick={handlenavigation(pages.contactUs)}>Contact Us</p>
          </div>

        </div>
        <div>
          <h3>CONNECT</h3>
          <div className={classes.linksContainer}>
          <a href={pages.facebook}><p>Facebook</p></a>
          <a href={pages.twitter}><p>Twitter</p></a>
          <a href={pages.instagram}><p>Instagram</p></a>
          <a href={pages.linkedIn}><p>LinkedIn</p></a>
          </div>

        </div>
      </div>
      </div>
      <div className={classes.bottomContainer}>
        <p>©2023 GetTechForce | All Rights Reserved</p>
      </div>
    </div>
  );
};

export default Footer;
