export const registerFormValidation = (data) => {

    let errors = {firstname: [], lastname: [], email: [], password: [], confirmPassword: []}

    const {firstname, lastname, email, password, confirmPassword} = data

    errors = {
        ...errors,
        firstname: validateFirstname(firstname),
        lastname: validateLastname(lastname),
        email: validateEmail(email),
        password: validatePassword(password, confirmPassword),
        confirmPassword: validateConfirmPassword(confirmPassword)
    }

    const hasError = Object.values(errors).find(err => err.length > 0) ? true : false;

    return {hasError, errors}
}

const validateFirstname = (firstname) => {
    const errors = []

    if (!(typeof firstname === "string" || firstname instanceof String)) {
        errors.push("Firstname Should be string!");
        return errors;
      }
      if (firstname.length <= 0) {
        errors.push("Firstname required!");
      }
      if (firstname.length < 2) {
        errors.push(
          "Firstname shouldn't be less than 2 characters"
        );
      }
      if (firstname.length > 300) {
        errors.push(
          "Firstname shouldn't be more than 300 characters"
        );
      }

    return errors
}

const validateLastname = (lastname) => {
    const errors = []

    if (!(typeof lastname === "string" || lastname instanceof String)) {
        errors.push("Lastname Should be string!");
        return errors;
      }
      if (lastname.length <= 0) {
        errors.push("Lastname required!");
      }
      if (lastname.length < 2) {
        errors.push(
          "Lastname shouldn't be less than 2 characters"
        );
      }
      if (lastname.length > 300) {
        errors.push(
          "Lastname shouldn't be more than 300 characters"
        );
      }

    return errors
}

const validateEmail = (email) => {
    const errors = []

    if (!(typeof email === "string" || email instanceof String)) {
        errors.push("Email Should be string!");
        return errors;
      }
      if (!email.toLowerCase().match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
        errors.push("invalid email format");
      }

    return errors
}

const validatePassword = (password, confirmPassword) => {

    const errors = []

    if(password.length <= 0) errors.push("Password Required")

    if(password !== confirmPassword) errors.push("The password confirmation doesn't match")

    if(!password.match(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/)) errors.push("Password Minimum eight characters, at least one letter, one number and one special character")

    return errors
}

const validateConfirmPassword = (confirmPassword) => {
    const errors = []

    if(confirmPassword.length <= 0) errors.push("ConfirmPassword Required")

    return errors
}