import { Validator } from "../../utils/Validator";

export class ApplicationCandidateInfoValidator extends Validator {
  constructor(application) {
    super();
    const {
      cv,
      firstname,
      lastname,
      email,
      testdome,
      linkedIn,
      github,
      wuzzuf,
      salary,
      country,
      city,
      yearsExperience,
    } = application;

    this._errors = {
        cv: this.validateCv(cv),
      firstname: this.validateFirstname(firstname),
      lastname: this.validateLastname(lastname),
      email: this.validateEmail(email),
      testdome: this.validateTestdome(testdome),
      linkedIn: this.validateLinkedIn(linkedIn),
      github: this.validateGithub(github),
      wuzzuf: this.validateWuzzuf(wuzzuf),
      salary: this.validateSalary(salary),
      country: this.validateCountry(country),
      city: this.validateCity(city),
      yearsExperience: this.validateYearsExperience(yearsExperience),
    };
  }

  validateFirstname(value) {
    const errors = [];

    if (!(typeof value === "string" || value instanceof String)) {
      errors.push(this.detectError("Firstname Should be string!"));
      return errors;
    }
    if (value.length <= 0) {
      errors.push(this.detectError("Firstname required!"));
    }
    if (value.length < 2) {
      errors.push(
        this.detectError("Firstname shouldn't be less than 2 characters")
      );
    }
    if (value.length > 300) {
      errors.push(
        this.detectError("Firstname shouldn't be more than 300 characters")
      );
    }

    return errors;
  }
  validateLastname(value) {
    const errors = [];

    if (!(typeof value === "string" || value instanceof String)) {
      errors.push(this.detectError("Lastname Should be string!"));
      return errors;
    }
    if (value.length <= 0) {
      errors.push(this.detectError("Lastname required!"));
    }
    if (value.length < 2) {
      errors.push(
        this.detectError("Lastname shouldn't be less than 2 characters")
      );
    }
    if (value.length > 300) {
      errors.push(
        this.detectError("Lastname shouldn't be more than 300 characters")
      );
    }

    return errors;
  }
  validateEmail(value) {
    const errors = [];

    if (!(typeof value === "string" || value instanceof String)) {
      errors.push(this.detectError("Email Should be string!"));
      return errors;
    }
    if (!value.toLowerCase().match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
      errors.push(this.detectError("invalid email format"));
    }

    return errors;
  }
  validateTestdome(value) {
    const errors = [];

    /*
    if (value === null || value === undefined) {
      return errors;
    }
    if (value.length <= 0) {
      errors.push(this.detectError("Testdome required!"));
    }
    if (value.length < 5) {
      errors.push(
        this.detectError("Testdome shouldn't be less than 5 characters")
      );
    }
    */
    return errors;
  }
  validateLinkedIn(value) {
    const errors = [];
    /*
    if (value === null || value === undefined) {
      return errors;
    }
    if (value.length <= 0) {
      errors.push(this.detectError("LinkedIn required!"));
    }
    if (value.length < 5) {
      errors.push(this.detectError("LinkedIn shouldn't be less than 5 characters"));
    }
    */
    return errors;
  };
  validateGithub(value) {
    const errors = [];
    /*
    if (value === null || value === undefined) {
      return errors;
    }
    if (value.length <= 0) {
      errors.push(this.detectError("Github required!"));
    }
    if (value.length < 5) {
      errors.push(this.detectError("Github shouldn't be less than 5 characters"));
    }
    */
    return errors;
  };
  validateWuzzuf(value) {
    const errors = [];
    /*
    if (value === null || value === undefined) {
      return errors;
    }
    if (value.length <= 0) {
      errors.push(this.detectError("Title Should be string!"));
    }
    if (value.length < 5) {
      errors.push(this.detectError("Title Should be string!"));
    }
    */
    return errors;
  };
  validateSalary(value) {
    const errors = [];
    if (!Number.isFinite(value)) {
      errors.push(this.detectError("Salary should be a Number!"));
    }
    if (value < 1) {
      errors.push(this.detectError("Salary shouldn't be less than 1$!"));
    }
    return errors;
  }
  validateCv(value) {
    const errors = [];
    const supportedFileTypes = ["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/pdf", "text/plain"]

    if (value.length <= 0 || !value) {
      errors.push(this.detectError("Cv required!"));
    }
    if(value.size > 2097152) {
      errors.push(this.detectError("CV Max File exceeded 2MB!"))
    }
    if(!supportedFileTypes.includes(value.type)) {
      errors.push(this.detectError("Only supported File Types are (.doc, .docx, .pdf, .txt)!"))
    }

    return errors;
  }
  validateCountry(value) {
    const errors = [];
    return errors;
  }
  validateCity(value) {
    const errors = [];
    return errors;
  }
  validateYearsExperience(value) {
    const errors = [];

    if (!Number.isFinite(value)) {
      errors.push(this.detectError("Years of Experience should be a number!"));
      return errors;
    }
    if (value < 0) {
      errors.push(
        this.detectError("Years of Experience shouldn't be less than 0!")
      );
    }

    return errors;
  }
}
