import { Grid, Typography } from "@mui/material";
import WhyChooseUsCard from "../WhyChooseUsCard";
import classes from "./WhyChooseUs.module.css";
import titleFrame from "../../../assets/images/title-frame.svg";
import anyTechnology from "../../../assets/images/any-technology.svg";
import easyCommunication from "../../../assets/images/easy-communication.svg";
import riskManagement from "../../../assets/images/risk-management.svg";
import provenSkills from "../../../assets/images/proven-skills.svg";

const WhyChooseUs = () => {
  return (
    <Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.headerContainer}
      >
        <img
          className={classes.headerIcon}
          src={titleFrame}
          alt="title-frame"
        />
        <Typography variant="h2" fontWeight={700} className={classes.headerTitle}>
          Why choose us?
        </Typography>
      </Grid>

      <Grid
        container
        xs={12}
        justifyContent="center"
        className={classes.cardsContainer}
      >
        <WhyChooseUsCard
          icon={anyTechnology}
          alt="any-technology"
          title="Any technology"
          description="We can find the ideal candidate for you whether the technology
        is web, mobile, or a desktop."
        />

        <WhyChooseUsCard
          icon={easyCommunication}
          alt="easy-communication"
          title="Easy communication"
          description="Trial period with all candidates - you only pay when happy with
            your developer."
        />

        <WhyChooseUsCard
          icon={riskManagement}
          alt="risk-management"
          title="Risk management"
          description="Trial period with all candidates - you only pay when happy with
            your developer."
        />

        <WhyChooseUsCard
          icon={provenSkills}
          alt="proven-skills"
          title="Proven skills"
          description="All developers are thoroughly tested with both internal and
            external testing tools."
        />
      </Grid>
    </Grid>
  );
};

export default WhyChooseUs;
