import { Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import classes from "./ChangePassword.module.css";
import titleFrame from "../../assets/images/title-frame.svg";
import resetIcon from "../../assets/images/reset-icon.svg";
import useFlashMsg from "../../hooks/useFlashMsg";
import ClientValidatorErrorDisplayer from "../../components/ClientValidatorErrorDisplayer";
import { changePasswordAPI } from "../../services";
import { useSelector } from "react-redux";


const ChangePassword = () => {
  const navigate = useNavigate();
  const authUser = useSelector((state) => state.authReducer);
  const { handleErrorFlash, handleSuccessFlash } = useFlashMsg();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({
    oldPassword: [],
    newPassword: [],
    confirmPassword: [],
  });

  const handleInputChange = (fieldName) => {
    return (e) => {
      if (fieldName === "oldPassword") setOldPassword(e.target.value);
      if (fieldName === "newPassword") setNewPassword(e.target.value);
      if (fieldName === "confirmPassword") setConfirmPassword(e.target.value);
    };
  };

  const validateOldPassword = (oldPass, newPass) => {
    const errors = [];

    if (oldPass.length <= 0) errors.push("Old Password Required!");

    if (oldPass === newPass)
      errors.push("Old password shouldn't be the same as new password!");

    if (
      !oldPass.match(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/
      )
    )
      errors.push(
        "Password Minimum eight characters, at least one letter, one number and one special character"
      );

    return errors;
  };

  const validateNewPassword = (newPass, confirmPass) => {
    const errors = [];

    if (newPass.length <= 0) errors.push("New Password Required!");

    if (newPass !== confirmPass)
      errors.push("The password confirmation doesn't match!");

    if (
      !newPassword.match(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/
      )
    )
      errors.push(
        "Password Minimum eight characters, at least one letter, one number and one special character"
      );

    return errors;
  };

  const validateConfirmPassword = (confirmPass) => {
    const errors = [];

    if (confirmPass.length <= 0) errors.push("ConfirmPassword Required!");

    return errors;
  };

  const validateForm = () => {
    let errors = { oldPassword: [], newPassword: [], confirmPassword: [] };

    errors = {
      ...errors,
      oldPassword: validateOldPassword(oldPassword, newPassword),
      newPassword: validateNewPassword(newPassword, confirmPassword),
      confirmPassword: validateConfirmPassword(confirmPassword),
    };

    const hasError = Object.values(errors).find((err) => err.length > 0)
      ? true
      : false;

    return { hasError, errors };
  };

  const changePassword = async () => {
    const formVal = validateForm({ password: newPassword, confirmPassword });

    if (formVal.hasError) {
      setErrors(formVal.errors);
      return;
    }

    try {
      const res = await changePasswordAPI({
        oldPassword,
        newPassword,
        confirmPassword,
      });

      console.log(res);
      navigate("/login");
      handleSuccessFlash("Successfully Changed Password!");
    } catch (err) {
      const errMsg = err.response.data.err?.msg || err.message;

      if (err.response.data.err) console.log(err.response.data.err);
      handleErrorFlash("error occurred while updating password");
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.formContainer}>
        <div className={classes.titleContiner}>
          <div className={classes.headerContainer}>
            <img
              className={classes.headerIcon}
              src={titleFrame}
              alt="title-frame"
            />
            <Typography
              variant="h3"
              fontWeight={700}
              className={classes.headerTitle}
            >
              Change your Password
            </Typography>
          </div>
        </div>

        <Typography className={classes.emailText}>
          Email: <span>{authUser.user.email}</span>
        </Typography>

        <div className={`${classes.fullWidthField} ${classes.oldPassword}`}>
          <label className={classes.fieldLabel}>Old password</label>
          <input
            value={oldPassword}
            onChange={handleInputChange("oldPassword")}
            type="password"
            className={classes.textField}
          />
          <ClientValidatorErrorDisplayer fieldErrors={errors.oldPassword} />
        </div>

        <div className={`${classes.fullWidthField} ${classes.newPassword}`}>
          <label className={classes.fieldLabel}>New password</label>
          <input
            value={newPassword}
            onChange={handleInputChange("newPassword")}
            type="password"
            className={classes.textField}
          />
          <ClientValidatorErrorDisplayer fieldErrors={errors.newPassword} />
        </div>

        <div className={`${classes.fullWidthField} ${classes.confirmPassword}`}>
          <label className={classes.fieldLabel}>Confirm password</label>
          <input
            value={confirmPassword}
            onChange={handleInputChange("confirmPassword")}
            type="password"
            className={classes.textField}
          />
          <ClientValidatorErrorDisplayer fieldErrors={errors.confirmPassword} />
        </div>

        <button onClick={changePassword} className={classes.changePasswordBtn}>
          Reset password <img src={resetIcon} alt="reset-icon" />
        </button>
      </div>
    </div>
  );
};

export default ChangePassword;
