import { Typography } from "@mui/material";
import { memo } from "react";
import classes from "./ScreeningQuestions.module.css";
import { questionTypes } from "../../constants/questionTypes";
import MultipleRatingAnswer from "../Answers/MultipleRatingAnswer";
import RecordAnswer from "../Answers/RecordAnswer";
import SingleRatingAnswer from "../Answers/SingleRatingAnswer";
import TextAnswer from "../Answers/TextAnswer";
import YesNoAnswer from "../Answers/YesNoAnswer";
import freeTextIcon from "../../assets/images/free-text-icon.svg";
import yesNoIcon from "../../assets/images/yes-no-icon.svg";
import recordIcon from "../../assets/images/record-icon.svg";

const ScreeningQuestions = ({ questions, handleScreeningAnswer, screeningQuestions, handleRecords, records }) => {

  const getQuestionTypeIcon = (type) => {
    switch (type) {
      case questionTypes.TEXT:
        return freeTextIcon;
      case questionTypes.YES_NO:
        return yesNoIcon;
      case questionTypes.RECORD:
        return recordIcon;
    }
  
    return freeTextIcon;
  };

  return (
    <div className={classes.questionsContainer}>
      {questions?.map((question, index) => {
        
        const questionObj = {
          id: question._id, type: question.type
        }
        
        const answer = screeningQuestions[index]?.answer

        const record = records.find(currRecord => currRecord.questionId === question._id)
        
        return (
        <div key={index} className={classes.fullWidthField}>
          <Typography className={classes.fieldLabel}>
            <img src={getQuestionTypeIcon(question.type)}/> {question.title} 
            {question.type === questionTypes.RECORD && <span className={classes.recordHelperText}>Max duration is 5 minutes</span>}
          </Typography>
          {question.type === questionTypes.TEXT && <TextAnswer handleScreeningAnswer={handleScreeningAnswer} question={questionObj} answer={answer} />}
          {question.type === questionTypes.RECORD && <RecordAnswer handleScreeningAnswer={handleScreeningAnswer} question={questionObj} record={record ? record.blob : null} handleRecords={handleRecords} />}
          {question.type === questionTypes.MULTIPLE_RATING && <MultipleRatingAnswer handleScreeningAnswer={handleScreeningAnswer} question={questionObj} answer={answer} />}
          {question.type === questionTypes.SINGLE_RATING && <SingleRatingAnswer handleScreeningAnswer={handleScreeningAnswer} question={questionObj} answer={answer} />}
          {question.type === questionTypes.YES_NO && <YesNoAnswer handleScreeningAnswer={handleScreeningAnswer} question={questionObj} answer={answer} />}
        </div>
      )})}
    </div>
  );
};

export default memo(ScreeningQuestions);
