import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const UnAuth = ({ children }) => {

    const navigate = useNavigate()
    const auth = useSelector(state => state.authReducer)

    useEffect(() => {
        if(auth.loggedIn) {
            navigate("/un-authorized")
        }
    }, [])

    return children
}

export default UnAuth;