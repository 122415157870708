import { Button, Grid, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import PhoneDisabledIcon from "@mui/icons-material/PhoneDisabled";
import { useNavigate } from "react-router-dom";
import useFlashMsg from "../../../../hooks/useFlashMsg";
import { getContactUsListAPI } from "../../../../services";
import titleFrame from "../../../../assets/images/title-frame.svg";
import classes from "./ContactUsList.module.css";
import moment from "moment";

const ContactUsList = () => {
  const navigate = useNavigate();
  const [contactUsList, setContactUsList] = useState([]);
  const { handleErrorFlash } = useFlashMsg();

  const getContactUsList = useCallback(async () => {
    try {
      const res = await getContactUsListAPI();

      setContactUsList(res.data.data.data);
    } catch (err) {
      console.log(err);
      const errMsg = err.response.data.err?.msg || err.message;

      if (err.response.data.err) console.log(err.response.data.err);

      handleErrorFlash("error occurred while fetching the list");
    }
  }, []);

  useEffect(() => {
    getContactUsList();
  }, [getContactUsList]);

  const handleDisplayContactUs = (contactUsId) => {
    navigate(`/admin/contact-us/${contactUsId}`);
  };

  return (
    <div className={classes.page}>
      <Grid className={classes.container}>
        <Grid
          container
          justifyContent="center"
          className={classes.tabsContainer}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.tabContainer}
          >
            <img
              className={classes.tabIcon}
              src={titleFrame}
              alt="title-frame"
            />
            <Typography
              variant="h3"
              fontWeight={700}
              className={`${classes.tabTitle} ${classes.activeTabTitle}`}
            >
              All Messages {`(${contactUsList.length})`}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          className={classes.contactUsListContainer}
          rowGap="32px"
        >
          {contactUsList.length === 0 && (
            <div className={classes.emptyContactUsList}>
              <PhoneDisabledIcon className={classes.noContactUsListIcon} />
              <p>No Messages Avaiable at the moment!</p>
            </div>
          )}
          {contactUsList.length > 0 &&
            contactUsList.map((contactUs, index) => (
              <div className={classes.contactUsContainer} key={index}>
                <h1>{contactUs.title}</h1>
                <p className={classes.createdAt}><span>Sent {moment.utc(contactUs.createdAt).local().startOf("seconds").fromNow()} Ago</span></p>
                <p><span>Name:</span> {contactUs.name}</p>
                <p><span>Email:</span> {contactUs.email}</p>
                <p><span>Message:</span> {contactUs.message}</p>
              </div>
            ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default ContactUsList;
