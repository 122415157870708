import { useSelector, useDispatch } from "react-redux";
import { Snackbar, Alert } from "@mui/material";
import { clearFlashMessageAction } from "../../slices/flashMessage-slice";

const CustomSnackbar = () => {

    const flashMessage = useSelector((state) => state.flashMessageReducer);

    const dispatch = useDispatch();

    const closeSnackbar = () => dispatch(clearFlashMessageAction())

    return (
        <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        open={
          flashMessage.message && flashMessage.message.length > 0 ? true : false
        }
        onClose={closeSnackbar}
      >
        <Alert
          onClose={closeSnackbar}
          severity={flashMessage.flashType}
          className="flash-box"
        >
          {flashMessage.message}
        </Alert>
      </Snackbar>
    )
}

export default CustomSnackbar