import { useState } from "react";
import { registerAPI } from "../../services";
import { registerFormValidation } from "./utils";
import { useDispatch } from "react-redux";
import { setFlashMessageAction } from "../../slices/flashMessage-slice";
import { flashMessagesType } from "../../constants/flashMessages";
import { useNavigate } from "react-router-dom";
import classes from "./Register.module.css";
import rightArrowIcon from "../../assets/images/right-arrow-icon-white.svg";
import titleFrame from "../../assets/images/title-frame.svg";
import { Typography } from "@mui/material";
import APIValidatorErrorDisplayer from "../../components/APIValidatorErrorDisplayer";


const ErrorDisplayer = ({errors}) => {
  return (
    <div className={classes.fieldErrorContainer}>
      {errors.length > 0 && errors.map((err) => (
        <span className={classes.errorMsg}>* {err}</span>
      ))}
    </div>
  );
};

const initFormErrors = {
  firstname: [],
  lastname: [],
  email: [],
  password: [],
  confirmPassword: [],
}

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [registerForm, setRegiserForm] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [formErrors, setFormErrors] = useState(initFormErrors);
  const [displayAPIValidation, setDisplayAPIValidation] = useState(false);

  const handleInputChange = (e) => {
    const inputName = e.target.getAttribute("name");
    const value = e.target.value;

    setRegiserForm({ ...registerForm, [inputName]: value });
  };

  const registerUser = async () => {
    setFormErrors(initFormErrors);
    setDisplayAPIValidation(null);
    const formVal = registerFormValidation(registerForm);

    if (formVal.hasError) {
      setFormErrors(formVal.errors);
      return;
    }

    try {
      const res = await registerAPI(registerForm);

      const { msg } = res.data.data;

      setTimeout(() => {
        navigate("/login");
      }, 1000);

      dispatch(
        setFlashMessageAction({
          message: msg,
          flashType: flashMessagesType.SUCCESS,
        })
      );
    } catch (err) {
      const errMsg = err.response.data.err?.msg || err.message;

      if (err.response.data.err) {
        console.log("error: ", err.response.data.err);
      }

      dispatch(
        setFlashMessageAction({
          message: errMsg,
          flashType: flashMessagesType.ERROR,
        })
      );
    }
  };

  const navigateToLogin = () => {
    navigate("/login")
  }

  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}></div>

      <div className={classes.rightSideContainer}>
        <div className={classes.formContainer}>
          <div className={classes.titleContainer}>
            <div className={classes.headerContainer}>
              <img
                className={classes.headerIcon}
                src={titleFrame}
                alt="title-frame"
              />
              <Typography
                variant="h3"
                fontWeight={700}
                className={classes.headerTitle}
              >
                Sign up
              </Typography>
            </div>
          </div>

          {
            displayAPIValidation && <APIValidatorErrorDisplayer displayAPIValidation={displayAPIValidation} />
          }
          

          <div className={`${classes.fullWidthField} ${classes.firstname}`}>
            <label className={classes.fieldLabel}>Firstname</label>
            <input
              name="firstname"
              value={registerForm.firstname}
              onChange={handleInputChange}
              className={classes.textField}
            ></input>
            <ErrorDisplayer errors={formErrors.firstname} />
          </div>
          <div className={`${classes.fullWidthField} ${classes.lastname}`}>
            <label className={classes.fieldLabel}>Lastname</label>
            <input
              name="lastname"
              value={registerForm.lastname}
              onChange={handleInputChange}
              className={classes.textField}
            ></input>
            <ErrorDisplayer errors={formErrors.lastname} />
          </div>
          <div className={`${classes.fullWidthField} ${classes.email}`}>
            <label className={classes.fieldLabel}>Email</label>
            <input
              name="email"
              value={registerForm.email}
              onChange={handleInputChange}
              className={classes.textField}
            ></input>
            <ErrorDisplayer errors={formErrors.email} />
          </div>
          <div className={`${classes.fullWidthField} ${classes.password}`}>
            <label className={classes.fieldLabel}>Password</label>
            <input
              name="password"
              type="password"
              value={registerForm.password}
              onChange={handleInputChange}
              className={classes.textField}
            ></input>
            <ErrorDisplayer errors={formErrors.password} />
          </div>
          <div
            className={`${classes.fullWidthField} ${classes.confirmPassword}`}
          >
            <label className={classes.fieldLabel}>Confirm Password</label>
            <input
              name="confirmPassword"
              type="password"
              value={registerForm.confirmPassword}
              onChange={handleInputChange}
              className={classes.textField}
            ></input>
            <ErrorDisplayer errors={formErrors.confirmPassword} />
          </div>
          <button onClick={registerUser} className={classes.signUpBtn}>
            Sign up <img src={rightArrowIcon} />
          </button>
          <Typography className={classes.existingUser}>
            Already have an account? <span onClick={navigateToLogin}>Sign in</span>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Register;
