import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Grow, Tooltip } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import classes from "./ScrollToTopBtn.module.css";
import { getCookieConsentValue } from "react-cookie-consent";
import { useSelector } from "react-redux";

const ScrollToTopBtn = () => {
  const [displayIcon, setDisplayIcon] = useState(false);
  const [displayCookie, setDisplayCookie] = useState(false);
  const { cookieConsent, calendly } = useSelector((state) => state.popupReducer);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY >= 300) {
        if (displayIcon === false) setDisplayIcon(true);
      } else {
        if (displayIcon === true) setDisplayIcon(false);
      }
    });

    window.addEventListener("load", () => {
        console.log(document.getElementsByClassName("CookieConsent"))
        if(document.getElementsByClassName("CookieConsent").length > 0) {

            if(displayCookie === false) setDisplayCookie(true);
        }
        else {
            if(displayCookie === true) setDisplayCookie(false)
        }
    })
  }, []);


  console.log(cookieConsent)

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const topClass = useMemo(() => {
    if(cookieConsent && calendly) {
      return classes.topCalendlyCookie
    } else if(cookieConsent) {
      return classes.topCookie
    } else if (calendly) {
      return classes.topCalendly
    }

    return ""
  }, [cookieConsent, calendly])

  return (
    <>
        <Grow in={displayIcon}>
          <div onClick={scrollToTop} className={`${classes.container} ${topClass}`}>
            <KeyboardArrowUpIcon className={classes.arrowIcon} />
          </div>
        </Grow>

    </>
  );
};

export default ScrollToTopBtn;
