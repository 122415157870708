import classes from "./ResetPassword.module.css";
import titleFrame from "../../assets/images/title-frame.svg";
import { Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import resetIcon from "../../assets/images/reset-icon.svg";
import { useNavigate, useParams } from "react-router-dom";
import useFlashMsg from "../../hooks/useFlashMsg";
import { checkIfValidTokenAPI, resetPasswordAPI } from "../../services";
import ClientValidatorErrorDisplayer from "../../components/ClientValidatorErrorDisplayer";

const ResetPassword = () => {
  const { userId, resetToken } = useParams();
  const { handleErrorFlash, handleSuccessFlash } = useFlashMsg();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({
    password: [],
    confirmPassword: [],
  });

  const checkIfValidToken = useCallback(async () => {
    try {
      const res = await checkIfValidTokenAPI(userId, resetToken);

      setEmail(res.data.data.data);
    } catch (err) {
      const errMsg = err.response.data.err?.msg || err.message;

      if (err.response.data.err) console.log(err.response.data.err);

      navigate("/Not-found");
      //handleErrorFlash(errMsg);
    }
  }, []);

  useEffect(() => {
    checkIfValidToken();
  }, []);

  const handleInputChange = (fieldName) => {
    return (e) => {
      if (fieldName === "newPassword") setNewPassword(e.target.value);
      if (fieldName === "confirmPassword") setConfirmPassword(e.target.value);
    };
  };

  const validatePassword = (password, confirmPassword) => {

    const errors = []

    if(password.length <= 0) errors.push("Password Required")

    if(password !== confirmPassword) errors.push("The password confirmation doesn't match")

    if(!password.match(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/)) errors.push("Password Minimum eight characters, at least one letter, one number and one special character")

    return errors
}

const validateConfirmPassword = (confirmPassword) => {
    const errors = []

    if(confirmPassword.length <= 0) errors.push("ConfirmPassword Required")

    return errors
}

  const validateForm = (data) => {
    let errors = {password: [], confirmPassword: []}

    const {password, confirmPassword} = data

    errors = {
        ...errors,
        password: validatePassword(password, confirmPassword),
        confirmPassword: validateConfirmPassword(confirmPassword)
    }

    const hasError = Object.values(errors).find(err => err.length > 0) ? true : false;

    return {hasError, errors}
  }

  const handleResetPassword = async () => {

    const formVal = validateForm({password:newPassword, confirmPassword})

    if (formVal.hasError) {
      setErrors(formVal.errors);
      return;
    }

    try {
      const res = await resetPasswordAPI({userId, resetToken, password: newPassword, confirmPassword});

      navigate("/login");
      handleSuccessFlash("Successfully Changed Password!")
    } catch (err) {
      const errMsg = err.response.data.err?.msg || err.message;

      if (err.response.data.err) console.log(err.response.data.err);
      handleErrorFlash("error occurred while resetting password");
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.formContainer}>
        <div className={classes.titleContiner}>
          <div className={classes.headerContainer}>
            <img
              className={classes.headerIcon}
              src={titleFrame}
              alt="title-frame"
            />
            <Typography
              variant="h3"
              fontWeight={700}
              className={classes.headerTitle}
            >
              Reset your Password
            </Typography>
          </div>
        </div>

        <Typography className={classes.emailText}>
          Email: <span>{email}</span>
        </Typography>

        <div className={`${classes.fullWidthField} ${classes.newPassword}`}>
          <label className={classes.fieldLabel}>New password</label>
          <input
            value={newPassword}
            onChange={handleInputChange("newPassword")}
            type="password"
            className={classes.textField}
          />
          <ClientValidatorErrorDisplayer fieldErrors={errors.password} />
        </div>

        <div className={`${classes.fullWidthField} ${classes.confirmPassword}`}>
          <label className={classes.fieldLabel}>Confirm password</label>
          <input
            value={confirmPassword}
            onChange={handleInputChange("confirmPassword")}
            type="password"
            className={classes.textField}
          />
          <ClientValidatorErrorDisplayer fieldErrors={errors.confirmPassword} />
        </div>

        <button
          onClick={handleResetPassword}
          className={classes.resetPasswordBtn}
        >
          Reset password <img src={resetIcon} alt="reset-icon" />
        </button>
      </div>
    </div>
  );
};

export default ResetPassword;
