import { Typography } from "@mui/material";

import freeTextIcon from "../../assets/images/free-text-icon.svg";
import yesNoIcon from "../../assets/images/yes-no-icon.svg";
import recordIcon from "../../assets/images/record-icon.svg";
import { questionTypes } from "../../constants/questionTypes";
import classes from "./Preview.module.css";
import CustomAudioPlayer from "../CustomAudioPlayer";

const getQuestionTypeIcon = (type) => {
  switch (type) {
    case questionTypes.TEXT:
      return freeTextIcon;
    case questionTypes.YES_NO:
      return yesNoIcon;
    case questionTypes.RECORD:
      return recordIcon;
  }

  return freeTextIcon;
};

const Preview = ({
  applicationForm,
  screeningQuestions,
  questions,
  records,
}) => {
  console.log(records);

  return (
    <div className={classes.container}>
      <div className={classes.applicationDetails}>
        <Typography variant="h3">Candidate Information</Typography>
        <div className={classes.applicationDetailsData}>
          <Typography
            className={classes.applicationDetailData}
            variant="h3"
            fontWeight={400}
          >
            First name:{" "}
            <span className={classes.applicationDetailValue}>
              {applicationForm.firstname}
            </span>
          </Typography>
          <Typography
            className={classes.applicationDetailData}
            variant="h3"
            fontWeight={400}
          >
            Last name:{" "}
            <span className={classes.applicationDetailValue}>
              {applicationForm.lastname}
            </span>
          </Typography>
          <Typography
            className={classes.applicationDetailData}
            variant="h3"
            fontWeight={400}
          >
            Email:{" "}
            <span className={classes.applicationDetailValue}>
              {applicationForm.email}
            </span>
          </Typography>
          <Typography
            className={classes.applicationDetailData}
            variant="h3"
            fontWeight={400}
          >
            Github:{" "}
            <span className={classes.applicationDetailValue}>
              {applicationForm.github}
            </span>
          </Typography>
          <Typography
            className={classes.applicationDetailData}
            variant="h3"
            fontWeight={400}
          >
            LinkedIn:{" "}
            <span className={classes.applicationDetailValue}>
              {applicationForm.linkedIn}
            </span>
          </Typography>
          <Typography
            className={classes.applicationDetailData}
            variant="h3"
            fontWeight={400}
          >
            Wuzzuf:{" "}
            <span className={classes.applicationDetailValue}>
              {applicationForm.wuzzuf}
            </span>
          </Typography>
          <Typography
            className={classes.applicationDetailData}
            variant="h3"
            fontWeight={400}
          >
            Country:{" "}
            <span className={classes.applicationDetailValue}>
              {applicationForm.country}
            </span>
          </Typography>
          <Typography
            className={classes.applicationDetailData}
            variant="h3"
            fontWeight={400}
          >
            City:{" "}
            <span className={classes.applicationDetailValue}>
              {applicationForm.city}
            </span>
          </Typography>
          <Typography
            className={classes.applicationDetailData}
            variant="h3"
            fontWeight={400}
          >
            Years of experience:{" "}
            <span className={classes.applicationDetailValue}>
              {applicationForm.yearsExperience}
            </span>
          </Typography>
          <Typography
            className={classes.applicationDetailData}
            variant="h3"
            fontWeight={400}
          >
            Expected Salary:{" "}
            <span className={classes.applicationDetailValue}>
              {applicationForm.salary}$
            </span>
          </Typography>
        </div>
      </div>

      <div className={classes.screeningQuestions}>
        <Typography variant="h3">Screening Questions</Typography>
        <div className={classes.screeningQuestionsContainer}>
          {screeningQuestions.map(
            (screeningQuestion, index) =>
              questions.find(
                (question) => question._id === screeningQuestion.question
              ) && (
                <div key={index} className={classes.screeningQuestion}>
                  <div className={classes.question}>
                    <img
                      src={getQuestionTypeIcon(screeningQuestion.answer.type)}
                    />
                    <Typography>{screeningQuestion.title}</Typography>
                  </div>

                  <div className={classes.questionAnswer}>
                    {screeningQuestion.answer.type !== "record" && (
                      <Typography>{screeningQuestion.answer.answer}</Typography>
                    )}
                    {screeningQuestion.answer.type === "record" && (
                      <CustomAudioPlayer
                      src={
                        records.find(
                          (record) =>
                            record.questionId === screeningQuestion.question
                        )?.blob
                      }
                    />
                    )}
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default Preview;
