import { Validator } from "../../utils/Validator";

export class ContactUsFormValidator extends Validator {
  constructor(form) {
    super();
    const { name, email, title, message } = form;

    this._errors = {
      name: this.validateName(name),
      email: this.validateEmail(email),
      title: this.validateTitle(title),
      message: this.validateMessage(message),
    };
  }

  validateName(value) {
    const errors = [];

    if(!value || value === "") {
      errors.push(this.detectError("Name is Required!"));
      return errors;
    }
    return errors;
  }

  validateEmail(value) {
    const errors = [];

    if(!value || value === "") {
      errors.push(this.detectError("Email is Required!"));
      return errors;
    }
    if (!(typeof value === "string" || value instanceof String)) {
      errors.push(this.detectError("Email Should be string!"));
      return errors;
    }
    if (!value.toLowerCase().match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
      errors.push(this.detectError("invalid email format"));
    }

    return errors;
  }
  validateTitle(value) {
    const errors = [];

    if(!value || value === "") {
      errors.push(this.detectError("Title is Required!"));
      return errors;
    }

    return errors;
  }
  validateMessage(value) {
    const errors = [];

    if(!value || value === "") {
      errors.push(this.detectError("Message is Required!"));
      return errors;
    }

    return errors;
  }
}
