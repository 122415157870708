import { Typography } from "@mui/material";
import ReactQuill from "react-quill";

import classes from "./JobInformation.module.css";
import addIcon from "../../../assets/images/add-icon-black.svg";
import subtractIcon from "../../../assets/images/subtract-icon.svg";
import { jobTypes } from "../../../constants/jobTypes";
import { careerLevels } from "../../../constants/careerLevels";
import useCountriesCities from "../../../hooks/useCountriesCities";
import ClientValidatorErrorDisplayer from "../../ClientValidatorErrorDisplayer";
import { useCallback, useEffect, useState } from "react";
import useFlashMsg from "../../../hooks/useFlashMsg";
import { getAllTestdomeTestsAPI } from "../../../services";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],

    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    ["link", "image"],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],

    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "code-block",
  "list",
  "bullet",
  "script",
  "indent",
  "link",
  "image",
  "direction",
  "color",
  "background",
  "font",
  "align",
  "clean",
];

const JobInformation = (props) => {
  const {
    jobState,
    handleTextField,
    handleSelectField,
    handleJobType,
    handleYearsExperience,
    handleCareerLevel,
    handleSalaryRange,
    handleDraftField,
    handleVacancyNumber,
    handleVacancyNumberChange,
    clientValidationErrors,
  } = props;
  const { countries, cities, getAllCities } = useCountriesCities(
    jobState.country
  );
  const { handleErrorFlash } = useFlashMsg();
  const [testdomeTests, setTestdomeTests] = useState([]);

  console.log(jobState);

  const getAllTestdomeTestsHandler = useCallback(async () => {
    try {
      const result = await getAllTestdomeTestsAPI();

      setTestdomeTests(result.data.data);
    } catch (err) {
      console.log(err);
      const errMsg = err.response.data.err?.msg || err.message;

      if (err.response.data.err) console.log(err.response.data.err);

      handleErrorFlash("error occurred while fetching tests");
    }
  }, []);

  useEffect(() => {
    getAllTestdomeTestsHandler();
  }, [getAllTestdomeTestsHandler]);

  const range = (start, end, step) => {
    return Array.from(
      Array.from(Array(Math.ceil((end - start) / step)).keys()),
      (x) => start + x * step
    );
  };

  const handleCountrySelection = async (e) => {
    handleSelectField("country")(e);
    getAllCities(e.currentTarget.value);
  };

  const handleTestdomeTestSelection = async (e) => {
    let testdomeTest = testdomeTests.find(
      (test) => parseInt(test.id) === parseInt(e.currentTarget.value)
    );

    if (!testdomeTest || !e.currentTarget.value) {
      testdomeTest = {
        id: null,
        name: "",
        description: "",
      };
    }

    const testdome = {
      currentTarget: {
        value: testdomeTest,
      },
    };
    handleSelectField("testdome")(testdome);
  };

  const jobTypeClasses = (value) =>
    `${classes.tagContainer} ${
      jobState.jobType &&
      jobState.jobType.includes(value) &&
      classes.selectedTagContainer
    }`;
  const careerLevelClasses = (value) =>
    `${classes.tagContainer} ${
      jobState.careerLevel &&
      jobState.careerLevel === value &&
      classes.selectedTagContainer
    }`;

  return (
    <div className={classes.container}>
      <div className={`${classes.halfWidthField} ${classes.leftField}`}>
        <Typography variant="body1" className={classes.fieldLabel}>
          Job title
        </Typography>
        <input
          value={jobState.title}
          className={classes.textField}
          onChange={handleTextField("title")}
        />

        <ClientValidatorErrorDisplayer
          fieldErrors={clientValidationErrors?.title}
        />
      </div>

      <div className={classes.halfWidthField}>
        <Typography variant="body1" className={classes.fieldLabel}>
          Job category
        </Typography>
        <select
          value={jobState.postType}
          placeholder="Search for a category"
          className={classes.selectField}
          onChange={handleSelectField("postType")}
        >
          <option value="Job">Job</option>
          <option value="Internship">Internship</option>
        </select>

        <ClientValidatorErrorDisplayer
          fieldErrors={clientValidationErrors?.postType}
        />
      </div>

      <div className={`${classes.halfWidthField} ${classes.leftField}`}>
        <Typography variant="body1" className={classes.fieldLabel}>
          Country
        </Typography>
        <select
          value={jobState.country}
          placeholder="Search for a country"
          className={classes.selectField}
          onChange={handleCountrySelection}
        >
          {countries.length > 0 &&
            countries.map((country) => (
              <option key={country.iso3} value={country.name}>
                {country.name}
              </option>
            ))}
          {countries.length <= 0 && "Loading..."}
        </select>

        <ClientValidatorErrorDisplayer
          fieldErrors={clientValidationErrors?.country}
        />
      </div>

      <div className={classes.halfWidthField}>
        <Typography variant="body1" className={classes.fieldLabel}>
          City
        </Typography>
        <select
          value={jobState.city}
          disabled={cities.length <= 0 ? true : false}
          placeholder="Search for a city"
          className={classes.selectField}
          onChange={handleSelectField("city")}
        >
          {cities.length > 0 &&
            cities.map((city) => (
              <option key={city.id} value={city.name}>
                {city.name}
              </option>
            ))}
          {cities.length <= 0 && "Loading..."}
        </select>

        <ClientValidatorErrorDisplayer
          fieldErrors={clientValidationErrors?.city}
        />
      </div>

      <div className={`${classes.fullWidthField}`}>
        <Typography variant="body1" className={classes.fieldLabel}>
          Testdome Test
        </Typography>
        <select
          value={jobState.testdome?.id}
          placeholder="Select Testdome Test"
          className={classes.selectField}
          onChange={handleTestdomeTestSelection}
        >
          <option selected="selected" disabled value="">
            Select Testdome Exam
          </option>
          <option value="">
            No Default Exam For Job
          </option>
          {testdomeTests.length > 0 &&
            testdomeTests.map((testdomeTest) => (
              <option
                key={`testdome-${testdomeTest?.id}`}
                value={testdomeTest?.id}
              >
                {`(ID:${testdomeTest.id})`} {`(Name: ${testdomeTest.name})`}{" "}
                {testdomeTest.description
                  ? `(Description: ${testdomeTest.description})`
                  : ""}
              </option>
            ))}
          {testdomeTests.length <= 0 && "Loading..."}
        </select>
      </div>

      <div className={classes.fullWidthField}>
        <Typography variant="body1" className={classes.fieldLabel}>
          Job Type
        </Typography>
        <div className={classes.tagsContainer}>
          {jobTypes.map((jobType, index) => (
            <div
              key={`job-type-${index}`}
              onClick={handleJobType(jobType.value)}
              className={jobTypeClasses(jobType.value)}
            >
              <Typography className={classes.tagLabel} variant="body1">
                {jobType.label}
              </Typography>
              <img src={addIcon} className={classes.addIcon} />
            </div>
          ))}
        </div>

        <ClientValidatorErrorDisplayer
          fieldErrors={clientValidationErrors?.jobType}
        />
      </div>

      <div style={{ width: "100%" }}>
        <Typography variant="body1" className={classes.fieldLabel}>
          Years of experience
        </Typography>
        <div className={classes.yearsOfExperienceContainer}>
          <select
            placeholder="Min"
            onChange={handleYearsExperience(0)}
            value={jobState.yearsExperience[0]}
          >
            {range(1, 10, 1).map((data) => (
              <option key={`min-${data}`} value={data}>
                {data}
              </option>
            ))}
          </select>
          <select
            placeholder="Max"
            onChange={handleYearsExperience(1)}
            value={jobState.yearsExperience[1]}
          >
            {range(jobState.yearsExperience[0], 10, 1).map((data) => (
              <option key={`max-${data}`} value={data}>
                {data}
              </option>
            ))}
          </select>
        </div>

        <ClientValidatorErrorDisplayer
          fieldErrors={clientValidationErrors?.yearsExperience}
        />
      </div>

      <div style={{ width: "100%" }}>
        <Typography variant="body1" className={classes.fieldLabel}>
          Career level
        </Typography>
        <div className={classes.tagsContainer}>
          {careerLevels.map((careerLevel, index) => (
            <div
              key={`career-level-${index}`}
              onClick={handleCareerLevel(careerLevel.value)}
              className={careerLevelClasses(careerLevel.value)}
            >
              <Typography className={classes.tagLabel} variant="body1">
                {careerLevel.label}
              </Typography>
              <img src={addIcon} className={classes.addIcon} />
            </div>
          ))}
        </div>

        <ClientValidatorErrorDisplayer
          fieldErrors={clientValidationErrors?.careerLevel}
        />
      </div>

      <div className={classes.fullWidthField}>
        <Typography variant="body1" className={classes.fieldLabel}>
          Salary range
        </Typography>
        <div className={classes.salaryContainer}>
          <input
            placeholder="Min"
            onChange={handleSalaryRange(0)}
            value={jobState.salaryRange[0]}
          />
          <input
            placeholder="Max"
            onChange={handleSalaryRange(1)}
            value={jobState.salaryRange[1]}
          />
        </div>

        <ClientValidatorErrorDisplayer
          fieldErrors={clientValidationErrors?.salaryRange}
        />
      </div>

      <div className={classes.fullWidthField}>
        <Typography variant="body1" className={classes.fieldLabel}>
          Job Description
        </Typography>

        <ReactQuill
          theme="snow"
          value={jobState.description}
          onChange={handleDraftField("description")}
          modules={modules}
          formats={formats}
        />

        <ClientValidatorErrorDisplayer
          fieldErrors={clientValidationErrors?.description}
        />
      </div>

      <div className={classes.fullWidthField}>
        <Typography variant="body1" className={classes.fieldLabel}>
          Job Requirements
        </Typography>

        <ReactQuill
          theme="snow"
          value={jobState.requirements}
          onChange={handleDraftField("requirements")}
          modules={modules}
          formats={formats}
        />

        <ClientValidatorErrorDisplayer
          fieldErrors={clientValidationErrors?.requirements}
        />
      </div>

      <div className={classes.fullWidthField}>
        <Typography variant="body1" className={classes.fieldLabel}>
          Number of vacancies
        </Typography>
        <div className={classes.vacanciesContainer}>
          <button
            onClick={handleVacancyNumberChange("decrement")}
            className={`${classes.vacanciesControl} ${
              classes.subtractVacanciesBtn
            } ${jobState.vacancyNumber <= 1 && classes.disableBtn}`}
          >
            <img src={subtractIcon} alt="subtract-icon" />
          </button>
          <input
            value={jobState.vacancyNumber}
            onChange={handleVacancyNumber}
            className={classes.vacanciesInput}
          />
          <button
            onClick={handleVacancyNumberChange("increment")}
            className={`${classes.vacanciesControl} ${classes.addVacanciesBtn}`}
          >
            <img src={addIcon} alt="add-icon" />
          </button>
        </div>

        <ClientValidatorErrorDisplayer
          fieldErrors={clientValidationErrors?.vacancyNumber}
        />
      </div>
    </div>
  );
};

export default JobInformation;
