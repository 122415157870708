import { Grid, Typography } from "@mui/material"
import notFoundImg from "../../assets/images/error-404.png"
import classes from "./NotFound.module.css"

const NotFound = () => {

    return(
        <div className={classes.container}>
            <img src={notFoundImg} className={classes.notFoundImg} />
            <Typography variant="h2" className={classes.code}>404</Typography>
            <Typography variant="h2" className={classes.title}>Page Not Found!</Typography>
            <Typography variant="h3" className={classes.description}>The page you are looking for doesn't exist or an other error occured.</Typography>
        </div>
    )
}

export default NotFound