import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { flashMessagesType } from "../constants/flashMessages";
import { authService } from "../services/authService";
import { setFlashMessageAction } from "./flashMessage-slice";

export const loginAction = createAsyncThunk(
  "auth/loginAction",
  async ({ email, password }, thunkAPI) => {
    try {
      const res = await authService.login(email, password);

      delete res.data.tokens;

      thunkAPI.dispatch(
        setFlashMessageAction({
          message: res.msg,
          flashType: flashMessagesType.SUCCESS,
        })
      );
      return thunkAPI.fulfillWithValue({ ...res.data });
    } catch (err) {
      const message = err.response.data?.err || err.message;

      await thunkAPI.dispatch(
        setFlashMessageAction({ message, flashType: flashMessagesType.ERROR })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateProfileAction = createAsyncThunk(
  "auth/updateProfileAction",
  async (updatedUser, thunkAPI) => {
    try {
      localStorage.setItem("user", JSON.stringify(updatedUser));

      thunkAPI.dispatch(
        setFlashMessageAction({
          message: "Updated Profile!",
          flashType: flashMessagesType.SUCCESS,
        })
      );
      return thunkAPI.fulfillWithValue({ ...updatedUser });
    } catch (err) {
      const message = err.response.data;

      thunkAPI.dispatch(
        setFlashMessageAction({ message, flashType: flashMessagesType.ERROR })
      );
    }
  }
);

export const verifyAccountAction = createAsyncThunk(
  "auth/verifyAccountAction",
  async (newData, thunkAPI) => {
    let user = JSON.parse(localStorage.getItem("user"));
    try {

      localStorage.setItem(
        "user",
        JSON.stringify({
          ...user,
          isVerified: true
        })
      );

      thunkAPI.dispatch(
        setFlashMessageAction({
          message: "Account Got Verified Successfully!",
          flashType: flashMessagesType.SUCCESS,
        })
      );
      return thunkAPI.fulfillWithValue();
    } catch (err) {
      const message = err?.response?.data;

      thunkAPI.dispatch(
        setFlashMessageAction({ message, flashType: flashMessagesType.ERROR })
      );
    }
  }
);

export const logoutAction = createAsyncThunk(
  "auth/logoutAction",
  async (_, thunkAPI) => {
    try {
      const res = await authService.logout();

      if (res.status === 205)
        thunkAPI.dispatch(
          setFlashMessageAction({
            message: "Logged Out Successfully!",
            flashType: flashMessagesType.SUCCESS,
          })
        );

      setTimeout(() => {
        window.location.reload(true);
      }, 500);
    } catch (err) {
      thunkAPI.dispatch(
        setFlashMessageAction({
          message: "Something Went Wrong!",
          flashType: flashMessagesType.ERROR,
        })
      );
    }
  }
);

// if we closed the session or server and opened again then the state will get the data from localstorage if available
let user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? { loggedIn: true, user }
  : { loggedIn: false, user: null };

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [loginAction.fulfilled]: (state, action) => {
      console.log("fulfilled");
      state.loggedIn = true;
      state.user = action.payload;
    },
    [loginAction.rejected]: (state) => {
      state.loggedIn = false;
      state.user = null;
    },
    [updateProfileAction.fulfilled]: (state, action) => {
      state.loggedIn = true;
      state.user = action.payload;
    },
    [verifyAccountAction.fulfilled]: (state) => {
      state.user.isVerified = true;
    },
    [logoutAction.fulfilled]: (state) => {
      state.loggedIn = false;
      state.user = null;
    },
  },
});

export default authSlice.reducer;
