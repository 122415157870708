import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useFlashMsg from "../../hooks/useFlashMsg";

const excludedPages = ["/profile"]


const Auth = ({ children }) => {

    const navigate = useNavigate()
    const location = useLocation()
    const auth = useSelector(state => state.authReducer)
    const { handleInfoFlash, handleErrorFlash } = useFlashMsg()

    useEffect(() => {
        if(!auth.loggedIn) {
            navigate("/login")
            handleInfoFlash("You need to Login to access the page!")
        }

        if(!auth?.user?.isVerified && !excludedPages.includes(location.pathname)) {
            navigate("/")
            handleErrorFlash("You need to verify Your Email before proceeding for this page!")
        }
    }, [auth.loggedIn, navigate])

    if(auth.loggedIn) {
        return children
    }
    else {
        return <div>Loading...</div>
    }

}

export default Auth;