import { Grid } from "@mui/material";
import Navbar from "../Navbar";

const AdminSystem = ({children}) => {

    return(
        <Grid>
            <Navbar />
            {children}
        </Grid>
    )
}

export default AdminSystem;