import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userRoles } from "../../utils/roles";

const { owner: ownerRole, admin: adminRole } = userRoles;

const AuthRoles = ({ children, allowedRoles = [] }) => {
  const navigate = useNavigate();
  const authUser = useSelector((state) => state.authReducer);
  useEffect(() => {
    const userRole = authUser.user.role;
    if (
      !(
        userRole === ownerRole ||
        userRole === adminRole ||
        allowedRoles.includes(userRole)
      )
    ) {
      navigate("/not-found");
    }
  }, [navigate, authUser, allowedRoles]);

  return children;
};

export default AuthRoles;
