import env from "../../../env";
import classes from "./CandidateResume.module.css";

const CandidateResume = ({ cv }) => {
  return (
    <div className={classes.container}>
      {process.env.REACT_APP_REACT_ENV === "production" && (
        <iframe
          className={classes.cv}
          src={`${env.API_URL}/get-file/cvs/${cv}`}
        />
      )}
    </div>
  );
};

export default CandidateResume;
