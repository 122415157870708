import { loginAPI, logoutAPI } from ".";

const login = async (email, password) => {
  try {
    const apiResult = await loginAPI({ email, password });
    if (apiResult.data.data.tokens.accessToken) {
      localStorage.setItem("user", JSON.stringify(apiResult.data.data));
    } else {
      localStorage.removeItem("user");
    }
    return apiResult.data;
  } catch (err) {
    throw err;
  }
};

const logout = () => {
  let refreshToken = null;
  const user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    refreshToken = user.tokens.refreshToken;
  }
  return logoutAPI({ refreshToken }).then((res) => {
    localStorage.removeItem("user");
    return res;
  });
};

export const authService = {
  login,
  logout,
};
