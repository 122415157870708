import React, { useEffect, useMemo } from "react";
import { PopupWidget } from "react-calendly";
import { useDispatch, useSelector } from "react-redux";
import {
  hideCalendlyAction,
  showCalendlyAction,
} from "../../slices/popup-slice";
import classes from "./CalendlyWrapper.module.css"

const CalendlyWrapper = ({ children }) => {
  const {cookieConsent} = useSelector((state) => state.popupReducer)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showCalendlyAction());
    return () => {
      dispatch(hideCalendlyAction());
    };
  }, [dispatch]);

  const topClass = useMemo(() => {
    if(cookieConsent) {
      return classes.topCookie
    } 
    return ""
  }, [cookieConsent])

  return (
    <>
      {children}
      <div className={`${classes.calendly} ${topClass}`}>
        <PopupWidget
          url="https://calendly.com/krakowkev/intro-15-min-quick-call"
          /*
           * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
           * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
           */
          rootElement={document.getElementById("root")}
          text="Click here to schedule a meeting!"
          textColor="var(--white-color)"
          color="var(--primary-color)"
        />
      </div>
    </>
  );
};

export default CalendlyWrapper;
