import { useDispatch } from "react-redux"
import { flashMessagesType } from "../constants/flashMessages";
import { setFlashMessageAction } from "../slices/flashMessage-slice";

const useFlashMsg = () => {

    const dispatch = useDispatch()

    const handleFlash = (msg, type) => {
      dispatch(
        setFlashMessageAction({
          message: msg,
          flashType: type,
        })
      );
    }

    return {
        handleErrorFlash: (msg) => handleFlash(msg, flashMessagesType.ERROR),
        handleSuccessFlash: (msg) => handleFlash(msg, flashMessagesType.SUCCESS),
        handleWarningFlash: (msg) => handleFlash(msg, flashMessagesType.WARNING),
        handleInfoFlash: (msg) => handleFlash(msg, flashMessagesType.INFO)
    }
}

export default useFlashMsg