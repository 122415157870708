import classes from "./ScheduleInterviewModal.module.css";
import titleFrame from "../../../assets/images/title-frame.svg";
import { Typography } from "@mui/material";
import cancelIcon from "../../../assets/images/cancel-icon.svg"
import sendIcon from "../../../assets/images/send-icon.svg"

const ScheduleInterviewModal = ({handleCloseModal}) => {
  return (
    <div className={classes.container}>
      <div className={classes.titleContiner}>
        <div className={classes.headerContainer}>
          <img
            className={classes.headerIcon}
            src={titleFrame}
            alt="title-frame"
          />
          <Typography
            variant="h3"
            fontWeight={700}
            className={classes.headerTitle}
          >
            Invite <span className={classes.candidateName}>Marwan Samih</span> to another job
          </Typography>
        </div>
      </div>
      
      <div className={`${classes.fullWidthField} ${classes.interviewerEmail}`}>
        <Typography variant="body1" className={classes.fieldLabel}>
            Interviewer’s email
        </Typography>
        <input className={classes.textField} />
      </div>

      <div className={`${classes.fullWidthField} ${classes.applicantEmail}`}>
        <Typography variant="body1" className={classes.fieldLabel}>
            Applicant email
        </Typography>
        <input className={classes.textField} />
      </div>

      <div className={classes.dateContainer}>
        <div className={classes.interviewDateContainer}>
          <Typography variant="body1" className={classes.fieldLabel}>
            Interview date
          </Typography>
          <input type="date" className={classes.dateField} />
        </div>

        <div className={classes.interviewTimeContainer}>
          <div className={classes.fromTime}>
            <Typography variant="body1" className={classes.fieldLabel}>
              From
            </Typography>
            <input type="time" placeholder="09:00 AM" />
          </div>

          <div className={classes.toTime}>
            <Typography variant="body1" className={classes.fieldLabel}>
              To
            </Typography>
            <input type="time" placeholder="12:00 AM" />
          </div>
        </div>
      </div>


      <div className={`${classes.fullWidthField} ${classes.addDescription}`}>
        <Typography variant="body1" className={classes.fieldLabel}>
            Add description
        </Typography>
        <textarea className={`${classes.textField} ${classes.textAreaField}`} />
      </div>

      <div className={classes.controlBtnsContainer}>
        <button onClick={handleCloseModal} className={`${classes.controlBtn} ${classes.cancelBtn}`}>Cancel <img src={cancelIcon} alt="cancel-icon" /></button>
        <button className={`${classes.controlBtn} ${classes.sendBtn}`}>Send invitation <img src={sendIcon} alt="send-icon" /></button>
      </div>
    </div>
  );
};

export default ScheduleInterviewModal;
