import "./assets/styles/App.css";
import 'react-quill/dist/quill.snow.css';
import { Routes } from "./routes";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import CustomSnackbar from "./components/CustomSnackbar";
import ScrollToTopBtn from "./components/ScrollToTopBtn";
import CookiePopup from "./components/CookiePopup";


const App = () => {

  return (
    <BrowserRouter>
    <CustomSnackbar />
    <CookiePopup />
      <ScrollToTop />
      <ScrollToTopBtn />
      <Routes />
    </BrowserRouter>
  );
};

export default App;
