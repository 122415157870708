import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Avatar,
  Modal,
  styled,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import classes from "./CandidateDetails.module.css";
import profilePic from "../../../assets/images/demo.jfif";
import previewIcon from "../../../assets/images/preview-icon.svg";
import { moveToTypes } from "../../../constants/moveToTypes";
import TestdomeModal from "./TestdomeModal";
import { useState } from "react";
import CandidateTestdome from "./CandidateTestdome";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    border: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  borderRadius: "5px",
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const CandidateDetails = ({ application, job, updateTestdome }) => {
  const stringAvatar = `${application?.firstname[0]}${application?.lastname[0]}`;

  const [openModal, setOpenModal] = useState(false);
  const [expandAccordion, setExpandAccordion] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleAccordionChange = () =>
    setExpandAccordion((prevState) => !prevState);

  return (
    <>
      <Modal open={openModal} onClose={handleCloseModal}>
        <TestdomeModal
          updateTestdome={updateTestdome}
          testId={job?.testdome?.id}
          fullName={`${application?.firstname} ${application?.lastname}`}
          email={application?.email}
          handleCloseModal={handleCloseModal}
        />
      </Modal>
      <div className={classes.personalInfoContainer}>
        <Typography variant="h3">Personal informations</Typography>

        <div className={classes.personalInfoDataContainer}>
          <Typography className={classes.infoData}>
            <span className={classes.infoLabel}>Country: </span>
            {application.country}
          </Typography>
          <Typography className={classes.infoData}>
            <span className={classes.infoLabel}>City: </span>
            {application.city}
          </Typography>
          <Typography className={classes.infoData}>
            <span className={classes.infoLabel}>Years of experiences: </span>
            {application.yearsExperience} Years
          </Typography>
          <Typography className={classes.infoData}>
            <span className={classes.infoLabel}>Expected Salary: </span>
            {application.salary}$
          </Typography>
        </div>
      </div>

      <div className={classes.contactInfoContainer}>
        <Typography variant="h3">Contact informations</Typography>

        <div className={classes.contactInfoDataContainer}>
          <Typography className={classes.infoData}>
            <span className={classes.infoLabel}>Email: </span>
            {application.email}
          </Typography>
          {/*<Typography className={classes.infoData}><span className={classes.infoLabel}>Phone: </span>+201110005839</Typography>*/}
          <Typography className={`${classes.infoData} ${classes.link}`}>
            <span className={classes.infoLabel}>LinkedIn: </span>
            <a href={application.linkedIn} target="_blank" rel="noreferrer">
              {application.linkedIn}
            </a>
          </Typography>
          <Typography className={`${classes.infoData} ${classes.link}`}>
            <span className={classes.infoLabel}>Github: </span>
            <a href={application.github} target="_blank" rel="noreferrer">
              {application.github}
            </a>
          </Typography>
          <Typography className={`${classes.infoData} ${classes.link}`}>
            <span className={classes.infoLabel}>Wuzzuf: </span>
            <a href={application.wuzzuf} target="_blank" rel="noreferrer">
              {application.wuzzuf}
            </a>
          </Typography>
        </div>
        {Object.keys(application?.testdome).length > 0 && (
          <Accordion
            className={classes.accordion}
            elevation={0}
            expanded={expandAccordion}
            onChange={
              application?.testdome?.id ? handleAccordionChange : () => {}
            }
          >
            <AccordionSummary
              className={classes.accordionSummary}
              expandIcon={application?.testdome?.id && <ExpandMoreIcon />}
            >
              <Typography
                className={`${classes.infoLabel} ${classes.infoData}`}
              >
                Candidate's Testdome Info{" "}
                {!application?.testdome?.id && (
                  <span className={classes.reminder}>
                    {"(Testdome Test Haven't been Sent for Candidate)"}
                  </span>
                )}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {application?.testdome?.id && (
                <CandidateTestdome
                  testId={application?.testdome?.test?.id}
                  email={application?.email}
                  test={application?.testdome?.test}
                />
              )}
            </AccordionDetails>
          </Accordion>
        )}

        {application.moveTo === moveToTypes.TESTDOME &&
          !application?.testdome?.id && (
            <button onClick={handleOpenModal} className={classes.testdomeBtn}>
              Send Testdome Test
            </button>
          )}
        {application.moveTo === moveToTypes.TESTDOME &&
          application?.testdome?.id && (
            <button className={`${classes.testdomeBtn} ${classes.disabled}`}>
              Testdome Test Already Been Sent
            </button>
          )}
      </div>

      <div className={classes.notesContainer}>
        <Typography variant="h3">Notes</Typography>

        <div className={classes.notesDataContainer}>
          <Avatar
            className={classes.profileImg}
            src=""
            alt="Profile Pic"
            sx={{ height: 56, width: 56, fontSize: "20px" }}
          >
            {stringAvatar}
          </Avatar>
          <input
            placeholder="Write a note about this candidate"
            className={classes.notesInput}
          />
          <div className={classes.helperTextContainer}>
            <img src={previewIcon} alt="preview-icon" />
            <Typography className={classes.helperText}>
              Only visibile to you {"&"} your team
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};

export default CandidateDetails;
