import { Grid } from "@mui/material";
import RecordControls from "../../RecordControls";
import useRecorder from "../../../hooks/useRecorder";
import Recording from "../../Recording";
import { useCallback, useEffect } from "react";

const RecordAnswer = ({
  handleScreeningAnswer,
  question,
  handleRecords,
  record,
}) => {
  const { recorderState,  ...handlers } = useRecorder(record);
  const { audio } = recorderState;

  const handleRecordSave = useCallback(async () => {
    const filename = await handleRecords(audio, question.id);
    handleScreeningAnswer(filename, question.id, question.type);
  }, [audio, handleScreeningAnswer, question.id, question.type, handleRecords])

  useEffect(() => {
    handleRecordSave()
  }, [handleRecordSave]);

  return (
    <Grid>
      {audio ? (
        <Recording audio={audio} handlers={handlers} />
      ) : (
        <RecordControls recorderState={recorderState} handlers={handlers} />
      )}
    </Grid>
  );
};

export default RecordAnswer;
