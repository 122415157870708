import classes from "./InvToAnotherJobModal.module.css";
import titleFrame from "../../../assets/images/title-frame.svg";
import { Typography } from "@mui/material";
import cancelIcon from "../../../assets/images/cancel-icon.svg"
import sendIcon from "../../../assets/images/send-icon.svg"

const InvToAnotherJobModal = ({handleCloseModal}) => {

    return (
        <div className={classes.container}>
        <div className={classes.titleContiner}>
          <div className={classes.headerContainer}>
            <img
              className={classes.headerIcon}
              src={titleFrame}
              alt="title-frame"
            />
            <Typography
              variant="h3"
              fontWeight={700}
              className={classes.headerTitle}
            >
                Invite <span className={classes.candidateName}>Marwan Samih</span> to another job
            </Typography>
          </div>
        </div>
        
        <div className={`${classes.fullWidthField} ${classes.sendJobToInvTo}`}>
          <Typography variant="body1" className={classes.fieldLabel}>
            Select job to invite to
          </Typography>
          <select placeholder="Search for a city" className={classes.selectField}>
            <option>1</option>
            </select>
        </div>
  
        <div className={`${classes.fullWidthField} ${classes.emailPreviewContainer}`}>
          <Typography variant="body1" className={classes.fieldLabel}>
              Email preview
          </Typography>
          <div className={classes.emailPreview}>
            <Typography className={classes.emailPreviewText}><span className={classes.boldText}>Subject:</span> GetTechForce.com invites you to apply for a <span className={classes.boldText}>[Job title]</span> vacancy. <br/>
            <span className={classes.boldText}>From:</span> Kevin from GetTechForce.com<br />
            <br />
            Hi Marwan,<br />
            I came across your profile on <span className={classes.boldText}>Techforce</span> and would like to invite you to apply for the <span className={classes.boldText}>[Job title]</span> vacancy we currently have open.<br />
            <br />
            Your profile seems like a good match for what we are looking for.<br />
            If you're interested in learning more about this vacancy, check out the job posting.Looking forward to receiving your application.<br />
            View Job | Not Interested<br />
            Regards,<br />
            Kevin, CEO at GetTechForce.com<br />
            </Typography>
          </div>
        </div>

        <div>

        </div>
  
        <div className={classes.controlBtnsContainer}>
          <button onClick={handleCloseModal} className={`${classes.controlBtn} ${classes.cancelBtn}`}>Cancel <img src={cancelIcon} alt="cancel-icon" /></button>
          <button className={`${classes.controlBtn} ${classes.sendBtn}`}>Send invitation <img src={sendIcon} alt="send-icon" /></button>
        </div>
      </div>
    )
}

export default InvToAnotherJobModal