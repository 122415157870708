import { Grid, Typography } from "@mui/material";
import classes from "./PricingCard.module.css";

const PricingCard = ({title, price, list}) => {
  return (
    <Grid className={classes.cardContainer}>
      <Typography variant="h2" fontWeight={700}>
        {title}
      </Typography>
      <Typography variant="h2" fontWeight={400} className={classes.priceText}>
        from <span className={classes.price}>{price}</span>
      </Typography>

      <ul className={classes.listContainer}>
        {
            list?.map((item, index) => (
                <li key={`list-${index}`} className={classes.list}><span>{item}</span></li>
            ))
        }
      </ul>
    </Grid>
  );
};

export default PricingCard;
