import { useCallback, useEffect, useState } from "react";
import useFlashMsg from "../../../../hooks/useFlashMsg";
import { getCandidateTestDataAPI } from "../../../../services";
import classes from "./CandidateTestdome.module.css";

const DataDisplayer = ({ label, data }) => {
  return (
    <div className={classes.infoData}>
      <span className={classes.infoLabel}>{label}: </span>
      {data ? data : <span className={classes.nullValue}>No Value</span>}
    </div>
  );
};

const CandidateTestdome = ({ testId, email, test }) => {
  const [candidateData, setCandidateData] = useState([]);
  const { handleErrorFlash } = useFlashMsg();

  console.log(candidateData);

  const getCandidateTestDataHandler = useCallback(async () => {
    try {
      const result = await getCandidateTestDataAPI({ testId, email });

      setCandidateData(result.data.data);
    } catch (err) {
      console.log(err);
      const errMsg = err.response.data.err?.msg || err.message;

      if (err.response.data.err) console.log(err.response.data.err);

      handleErrorFlash("something went wrong while fetching candidates!");
    }
  }, []);

  useEffect(() => {
    getCandidateTestDataHandler();
  }, [getCandidateTestDataHandler]);

  return (
    <div>
      {candidateData.length > 0 &&
        candidateData.map((candidate, index) => (
          <div key={index} className={classes.candidateTest}>
            <DataDisplayer label={"Test ID"} data={test?.id} />
            <DataDisplayer label={"Test Name"} data={test?.name} />
            <DataDisplayer label={"Candidate ID"} data={candidate.id} />
            <DataDisplayer
              label={"Completed Date"}
              data={candidate.completedDate}
            />
            <DataDisplayer
              label={"Invitation Date"}
              data={candidate.invitationDate}
            />
            <DataDisplayer
              label={"Invitation End-Date"}
              data={candidate.invitationEndDare}
            />
            <DataDisplayer label={"Report URL"} data={candidate.reportUrl} />
            <DataDisplayer label={"Status"} data={candidate.status} />
            <DataDisplayer label={"Test Score"} data={candidate.testScore} />
            <DataDisplayer label={"Test URL"} data={candidate.testUrl} />
            <div>
              <span className={classes.infoLabel}>Notes: </span>
              {["dsakjdnasdkjansdkja", "daskjndakjdnasjkdn"].map((note) => (
                <div className={classes.note}>{note}</div>
              ))}
            </div>
          </div>
        ))}
    </div>
  );
};

export default CandidateTestdome;
