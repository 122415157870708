import { createSlice } from "@reduxjs/toolkit"
import { getCookieConsentValue } from "react-cookie-consent";

const cookieConsentValue = getCookieConsentValue("cookie-consent");

const initialState = {
    cookieConsent: cookieConsentValue ? false : true,
    calendly: false,
}

const popupSlice = createSlice({
    name: "popup",
    initialState,
    reducers: {
        hideCookieConsentAction: state => {
            state.cookieConsent = false;
        },
        showCookieConsentAction: state => {
            state.cookieConsent = true;
        },
        hideCalendlyAction: state => {
            state.calendly = false;
        },
        showCalendlyAction: state => {
            state.calendly = true;
        }
    } 
})

export const { hideCookieConsentAction, showCookieConsentAction, hideCalendlyAction, showCalendlyAction } = popupSlice.actions

export default popupSlice.reducer