import { Grid, Typography } from "@mui/material";
import classes from "./RecordControls.module.css";

const RecordControls = ({ recorderState, handlers }) => {
  const { recordingMinutes, recordingSeconds, initRecording, isPaused } =
    recorderState;
  const {
    startRecording,
    saveRecording,
    cancelRecording,
    pauseRecording,
    resumeRecording,
  } = handlers;

  const formatMinutes = (minutes) => {
    return minutes < 10 ? `0${minutes}` : minutes;
  };

  const formatSeconds = (seconds) => {
    return seconds < 10 ? `0${seconds}` : seconds;
  };

  return (
    <Grid className="controls-container">
      <Grid container className={classes.recordField}>
        <div className={classes.recordingTime}>
          {initRecording && <div className="recording-indicator"></div>}
          <span>{formatMinutes(recordingMinutes)}</span>
          <span>:</span>
          <span>{formatSeconds(recordingSeconds)}</span>
        </div>
        <div className={classes.controlBtnsContainer}>
          {initRecording && (
            <>
              <div
                className={`${classes.controlBtn} ${classes.cancelVoiceContainer}`}
                onClick={cancelRecording}
              >
                <Typography>Cancel</Typography>
              </div>

              {isPaused ? (
                <div
                  className={`${classes.controlBtn} ${classes.resumeVoiceContainer}`}
                  onClick={resumeRecording}
                >
                  <Typography>Resume</Typography>
                </div>
              ) : (
                <div
                  className={`${classes.controlBtn} ${classes.pauseVoiceContainer}`}
                  onClick={pauseRecording}
                >
                  <Typography>Pause</Typography>
                </div>
              )}
            </>
          )}
          {initRecording && (
            <>
              {recordingSeconds === 0 ? (
                <div
                  className={`${classes.controlBtn} ${classes.disabledSaveVoiceContainer}`}
                >
                  <Typography>Save</Typography>
                </div>
              ) : (
                <div
                  className={`${classes.controlBtn} ${classes.saveVoiceContainer}`}
                  onClick={saveRecording}
                >
                  <Typography>Save</Typography>
                </div>
              )}
            </>
          )}{!initRecording && !recorderState.audio && (
            <div
              className={`${classes.controlBtn} ${classes.recordVoiceContainer}`}
              onClick={startRecording}
            >
              <Typography>Record Voice</Typography>
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default RecordControls;

/*

import { Grid } from "@mui/material";
import classes from "./RecordControls.module.css"
import MicIcon from "@mui/icons-material/Mic";
import PauseIcon from "@mui/icons-material/Pause";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import StopIcon from "@mui/icons-material/Stop";

const CustomButton = ({
  data,
  title,
  color,
  backgroundColor,
  hoverColor,
  activeColor,
  onClickHandler,
  disabled,
}) => {
  return (
    <>
      {!disabled ? (
        <Grid
          container
          title={title}
          justifyContent="center"
          alignItems="center"
          onClick={onClickHandler}
          sx={{
            backgroundColor,
            color,
            width: "100px",
            height: "40px",
            cursor: "pointer",
            ":hover": {
              backgroundColor: hoverColor,
            },
            ":active": {
              backgroundColor: activeColor,
            },
          }}
        >
          {data}
        </Grid>
      ) : (
        <Grid
          container
          title={title}
          justifyContent="center"
          alignItems="center"
          onClick={() => {}}
          sx={{
            backgroundColor: "#949494",
            color: "white",
            width: "100px",
            height: "40px",
            cursor: "pointer",
          }}
        >
          {data}
        </Grid>
      )}
    </>
  );
};

const RecordControls = ({ recorderState, handlers }) => {
  const { recordingMinutes, recordingSeconds, initRecording, isPaused } =
    recorderState;
  const {
    startRecording,
    saveRecording,
    cancelRecording,
    pauseRecording,
    resumeRecording,
  } = handlers;

  const formatMinutes = (minutes) => {
    return minutes < 10 ? `0${minutes}` : minutes;
  };

  const formatSeconds = (seconds) => {
    return seconds < 10 ? `0${seconds}` : seconds;
  };

  return (
    <Grid className="controls-container">
      <div className="recording-time">
        {initRecording && <div className="recording-indicator"></div>}
        <span>{formatMinutes(recordingMinutes)}</span>
        <span>:</span>
        <span>{formatSeconds(recordingSeconds)}</span>
      </div>
      <Grid container>
        {initRecording && (
          <>
            <CustomButton
              data="Cancel"
              title="Cancel recording"
              onClickHandler={cancelRecording}
              backgroundColor="#CD0000"
              color="white"
              hoverColor="#A40000"
              activeColor="#720000"
            />
            {isPaused ? (
              <CustomButton
                data={<FiberManualRecordIcon />}
                title="Resume recording"
                onClickHandler={resumeRecording}
                backgroundColor="#0096FF"
                color="white"
                hoverColor="#0790f0"
                activeColor="#0d80d1"
              />
            ) : (
              <CustomButton
                data={<PauseIcon />}
                title="Pause recording"
                onClickHandler={pauseRecording}
                backgroundColor="#0096FF"
                color="white"
                hoverColor="#0790f0"
                activeColor="#0d80d1"
              />
            )}
          </>
        )}
        {initRecording ? (
          <CustomButton
            data={<StopIcon />}
            title="Save recording"
            disabled={recordingSeconds === 0}
            onClickHandler={saveRecording}
            backgroundColor="#CD0000"
            color="white"
            hoverColor="#A40000"
            activeColor="#720000"
          />
        ) : (
          <CustomButton
            data={
              <>
                <MicIcon />
                Record
              </>
            }
            title="Start recording"
            onClickHandler={startRecording}
            backgroundColor="#CD0000"
            color="white"
            hoverColor="#A40000"
            activeColor="#720000"
          />
        )}
      </Grid>
    </Grid>
  );
};

export default RecordControls;


*/
